/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DOM from './dataEvents';
import * as eventsActions from '../../core/events/eventsActions';
import * as globalActions from '../../core/global/globalActions';

class DataEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTabKey: 1,
            eventsTabLoaded: false,
        };
        this.view = DOM;
    }

    eventsTabLoaded = () => {
        this.setState({ eventsTabLoaded: true });
    };

    updateEvents = (event) => {
        const { actions } = this.props;
        actions.updateCompanyEventsRequest({ ...event, notify: event.notify ? 1 : 0 });
    };

    onEventsTabPageChange = (page, pageSize) => {
        const { dataEventsSearchParams, actions } = this.props;
        dataEventsSearchParams.page = page;
        actions.saveDataEventsSearchParamsRequest(dataEventsSearchParams);
        actions.getDataEventsRequest(dataEventsSearchParams);
    };

    onEventsTabFilterChange = (values) => {
        const { actions, assetId, dateFrom } = this.props;
        const filters = values;
        if (filters.date_from) {
            filters.date_from = values.date_from.format('YYYY-MM-DD');
        }
        if (filters.date_to) {
            filters.date_to = values.date_to.format('YYYY-MM-DD');
        }

        // if (moment(values.date_to, 'YYYY-MM-DD').isSameOrAfter(moment(values.date_from).add(31, 'day'))) {
        //     notification.warning({ message: 'Warning', description: 'Filter date to is more than 30 days, the maximum allowance time difference is 30 days' });
        //     filters.date_to = moment(values.date_from).add(30, 'day').format('YYYY-MM-DD');
        // }
        filters.page = 1;
        actions.saveDataEventsSearchParamsRequest(filters);
        actions.getDataEventsRequest(filters);
    };

    onEventsTabSortChange = (pagination, filters, sorter, extra) => {
        const { actions, dataEventsSearchParams, dataEventsPagination } = this.props;
        if (pagination && pagination.current == dataEventsPagination.currentPage) {
            const sortColumn = `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`;
            const params = dataEventsSearchParams;
            params.orderBy = sortColumn;
            actions.saveDataEventsSearchParamsRequest(params);
            actions.getDataEventsRequest(params);
        }
    };

    render() {
        return this.view(
            {
                update: (newVals) => (
                    this.props.actions.UpdateEventNotifications(newVals)
                ),
            },
        );
    }
}

DataEvents.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    dataEventsSearchParams: PropTypes.object.isRequired,
    reload: PropTypes.bool.isRequired,

};

function mapStateToProps(state, ownProps) {
    let reload = false;
    if (ownProps && ownProps.match && ownProps.match.params && ownProps.match.params.reload) {
        reload = true;
    }
    return {
        ...ownProps,
        events: state.events.companyEvents,
        user: state.user,
        dataEventsSearchParams: state.events.dataEventsSearchParams,
        assetId: state.global.eventSearchFilters.assetId,
        dateFrom: state.global.eventSearchFilters.dateFrom,
        dataEventsPagination: state.events.dataEventsPagination,
        reload,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...globalActions,
        }, dispatch),
    };
}
export const EventsTest = DataEvents;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DataEvents);
