import React from 'react';
import { Card, Col, Row } from 'antd';
import {
    Bar, BarChart, CartesianGrid, Tooltip as ToolTipChart, XAxis, YAxis,
} from 'recharts';
import ProgressCircle from '../../elements/ProgressCircle';
import { secondsToHms } from '../../../core/utils/functions';
import journeyScore from '../../../assets/images/journey-score.png';
import journeyTime from '../../../assets/images/clock.png';
import journeySpeed from '../../../assets/images/speed.png';
import averageMpgIcon from '../../../assets/images/average-mpg.png';
import idClearLogo from '../../../assets/images/id-clear-no-trademark.jpg';
import reclearLogo from '../../../assets/images/reclear-logo.png';
import cyclearLogo from '../../../assets/images/cyclear-logo.png';

export default function ({
    driverScore, journeyInfo, idClear, reaClear, cyClear, user, unit_of_measurement_preference,
}) {
    let averageSpeed = journeyInfo?.average_speed;
    let averageMpg = journeyInfo?.mpg;
    let speedUnit = 'kph';
    let mpgUnit = 'L/100km';
    const idleTime = journeyInfo?.idle_time || 0;
    const trueIdleTime = journeyInfo?.true_idle_time || 0;

    // if (user?.profile?.unit_of_measurement_preference === 'british-imperial') {
    if (unit_of_measurement_preference === 'british-imperial') {
        speedUnit = 'mph';
        averageSpeed = Math.floor(averageSpeed * 0.62);
        mpgUnit = 'UK mpg';
        averageMpg = averageMpg > 0 ? (282.481 / averageMpg).toFixed(2) : 0;
    }

    const chartData = [
        { name: 'Idle', pv: idleTime },
        { name: 'True Idle', pv: trueIdleTime },
    ];

    return (
        <>

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    {/* Telematics widgets */}
                    <div className="video-view-block-title">Telematics Data</div>
                </Col>
                <Col xs={24} md={5}>
                    <div className="telematics-data-card-col">
                        <div className="telematics-data-card-title">
                            <span
                                className="telematics-data-card-title-text">Driver Score
                            </span>
                        </div>
                        <Card className="telematics-data-card" style={{ height: 230 }}>
                            <ProgressCircle
                                width={160}
                                className="driver-score"
                                percent={parseInt(driverScore, 10)}
                                type="circle"
                                strokeColor="#FF8733"
                                strokeWidth={11} />
                        </Card>
                    </div>
                </Col>
                {/* Idle / True Idle graph */}
                <Col xs={24} md={6}>
                    <div className="telematics-data-card-col">
                        <div className="telematics-data-card-title">
                            <span
                                className="telematics-data-card-title-text">Idle
                            </span>
                        </div>
                        <Card
                            className="telematics-data-card"
                            style={{
                                height: 230,
                            }}>
                            <BarChart
                                className="idle-chart"
                                width={250}
                                height={200}
                                data={chartData}
                                barSize={30}
                                barGap={5}
                                margin={{}}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <ToolTipChart formatter={(value) => secondsToHms(value)} />
                                <Bar dataKey="pv" stackId="a" fill="#FF8733" />
                                <Bar dataKey="uv" stackId="a" fill="#eff3f5" />
                            </BarChart>
                        </Card>
                    </div>
                </Col>
                {/* Journey info table */}
                <Col xs={24} md={13}>
                    <div className="telematics-data-card-col">
                        <div className="telematics-data-card-title">
                            <span
                                className="telematics-data-card-title-text">Journey Info
                            </span>
                        </div>
                        <Card style={{ padding: 0 }} className="telematics-data-card journey-info-card">
                            <table className="journey-info">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="journey-score-icon"><img
                                                src={journeyScore}
                                                alt="journey score" />
                                            </div>
                                            Journey Score
                                        </td>
                                        <td>{journeyInfo?.score || journeyInfo?.global_score || 0} / 100</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="journey-score-icon"><img
                                                src={journeyTime}
                                                alt="journey score" />
                                            </div>
                                            Journey time
                                        </td>
                                        <td>{journeyInfo?.time_driven_formatted || 0}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="journey-score-icon"><img
                                                src={journeySpeed}
                                                alt="journey score" />
                                            </div>
                                            Journey Average speed
                                        </td>
                                        <td>{averageSpeed || 0} {speedUnit}</td>
                                    </tr>
                                </tbody>

                            </table>
                        </Card>
                    </div>
                </Col>
            </Row>
            {/* Telematics widgets */}
            <Row gutter={[16, 16]}>
                <Col xs={24} md={6}>
                    <div className="telematics-data-card-col middle">
                        <Card style={{ height: 121 }}>
                            <div className="telematic-box-stat-icon">
                                <img src={averageMpgIcon} alt="journey score" />
                            </div>
                            <div className="telematic-box-stat-text">
                                AVERAGE MPG
                            </div>
                            <div className="telematic-box-stat">
                                {averageMpg} {mpgUnit}
                            </div>
                        </Card>
                    </div>
                </Col>
                <Col xs={24} md={6}>
                    <div className="telematics-data-card-col first">
                        <div className="telematics-data-card-title">
                            <span
                                className="telematics-data-card-title-text">Activations
                            </span>
                        </div>
                        <Card className="telematics-data-card" style={{ height: 100 }}>
                            <Row gutter={[24, 24]}>
                                <Col xs={8} sm={4} md={8} lg={8}>
                                    <div className="activations-icon">
                                        <img src={idClearLogo} alt="journey score" />
                                    </div>
                                </Col>
                                <Col xs={8} sm={4} md={12} lg={12}>
                                    <div className="activations-title">
                                        IDClear Activations
                                    </div>
                                </Col>
                                <Col xs={8} sm={4} md={2} lg={2}>
                                    <div className="activations-stat">
                                        {idClear}
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>
                <Col xs={24} md={6}>
                    <div className="telematics-data-card-col">
                        <div className="telematics-data-card-title">
                            <span
                                className="telematics-data-card-title-text">Activations
                            </span>
                        </div>
                        <Card className="telematics-data-card" style={{ height: 100 }}>
                            <Row gutter={[24, 24]}>
                                <Col xs={8} sm={4} md={8} lg={8}>
                                    <div className="activations-icon">
                                        <img src={reclearLogo} alt="journey score" />
                                    </div>
                                </Col>
                                <Col xs={8} sm={4} md={12} lg={12}>
                                    <div className="activations-title">
                                        Reaclear Activations
                                    </div>
                                </Col>
                                <Col xs={8} sm={4} md={2} lg={2}>
                                    <div className="activations-stat">
                                        {reaClear}
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>
                <Col xs={24} md={6}>
                    <div className="telematics-data-card-col first">
                        <div className="telematics-data-card-title">
                            <span className="telematics-data-card-title-text">Activations
                            </span>
                        </div>
                        <Card className="telematics-data-card" style={{ height: 100 }}>
                            <Row gutter={[24, 24]}>
                                <Col xs={8} sm={4} md={8} lg={6}>
                                    <div className="activations-icon">
                                        <img src={cyclearLogo} alt="journey score" />
                                    </div>
                                </Col>
                                <Col xs={8} sm={4} md={8} lg={14}>
                                    <div className="activations-title">
                                        Cyclear Activations
                                    </div>
                                </Col>
                                <Col xs={8} sm={4} md={8} lg={2}>
                                    <div className="activations-stat">
                                        {cyClear}
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>
            </Row>
        </>
    );
}
