import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';

import {
    DatePicker, Select, Input, Button, 
} from 'antd';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import Icon from '../../../components/elements/Icon';

class AssetServiceAlertsFilterForm extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <Form.Item label="Service alert name">
                    {getFieldDecorator('alert_name', {

                    })(
                        <Input placeholder="Service alert name" />,
                    )}
                </Form.Item>
                <Form.Item label="Service alert description">
                    {getFieldDecorator('alert_description', {

                    })(
                        <Input placeholder="Service alert description" />,
                    )}
                </Form.Item>
                <Form.Item label="Next alert">
                    {getFieldDecorator('next_alert')(
                        <DatePicker format="DD/MM/YYYY" placeholder="NEXT ALERT" />,
                    )}
                </Form.Item>
                <Form.Item label="Alert frequesncy">
                    {getFieldDecorator('frequency')(
                        <Select
                            placeholder="Select alert frequency"
                            suffixIcon={<Icon name="triangle-down" />}>
                            <Select.Option value="">Select alert frequency</Select.Option>
                            <Select.Option value="2">Monthly</Select.Option>
                            <Select.Option value="1">Quarterly</Select.Option>
                            <Select.Option value="0">Yearly</Select.Option>
                        </Select>,
                    )}
                </Form.Item>
                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                        className="btn extrawide"
                        size="large">
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

const AssetServiceAlertsFilter = Form.create({ name: 'event_filter_form' })(AssetServiceAlertsFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssetServiceAlertsFilter);
