import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';

import { message } from 'antd';

import * as userActions from '../../../../core/user/userActions';

import DOM from './changePasswordForm';

class UserChangePasswordForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions, form } = this.props;
                let error = false;

                if (values.new_password !== values.new_password_confirm) {
                    error = true;
                    message.error('New password does not match!');
                }

                if (!error) {
                    actions.changePasswordRequest(values);
                    form.resetFields();
                }
            }
        });
    };

    render() {
        return this.view();
    }
}

UserChangePasswordForm.defaultProps = {
    initialValues: {},
};

UserChangePasswordForm.propTypes = {
    form: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    initialValues: PropTypes.object,
};

const ChangePasswordForm = Form.create({
    name: 'ChangePasswordForm',
})(UserChangePasswordForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
