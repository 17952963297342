/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin, Empty, Button } from 'antd';
import {
    first, isArray, isEmpty,
} from 'underscore';
import moment from 'moment';

import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import * as assetActions from '../../../../../core/asset/assetActions';
import * as driverActions from '../../../../../core/driver/driverActions';
import {
    isoStandardDateTimeFormat, isoStandardTimeFormat, dailyFrequencyFormat, weeklyFrequencyFormat, monthlyFrequencyFormat,
} from '../../../../../core/utils/dateUtils';

class ReportTab extends PureComponent {
    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    redirectToAssetOrDriver = (url, params) => {
        const { actions, filters } = this.props;
        const { asset_or_driver } = filters;

        if (asset_or_driver === 'drivers') {
            actions.setDriverJourneyFilters(params);
        } else {
            actions.setAssetJourneyFilters(params);
        }

        window.location.href = url;
    };

    convertDateLabel = (dateLabel) => {
        const { filters } = this.props;
        const { frequency } = filters;

        if (frequency == 1) {
            const dateTimeStart = moment(`${dateLabel} 00:00:00`, `${dailyFrequencyFormat} ${isoStandardTimeFormat}`)
                .format(isoStandardDateTimeFormat);
            const dateTimeEnd = moment(`${dateLabel} 23:59:59`, `${dailyFrequencyFormat} ${isoStandardTimeFormat}`)
                .format(isoStandardDateTimeFormat);

            return [dateTimeStart, dateTimeEnd];
        } if (frequency == 7) {
            const dates = dateLabel.split('-');
            if (dates.length === 2) {
                const dateTimeStart = moment(`${dates[0].trim()} 00:00:00`, `${weeklyFrequencyFormat} ${isoStandardTimeFormat}`)
                    .format(isoStandardDateTimeFormat);
                const dateTimeEnd = moment(`${dates[1].trim()} 23:59:59`, `${weeklyFrequencyFormat} ${isoStandardTimeFormat}`)
                    .format(isoStandardDateTimeFormat);

                return [dateTimeStart, dateTimeEnd];
            }
            return [null, null];
        } if (frequency == 30) {
            const date = moment(`${dateLabel}00:00:00`, `${monthlyFrequencyFormat} ${isoStandardTimeFormat}`);
            const dateTimeStart = date.startOf('month').format(isoStandardDateTimeFormat);
            const dateTimeEnd = date.endOf('month').format(isoStandardDateTimeFormat);

            return [dateTimeStart, dateTimeEnd];
        }

        return [];
    };

    render() {
        const {
            unauthorisedJourneyReport,
            filters,
            isFetching,
            reportHasBeenFetched,
        } = this.props;

        const { asset_or_driver } = filters;

        // wrap each table field in a p tag, and the name field in a Link component
        const listReports = unauthorisedJourneyReport.map((journey) => ({
            ...(Object.entries(journey).reduce((journeys, [key, value]) => {
                const { isAuthorised = 1, label = '-' } = value;

                if (key === 'name') {
                    return {
                        ...journeys,
                        [key]: value,
                    };
                }

                // Preserve the division property as a string
                if (key === 'division') {
                    return {
                        ...journeys,
                        [key]: value,
                    };
                }

                if (first(journey.name) && first(journey.name) !== ' ' && label !== '-') {
                    const dateRange = this.convertDateLabel(key);

                    const params = {
                        is_authorised: isAuthorised ? '1' : '0',
                        dateRange,
                    };

                    let page = '/assets';
                    if (asset_or_driver === 'drivers') {
                        page = '/drivers';
                    }

                    return {
                        ...journeys,
                        [key]: (
                            <Button
                                onClick={() => { this.redirectToAssetOrDriver(`${page}/view/${first(journey.name)}`, params); }}
                                className="btn btn-empty"
                                style={!isAuthorised ? { color: 'red', fontSize: '12px' } : { fontSize: '12px' }}>{label}
                            </Button>
                        ),
                    };
                }
                return {
                    ...journeys,
                    [key]: <p style={!isAuthorised ? { color: 'red' } : {}}>{label}</p>
                    ,
                };
            }, {})),
            ...(isArray(journey.name) && first(journey.name) && first(journey.name) !== ' ' ? {
                name: (
                    <Link
                        to={asset_or_driver === 'assets' ? `/assets/view/${first(journey.name)}` : `/drivers/view/${first(journey.name)}`}
                        className="btn btn-empty">
                        {journey.name[1]}
                    </Link>
                ),
            } : {}),
        }));

        const initialEmpty = (
            <Empty
                description={(
                    <span style={{ color: '#64879c', display: 'block' }}>
                        <span style={{
                            fontSize: '180%', fontWeight: 'bold', marginBottom: '5px', display: 'block',
                        }}>
                            Enter your search requirements to generate a report
                        </span>
                        <span style={{ display: 'block' }}>
                            Use the filter bar on the left to select Assets or Drivers,
                            the date and the frequency (if applicable) of the data you would like to display
                        </span>
                    </span>
                )}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{ display: 'none' }} />
        );

        const exportableColumns = listReports && listReports[0] ? Object.keys(listReports[0]) : [];

        return (
            <Spin spinning={isFetching}>
                <div className="report-table">
                    {isEmpty(listReports)
                        ? reportHasBeenFetched
                            ? <Empty />
                            : initialEmpty
                        : (

                            <GridView
                                showExportButton
                                exportableColumns={exportableColumns}
                                exportFileName=""
                                data={listReports}
                                onChange={this.onTableChange}
                                disablePagination />
                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    unauthorisedJourneyReport: [],
    filters: {},
    onPageChange: () => null,
    onTableChange: () => null,
    actions: {},
};

ReportTab.propTypes = {
    unauthorisedJourneyReport: PropTypes.array,
    filters: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
    actions: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        unauthorisedJourneyReport: state.report.unauthorisedJourneyReport,
        unauthorisedJourneyPagination: state.report.unauthorisedJourneyPagination,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
            ...driverActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
