/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import DOM from './eventTrigger';
import * as eventActions from '../../core/events/eventsActions';
import * as deviceActions from '../../core/device/deviceActions';
import * as assetActions from '../../core/asset/assetActions';

import history from '../../core/utils/history';

const { confirm } = Modal;
class EventTrigger extends Component {
    constructor(props) {
        super(props);
        this.googleMapAccess = React.createRef();
        this.centerMap = {
            lat: 52.48759,
            lng: -1.91199,
        };
        this.state = {
            showTriggerConditions: true,
            triggerEventFilter: {},
            drawGeoFence: false,
            geoFencePath: [],
            showEditTrigger: false,
            clearGeofence: false,
            showSaveButton: false,
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, match } = this.props;
        const { triggerId } = match.params;
        actions.getSelectedEventTriggerRequest({
            triggerId,
        });

        if (this.googleMapAccess && this.googleMapAccess.current) {
            this.googleMapAccess.current.setState({
                showGeoFence: true,
                geoFencePolygon: [],
            });
        }

        window.onpopstate = (e) => {
            history.push('/events', { currentTabKey: 2 });
        };
    }

    componentWillUnmount() {
        if (this.googleMapAccess && this.googleMapAccess.current) {
            this.googleMapAccess.current.setState({
                showGeoFence: true,
                geoFencePolygon: [],
            });
            this.googleMapAccess.current.drawGeoFenceOnMap();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedTriggerFetched, actions } = this.props;
        if (selectedTriggerFetched) {
            if (this.googleMapAccess && this.googleMapAccess.current) {
                const { geoFencePolygon } = this.googleMapAccess.current.state;
                geoFencePolygon.forEach((prevPolygon) => {
                    prevPolygon.setMap(null);
                });
                this.googleMapAccess.current.drawGeoFenceOnMap();
            }
            setTimeout(() => {
                if (this.googleMapAccess && this.googleMapAccess.current) {
                    this.googleMapAccess.current.executeAutoZoom();
                }
            }, 500);
            actions.setSelectedTriggerFetched();
        }
    }

    getGeofenceBoundary = () => {
        const { selectedTrigger } = this.props;
        const { clearGeofence } = this.state;
        if (clearGeofence || !selectedTrigger || !selectedTrigger.trigger_points) {
            return [];
        }
        return [{
            id: selectedTrigger.id,
            coordinates: selectedTrigger.trigger_points.map((point, pointIndex) => {
                if (pointIndex === 0) {
                    this.centerMap.lat = parseFloat(point.lat);
                    this.centerMap.lng = parseFloat(point.lng);
                }
                return ({
                    lat: parseFloat(point.lat),
                    lng: parseFloat(point.lng),
                });
            }),
        }];
    };

    onMapShapeClick = (e, polygon) => {
        const path = polygon.getPath();
        const geoFence = [];
        for (let i = 0; i < path.getLength(); i += 1) {
            const point = path.getAt(i);
            geoFence.push({ lat: point.lat(), lng: point.lng() });
        }
    };

    redrawGeofence = () => {
        this.setState({ clearGeofence: true, showEditTrigger: false, showSaveButton: true });
        if (this.googleMapAccess && this.googleMapAccess.current) {
            setTimeout(() => {
                this.googleMapAccess.current.setState({ drawGeoFence: true, geoFenceCords: [] });
                if (this.googleMapAccess.current.state.geoFencePolygon[0]) {
                    this.googleMapAccess.current.state.geoFencePolygon[0].setMap(null);
                }
            }, 300);
        }
    };

    getDivisions = () => {
        const { divisions, actions } = this.props;
        if (divisions.length === 0) {
            actions.getUserDivisionsRequest();
        }
    };

    toggleMapGeofence = (e) => {
        const drawGeoFenceToggle = !this.state.drawGeoFence;
        this.setState({ drawGeoFence: drawGeoFenceToggle });
        if (drawGeoFenceToggle) {
            Modal.info({
                title: 'Create a geofence alert',
                content: (
                    <div>
                        <p>Using the geofence tool you can create a boundary which will alert you should any assets meet the conditions</p>
                    </div>
                ),
                onOk() {},
            });
        }
        setTimeout(() => {
            const { drawGeoFence } = this.state;
            if (this.googleMapAccess && this.googleMapAccess.current) {
                this.googleMapAccess.current.setState({ drawGeoFence });
            }
        }, 200);
    };

    onTableChange = (pagination, filter, sorter, extra) => {
        const { triggerEventFilter } = this.state;
        const { actions, match } = this.props;
        const { triggerId } = match.params;
        const params = { triggerId, orderBy: `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`, ...triggerEventFilter };
        actions.getSelectedTriggerEventsRequest(params);
    };

    onFilterChange = (values) => {
        const filters = values;
        if (filters.date_from) {
            filters.date_from = values.date_from.format('YYYY-MM-DD');
        }
        if (filters.date_to) {
            filters.date_to = values.date_to.format('YYYY-MM-DD');
        }
        filters.page = 1;
        this.setState({ triggerEventFilter: filters });
        const { actions, match } = this.props;
        const { triggerId } = match.params;
        const params = { triggerId, ...filters };
        actions.getSelectedTriggerEventsRequest(params);
    };

    onPageChange = (page, pageSize) => {
        const { triggerEventFilter } = this.state;
        triggerEventFilter.page = page;
        const { actions, match } = this.props;
        const { triggerId } = match.params;
        this.setState({ triggerEventFilter });
        const params = { triggerId, ...triggerEventFilter };
        actions.getSelectedTriggerEventsRequest(params);
    };

    saveTrigger = (values) => {
        const { actions, match } = this.props;
        const { triggerId } = match.params;
        const geoFencePolygon = this.googleMapAccess && this.googleMapAccess.current && this.googleMapAccess.current.state.geoFencePolygon;
        const geoFenceCords = [];
        if (geoFencePolygon && geoFencePolygon[0]) {
            const path = geoFencePolygon[0].getPath();
            for (let i = 0; i < path.getLength(); i += 1) {
                const point = path.getAt(i);
                geoFenceCords.push({ lat: point.lat(), lng: point.lng() });
            }
        }

        actions.updateTriggerRequest({
            trigger_name: values.trigger_name,
            triggerId,
            geoFenceCords,
            selection: values.selection,
            divisions: values.divisions,
            redraw: !!values.redrawCoord,
        });
        this.setState({ showEditTrigger: false });
    };

    deleteGeofence = (singleTrigger) => {
        const { confirm } = Modal;
        const { actions } = this.props;
        if (singleTrigger && singleTrigger.id) {
            const previousView = this;

            confirm({
                title: 'Do you Want to delete this geofence?',
                content: `${singleTrigger.name}`,
                onOk() {
                    previousView.setState({ showEditTrigger: false, showSaveButton: true });
                    if (previousView.googleMapAccess.current.state.geoFencePolygon[0]) {
                        previousView.googleMapAccess.current.setState({ drawGeoFence: true });
                        previousView.googleMapAccess.current.state.geoFencePolygon[0].setMap(null);
                        previousView.googleMapAccess.current.executeAutoZoom();
                    }
                },
            });
        }
    };

    toggleGeoFence = () => {
        if (this.googleMapAccess && this.googleMapAccess.current) {
            const toggleShowGeoFence = !this.googleMapAccess.current.state.showGeoFence;
            this.googleMapAccess.current.showGeoFenceOnMap(toggleShowGeoFence);
        }
    };

    deleteTrigger = () => {
        confirm({
            title: 'Do you want to delete this trigger?',
            content: 'Trigger data will be lost, if delete.',
            onOk: () => {
                const { actions, match } = this.props;
                const { triggerId } = match.params;
                actions.deleteTriggerRequest({
                    triggerId,
                });
            },
            onCancel: () => {},
        });
    };

    render() {
        return this.view();
    }
}

EventTrigger.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        divisions: state.events.divisions,
        selectedTrigger: state.events.selectedTrigger,
        selectedTriggerEvents: state.events.selectedTriggerEvents,
        selectedTriggerEventsPagination: state.events.selectedTriggerEventsPagination,
        isFetching: state.events.isFetching,
        selectedTriggerFetched: state.events.selectedTriggerFetched,
        assets: state.asset.assets
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...eventActions,
                ...deviceActions,
                ...assetActions,
            },
            dispatch,
        ),
    };
}
export const EventTriggerTest = EventTrigger;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventTrigger);
