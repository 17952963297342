import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    Button, Input, Select, TimePicker, Row, Col, 
} from 'antd';
import { isEmpty } from 'underscore';
import * as driverActions from '../../../core/driver/driverActions';
import Icon from '../../../components/elements/Icon';

dayjs.extend(customParseFormat);

class CreateDriverFormComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // const { actions } = this.props;
        // actions.getDepartmentsRequest();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(['name', 'license', 'division', 'licence_type', 'driver_status', 'driver_availability', 'notes', 'operation_start', 'operation_end'], (err, values) => {
            if (!err) {
                const { updateDriver } = this.props;

                values.operation_start_time = values.operation_start.format('HH:mm:00');
                values.operation_end_time = values.operation_end.format('HH:mm:00');

                values.operation_start = '';
                values.operation_end = '';
                updateDriver(values);
            }
        });
    };

    render() {
        const {
            departments, form, singleDriver, licenceTypes, driverStatusList, divisions,
        } = this.props;
        const { getFieldDecorator } = form;
        const departmentId = singleDriver.department ? singleDriver.department.id : '';
        const divisionId = singleDriver.division ? singleDriver.division.id : '';
        const licenceTypeId = singleDriver.licenceType ? singleDriver.licenceType.id : '';
        const timeFormat = 'HH:mm';

        return (
            <Form onSubmit={this.handleSubmit} layout="vertical">
                <Form.Item label="Driver name">
                    {getFieldDecorator('name', {
                        initialValue: singleDriver.profile.full_name,
                        rules: [
                            {
                                required: true,
                                message: 'Please input your name',
                            },
                        ],
                    })(<Input placeholder="Driver name" />)}
                </Form.Item>
                <Form.Item label="Driver license">
                    {getFieldDecorator('license', { initialValue: singleDriver.profile.licence_number })(<Input placeholder="Driver license" />)}
                </Form.Item>
                <Form.Item label="Division">
                    {getFieldDecorator('division', { initialValue: divisionId.toString() })(
                        <Select
                            placeholder="Select department"
                            suffixIcon={<Icon name="triangle-down" />}>
                            {!isEmpty(divisions)
                                ? divisions.map((item) => (
                                    <Select.Option key={item.id} value={(item.id).toString()}>{item.name}</Select.Option>
                                ))
                                : null}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Licence type">
                    {getFieldDecorator('licence_type', { initialValue: licenceTypeId.toString() })(
                        <Select
                            placeholder="Select licence type"
                            suffixIcon={<Icon name="triangle-down" />}>
                            {!isEmpty(licenceTypes)
                                ? licenceTypes.map((item) => (
                                    <Select.Option key={item.id} value={(item.id).toString()}>{item.title}</Select.Option>
                                ))
                                : null}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Driver status">
                    {getFieldDecorator('driver_status', { initialValue: singleDriver.profile.driver_status })(
                        <Select
                            placeholder="Select licence type"
                            suffixIcon={<Icon name="triangle-down" />}>
                            {!isEmpty(driverStatusList)
                                ? driverStatusList.map((item) => (
                                    <Select.Option key={item.key} value={(item.key).toString()}>{item.title}</Select.Option>
                                ))
                                : null}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Driver availability">
                    {getFieldDecorator('driver_availability', { initialValue: parseInt(singleDriver.profile.driver_availability, 10) })(
                        <Select
                            placeholder="Select licence type"
                            suffixIcon={<Icon name="triangle-down" />}>
                            <Select.Option value={1}>Available</Select.Option>
                            <Select.Option value={0}>Not Available</Select.Option>
                        </Select>,
                    )}
                </Form.Item>

                <Row>
                    <Col md={6}>
                        <Form.Item label="Available from">
                            {getFieldDecorator('operation_start', { initialValue: !isEmpty(singleDriver.profile.operation_start_time) ? dayjs(singleDriver.profile.operation_start_time, timeFormat) : dayjs() })(
                                <TimePicker format={timeFormat} />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col md={6} />
                    <Col md={6}>
                        <Form.Item label="Available To">
                            {getFieldDecorator('operation_end', { initialValue: !isEmpty(singleDriver.profile.operation_end_time) ? dayjs(singleDriver.profile.operation_end_time, timeFormat) : dayjs() })(
                                <TimePicker format={timeFormat} />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Notes">
                    {getFieldDecorator('notes', { initialValue: singleDriver.profile.notes })(<Input.TextArea placeholder="Enter notes" style={{ height: 100 }} />)}
                </Form.Item>
                <div className="mt-2 text-center">
                    <Button htmlType="submit" type="primary" className="btn extrawide" size="large">
                        Update
                    </Button>
                </div>
            </Form>
        );
    }
}

CreateDriverFormComponent.propTypes = {
    departments: PropTypes.array.isRequired,
    licenceTypes: PropTypes.array.isRequired,
    singleDriver: PropTypes.object.isRequired,
    driverStatusList: PropTypes.array.isRequired,
};

const CreateDriverForm = Form.create()(CreateDriverFormComponent);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        departments: state.driver.departments,
        divisions: state.division.divisionOptions,
        licenceTypes: state.driver.licenceTypes,
        singleDriver: state.driver.singleDriver,
        driverStatusList: state.driver.driverStatusList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...driverActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDriverForm);
