/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin, Empty, Switch } from 'antd';
import { isEmpty, size } from 'underscore';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';

class ReportTab extends PureComponent {
    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    render() {
        const {
            carbonReport,
            isFetching,
            toggleFilterSidebar,
        } = this.props;

        const listReports = carbonReport.map((report) => (
            {
                ...report,
                ...(Array.isArray(report.name) && report.name[0] && report.name[0] !== ' ' && {
                    name: (
                        <Link to={`/assets/view/${report.name[0]}`} className="btn btn-empty">
                            {report.name[1]}
                        </Link>
                    ),
                }),
            }
        ));

        const customEmpty = (
            <Empty
                description={(
                    <div style={{ color: '#64879c' }}>
                        <div style={{ fontSize: '180%', fontWeight: 'bold', marginBottom: '5px' }}>
                            Enter your search requirements to generate a report
                        </div>
                        <div>
                            Use the filter bar on the left to select Assets or Drivers,
                            the divison and the date (if applicable) of the data you would like to display
                        </div>
                    </div>
                )}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{ display: 'none' }} />
        );

        const exportableColumns = listReports && listReports[0] ? (Object.keys(listReports[0]).filter((oK) => oK.toLowerCase() != 'actions')) : [];

        return (
            <Spin spinning={isFetching}>
                <div>
                    {isEmpty(listReports) ? customEmpty : (
                        <>
                            {size(listReports) === 1
                                ? <Empty />
                                : (
                                    <>
                                        <Switch
                                            checkedChildren="Kilograms"
                                            unCheckedChildren=""
                                            checked
                                            disabled
                                            className="ml-2" />
                                        <GridView
                                            data={listReports}
                                            onChange={this.onTableChange}
                                            showExportButton
                                            exportableColumns={exportableColumns}
                                            disablePagination />
                                    </>
                                )}
                        </>
                    )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    carbonReport: [],
    onPageChange: () => null,
    onTableChange: () => null,
};

ReportTab.propTypes = {
    carbonReport: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        carbonReport: state.report.carbonReport,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
