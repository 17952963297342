import React from 'react';

function PtoSVG() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 396.9 396.9"
            width="1em"
            height="1em"
            xmlSpace="preserve">
            <g>
                <path d="M393.4,204.8c-1-1.7-2.6-3-4.5-3.5l-29-7.8c-0.3-8.5-1.4-17.1-3.4-25.4l26-15c3.6-2.1,4.8-6.6,2.7-10.2
		l-31.5-54.6c-2.1-3.6-6.6-4.8-10.2-2.7l-26,15c-6.3-5.9-13.1-11.1-20.3-15.6l7.8-29c0.5-1.9,0.2-4-0.7-5.7c-1-1.7-2.6-3-4.5-3.5
		l-60.9-16.3c-1.9-0.5-4-0.2-5.7,0.7c-1.7,1-3,2.6-3.5,4.5l-7.8,29c-8.5,0.3-17.1,1.4-25.4,3.4l-15-26c-1-1.7-2.6-3-4.5-3.5
		c-1.9-0.5-4-0.2-5.7,0.7L116.6,71c-3.6,2.1-4.8,6.6-2.7,10.2l15,26c-5.9,6.3-11.1,13.1-15.6,20.3l-29-7.8c-3.9-1.1-8,1.2-9.1,5.2
		L69,146.5l14.4,4.1l4.1-14.5l27.3,7.3c3.3,0.9,6.8-0.6,8.4-3.5c5.4-9.6,12.2-18.4,20.1-26.2c2.4-2.4,2.9-6.1,1.2-9.1l-14.1-24.4
		l41.7-24.1l14.1,24.4c1.7,3,5.2,4.4,8.5,3.5c10.7-3,21.7-4.4,32.7-4.3c3.4,0,6.4-2.2,7.3-5.5l7.3-27.2l46.5,12.5l-7.3,27.2
		c-0.9,3.3,0.6,6.8,3.5,8.4c9.6,5.4,18.4,12.2,26.2,20.1c2.4,2.4,6.1,2.9,9.1,1.2l24.4-14.1l24.1,41.7l-24.4,14.1
		c-3,1.7-4.4,5.2-3.5,8.5c3,10.7,4.4,21.7,4.3,32.7c0,3.4,2.2,6.4,5.5,7.3l27.2,7.3l-12.5,46.5l-27.2-7.3c-3.3-0.9-6.8,0.6-8.4,3.5
		c-5.4,9.6-12.2,18.4-20.1,26.2c-2.4,2.4-2.9,6.1-1.2,9.1l14.1,24.4l-41.7,24.1l-14.1-24.4c-1.7-3-5.2-4.4-8.5-3.5
		c-10.7,3-21.7,4.4-32.7,4.3c0,0,0,0-0.1,0c-3.4,0-6.3,2.3-7.2,5.5l-7.3,27.2l-46.5-12.5l7.3-27.2c0.9-3.3-0.6-6.8-3.5-8.4
		c-9.6-5.4-18.4-12.2-26.2-20.1c-2.4-2.4-6.1-2.9-9.1-1.2l-24.4,14.1l-9.8-16.9l-12.9,7.5l13.5,23.4c1,1.7,2.6,3,4.5,3.5
		c1.9,0.5,4,0.2,5.7-0.7l26-15c6.3,5.9,13.1,11.1,20.3,15.6l-7.8,29c-0.5,1.9-0.2,4,0.7,5.7c1,1.7,2.6,3,4.5,3.5l60.9,16.3
		c0.6,0.2,1.3,0.3,1.9,0.3c3.3,0,6.3-2.2,7.2-5.5l7.8-29c8.5-0.3,17.1-1.4,25.4-3.4l15,26c2.1,3.6,6.6,4.8,10.2,2.7l54.6-31.5
		c3.6-2.1,4.8-6.6,2.7-10.2l-15-26c5.9-6.3,11.1-13.1,15.6-20.3l29,7.8c4,1.1,8.1-1.3,9.2-5.3l16.3-60.9
		C394.6,208.6,394.4,206.6,393.4,204.8z" />
                <path d="M226.4,263.7c36.1,0,65.4-29.4,65.4-65.4c0-36.1-29.4-65.4-65.4-65.4c-36.1,0-65.4,29.4-65.4,65.4
		C161,234.3,190.4,263.7,226.4,263.7z M226.4,147.7c27.8,0,50.5,22.7,50.5,50.5c0,27.8-22.7,50.5-50.5,50.5s-50.5-22.7-50.5-50.5
		C175.9,170.4,198.6,147.7,226.4,147.7z" />
                <rect x="2.8" y="165.9" width="115.9" height="14.9" />
                <rect x="44.3" y="248.7" width="82.8" height="14.9" />
                <rect x="69.1" y="207.3" width="74.5" height="14.9" />
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default PtoSVG;
