/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin, Empty, Button } from 'antd';

import { isEmpty } from 'underscore';
import Icon from '../../../../../components/elements/Icon';

import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import * as assetActions from '../../../../../core/asset/assetActions';
import * as driverActions from '../../../../../core/driver/driverActions';

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { profile } = this.props;
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    render() {
        const {
            eventReport,
            isFetching,
            eventReportPagination,
        } = this.props;
        let listReports = [];

        if (eventReport && eventReport.length > 0) {
            listReports = eventReport.map((report) => {
                const tempReport = {
                    ...report,
                };

                tempReport.actions = (
                    !isEmpty(report.event_id) && !isEmpty(report.device_id)
                        ? (
                            <Link
                                to={{
                                    pathname: `/events/custom-view/${report.event_id}/${report.device_id}`,
                                }}>
                                <Icon name="chevron-right" className="ml-2" />
                            </Link>
                        )
                        : null
                );

                delete tempReport.event_id;
                delete tempReport.device_id;

                return tempReport;
            });
        }

        const customEmpty = (
            <Empty
                description={(
                    <span style={{ color: '#64879c', display: 'block' }}>
                        <span style={{
                            fontSize: '180%', fontWeight: 'bold', marginBottom: '5px', display: 'block',
                        }}>
                            Enter your search requirements to generate a report
                        </span>
                        <span style={{ display: 'block' }}>
                            Use the filter bar on the left to select Assets or Drivers,
                            the date and the frequency (if applicable) of the data you would like to display
                        </span>
                    </span>
                )}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{ display: 'none' }} />
        );
        const exportableColumns = listReports && listReports[0] ? (Object.keys(listReports[0]).filter((oK) => oK.toLowerCase() != 'actions')) : [];

        return (
            <Spin spinning={isFetching}>
                <div className="report-table">
                    {isEmpty(listReports) ? customEmpty
                        : (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        type="primary"
                                        disabled={this.props.isExportingEventReportCsv}
                                        onClick={() => { this.props.onExportCSV(); }}>
                                        <Spin size="small" spinning={this.props.isExportingEventReportCsv}>Export CSV</Spin>
                                    </Button>
                                </div>
                                <GridView
                                    exportableColumns={exportableColumns}
                                    data={listReports}
                                    onChange={this.onTableChange}
                                    pagination={{
                                        total: eventReportPagination.totalRecords,
                                        pageSize: eventReportPagination.perPageCount,
                                        showSizeChanger: false,
                                        onChange: this.onPageChange,
                                    }} />
                            </>

                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    eventReport: [],
    filters: {},
    onPageChange: () => null,
    onTableChange: () => null,
    actions: {},
};

ReportTab.propTypes = {
    eventReport: PropTypes.array,
    filters: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
    actions: PropTypes.object,
    profile: PropTypes.object.isRequired,

};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        eventReport: state.report.eventReport,
        eventReportPagination: state.report.eventReportPagination,
        isExportingEventReportCsv: state.report.isExportingEventReportCsv,
        isFetching: state.report.isFetching,
        profile: state.user.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
            ...driverActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
