import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isUndefined, isEmpty } from 'underscore';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend,
} from 'recharts';

import {
    Spin,
} from 'antd';

import * as driverActions from '../../../core/driver/driverActions';

function DriverPerformance({ singleDriver, isFetching }) {
    // Code to generate fake data {
    const generateData = (s) => {
        let seed = s;
        const random = () => {
            const x = Math.sin(seed) * 10000;
            seed += 1;
            const randomNumber = x - Math.floor(x);
            return Math.floor(randomNumber * 1000) / 10;
        };
        const weeks = [
            '17/02 - 23/02',
            '24/02 - 01/03',
            '02/03 - 08/03',
            '09/03 - 15/03',
            '16/03 - 22/03',
            '23/03 - 29/03',
            '30/03 - 05/04',
            '06/04 - 12/04',
            '13/04 - 19/04',
            '20/04 - 26/04',
            '27/04 - 03/05',
        ];

        return weeks.map((w) => ({
            week: w,
            score: random(),
        }));
    };

    const startSeed = singleDriver.id % 1000; // Ensure data generated for user is always the same
    const fakeData = generateData(startSeed);

    let finalData = [];

    if (!isEmpty(singleDriver.journeyScore)) {
        finalData = singleDriver.journeyScore.map((item) => {
            const newItem = { ...item };
            if (!isUndefined(item.score)) {
                newItem['Weekly Average'] = item.score;
            }

            return newItem;
        });
    }

    // }

    let spinning = false;
    for (let i = 0; i < isFetching.length; i++) {
        const item = isFetching[i];
        if (item.GET_SINGLE_DRIVER) {
            spinning = true;
            break;
        }
    }

    return (
        <Spin spinning={spinning}>
            <h2>Average journey score</h2>
            <BarChart
                width={600}
                height={300}
                data={finalData} // TODO: Change to singleDriver.journeyScore after demo? fill="#FF8733" fill="#8884d8"
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}>
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis dataKey="week" />
                <YAxis domain={[0, 100]} />
                <Legend />
                <Bar dataKey="Weekly Average" fill="#FDCE7D" />
            </BarChart>
        </Spin>
    );
}

DriverPerformance.propTypes = {
    isFetching: PropTypes.array.isRequired,
    singleDriver: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        singleDriver: state.driver.singleDriver,
        isFetching: state.driver.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...driverActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DriverPerformance);
