/* global window */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import lockr from 'lockr';
import { getTodayInServerFormat, getYesterdayInServerFormat } from '../../../core/utils/dateUtils';
import * as userActions from '../../../core/user/userActions';
import * as globalActions from '../../../core/global/globalActions';
import * as deviceActions from '../../../core/device/deviceActions';
import * as assetActions from '../../../core/asset/assetActions';
import history from '../../../core/utils/history';
import DOM from './header';

const { MAP_SLIDER_INTERVAL, SHARD_KEY } = require('../../../core/constants').default;

class Header extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            changeCompanyModalVisible: false,
            companySelectValue: null,
        };
    }

    logout = () => {
        const { actions } = this.props;
        // const sliderValue = Math.floor((parseInt(moment().format('HH'), 10) * 60) + (parseInt(moment().format('mm'), 10)) / MAP_SLIDER_INTERVAL);
        actions.updateMapDate(moment().format('YYYY-MM-DD'));
        actions.updateEventSearch({ assetId: null });
        actions.setAssetJourneyFilters({
            dateRange: [
                getYesterdayInServerFormat(),
                getTodayInServerFormat(),
            ],
        });
        actions.logoutRequest();
    };

    clickOnItem = () => {
        const { actions } = this.props;
        actions.setRetainFilter('false');
        actions.updateVideoSearch({ assetReg: '' });
        actions.saveUserOrderParamsRequest([]);
    };

    handleShardChange = (e) => {
        const shardId = lockr.get(SHARD_KEY);
        if (e.key != null && e.key != shardId) {
            const newShardId = parseInt(e.key, 10);
            lockr.set(SHARD_KEY, newShardId);
            const { actions } = this.props;
            actions.setShardId(newShardId);
            actions.resetMapDataRequest();
            history.replace('/map');
        }
    };

    handleChangeCompany = () => {
        const { actions } = this.props;
        const { companySelectValue } = this.state;

        actions.updateUserCompanyRequest({ company_id: companySelectValue });
        actions.mapAssetSearchRequest();
        actions.resetMapDataRequest();
        this.setState({ changeCompanyModalVisible: false });
        history.replace('/map');
    };

    render() {
        return this.view(this.props);
    }
}

Header.defaultProps = {
    toolbar: [],
    menu: [],
    backLink: null,
    logo: null,
    menuPlacement: 'left',
    menuWidth: 280,
    floating: false,
    beforeHeader: null,
    afterHeader: null,
    title: null,
};

Header.propTypes = {
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    toolbar: PropTypes.array,
    menu: PropTypes.array,
    backLink: PropTypes.object,
    logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    menuPlacement: PropTypes.string,
    menuWidth: PropTypes.number,
    floating: PropTypes.bool,
    beforeHeader: PropTypes.object,
    afterHeader: PropTypes.object,
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user,
        companies: state.company.companies,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...userActions,
                ...globalActions,
                ...deviceActions,
                ...assetActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Header);
