/* eslint-disable-next-line */
import React, { useState } from 'react';
import ColorPicker from '@rc-component/color-picker';

import '@rc-component/color-picker/assets/index.css';
import { rgbToHex } from '../../core/utils/functions';

const MODE_PREVIEW = 'mode_preview';
const MODE_SELECT = 'mode_selection';

export default function ({ color, onChange }) {
    const [mode, setMode] = useState(MODE_PREVIEW);

    if (mode === MODE_PREVIEW) {
        return (
            <div 
                style={{
                    width: '30px',
                    height: '30px',
                    padding: '2px',
                    border: '1px solid #999',
                    cursor: 'pointer',
                }}
                onClick={() => setMode(MODE_SELECT)}>
                <div style={{
                    width: '24px',
                    height: '24px',
                    backgroundColor: color,
                }} />
            </div>
        );
    } 
    return (
        <ColorPicker
            color={color}
            defaultValue={color}
            onChange={(val) => onChange(rgbToHex(val.r, val.g, val.b))}
            onChangeComplete={(val) => {
                onChange(rgbToHex(val.r, val.g, val.b));
                setMode(MODE_PREVIEW);
            }} />
    );
}
