import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    DatePicker, Select, Button, 
} from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Icon from '../../../components/elements/Icon';
import * as assetActions from '../../../core/asset/assetActions';

dayjs.extend(customParseFormat);

class VideoSearchFilterForm extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { actions } = this.props;
        
        actions.getDivisionListRequest();
        actions.getAssetOptionsRequest();
        setTimeout(() => {
            this.submitForm();
        }, 1000);
    }

    submitForm = (e) => {
        if (e) {
            e.preventDefault();
        }

        const {
            onSubmit,
            form: { validateFields },
            assetOptions,
        } = this.props;

        validateFields((err, values) => {
            if (!err) {
                onSubmit({
                    ...values,
                    assetId: assetOptions.filter((a) => a.imei === values.imei)[0].id,  
                });
            }
        });
    };

    render() {
        const {
            form,
            divisions,
            assetReg,
            assetOptions,
            selectedDivisionId,
            onChangeSelectedDivisionId,
            selectedAssetImei,
            onChangeSelectedAssetImei,
            selectedDate,
            onChangeSelectedDate,
        } = this.props;
        const { getFieldDecorator } = form;

        let dateInitialVal;
        if (selectedDate) {
            dateInitialVal = dayjs(selectedDate);
        } else {
            dateInitialVal = dayjs();
        }
        let initialAssetReg;
        if (assetReg) {
            initialAssetReg = assetOptions.find((asset) => asset.reg === assetReg);
        } else if (selectedAssetImei) {
            initialAssetReg = assetOptions.find((asset) => asset.imei === selectedAssetImei);
        }
        let filteredAssetOptions = assetOptions;
        if (selectedDivisionId) {
            filteredAssetOptions = assetOptions.filter((a) => a.division_id === selectedDivisionId);
        }

        return (
            <Form 
                onSubmit={this.submitForm} 
                layout="vertical"
            >
                <Form.Item label="Division">
                    {getFieldDecorator('division_id', {
                        rules: [],
                    })(
                        <Select
                            placeholder="Select division"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            style={{ width: '100%' }}
                            suffixIcon={
                                <Icon name="triangle-down" />
                            }
                            onChange={(divisionId) => { 
                                onChangeSelectedDivisionId(divisionId); 
                                form.setFieldsValue({ imei: undefined });
                            }}
                            defaultValue={selectedDivisionId}
                        >
                            {divisions.map((v, i) => (
                                <Select.Option 
                                    key={`${i}`} 
                                    value={v.id}
                                >
                                    {v.name}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="Asset name or reg">
                    {getFieldDecorator('imei', {
                        initialValue: initialAssetReg ? [initialAssetReg.imei] : [],
                        rules: [
                            {
                                required: true,
                                message: 'Please select an asset',
                            },
                        ],
                    })(
                        <Select
                            showSearch
                            allowClear
                            filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                            placeholder="Asset name or reg"
                            suffixIcon={<Icon name="triangle-down" />}
                            onChange={(imei) => { onChangeSelectedAssetImei(imei); }}
                        >
                            {filteredAssetOptions && filteredAssetOptions.map((a) => (
                                <Select.Option 
                                    key={a.id} 
                                    value={a.imei}
                                >
                                    {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="Date">
                    {getFieldDecorator('date', {
                        initialValue: dateInitialVal,
                        rules: [
                            {
                                required: true,
                                message: 'Please select a date',
                            },
                        ],
                    })(
                        <DatePicker 
                            format="DD/MM/YYYY" 
                            onChange={(date) => { onChangeSelectedDate(date); }}
                        />,
                    )}
                </Form.Item>
                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                        className="btn extrawide"
                        size="large"
                    >
                        Search
                    </Button>
                </div>
            </Form>
        );
    }
}

VideoSearchFilterForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    assetReg: PropTypes.string,
    form: PropTypes.object,
};

const VideoFileListFilter = Form.create({ name: 'video_search_filter_form' })(VideoSearchFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        divisions: state.asset.divisions,
        assetOptions: state.asset.options,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VideoFileListFilter);
