/* global window */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';

import {
    DatePicker, Select, Button, Radio, 
} from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'underscore';

import Icon from '../../../../../components/elements/Icon';
import * as eventsActions from '../../../../../core/events/eventsActions';
import * as assetActions from '../../../../../core/asset/assetActions';
import * as driverActions from '../../../../../core/driver/driverActions';

class EventFilterForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            asset_or_driver: null,
        };
    }

    componentDidMount() {
        const {
            actions,
            driverList,
        } = this.props;

        actions.getAssetOptionsRequest({ is_report: true });
        actions.getDriverListRequest();
        actions.getDivisionListRequest();
        actions.getGeneralDataRequest();
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit, form } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    render() {
        const {
            form,
            assetOptions,
            driverList,
            divisionList,
            departments,
        } = this.props;
        const { getFieldDecorator } = form;

        const { asset_or_driver } = this.state;
        const dateFormat = 'DD/MM/YYYY';

        return (
            <Form onSubmit={this.submitForm} layout="vertical">

                <Form.Item label="Report on Drivers or Assets?">
                    {getFieldDecorator('asset_or_driver', {
                        rules: [
                            {
                                required: true,
                                message: 'Please select report type!',
                            },
                        ],
                    })(
                        <Radio.Group onChange={(e) => this.setState({ asset_or_driver: e.target.value })}>
                            <Radio value="drivers">Drivers</Radio><br />
                            <Radio value="assets">Assets</Radio>
                        </Radio.Group>,
                    )}
                </Form.Item>
                <br />

                {asset_or_driver === 'drivers' ? (
                    <>
                        <Form.Item label="Driver">
                            {getFieldDecorator('driver', {

                            })(
                                <Select
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select driver"
                                    suffixIcon={<Icon name="triangle-down" />}>
                                    {driverList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)}
                                </Select>,
                            )}
                        </Form.Item>
                        <Form.Item label="Division">
                            {getFieldDecorator('division', {

                            })(
                                <Select
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select division"
                                    suffixIcon={<Icon name="triangle-down" />}>
                                    {!isEmpty(divisionList)
                                        ? divisionList.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                        ))
                                        : null}
                                </Select>,
                            )}
                        </Form.Item>
                    </>
                )
                    : null}

                {asset_or_driver === 'assets' ? (
                    <>
                        <Form.Item label="Asset name or reg">
                            {getFieldDecorator('asset_info', {

                            })(
                                <Select
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                                    placeholder="Asset name or reg"
                                    suffixIcon={<Icon name="triangle-down" />}>
                                    {assetOptions.map((a) => (
                                        <Select.Option key={a.id} value={a.id}>
                                            {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                        </Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                        <Form.Item label="Division">
                            {getFieldDecorator('division', {

                            })(
                                <Select
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select division"
                                    suffixIcon={<Icon name="triangle-down" />}>
                                    {divisionList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)}
                                </Select>,
                            )}
                        </Form.Item>
                    </>
                )
                    : null}
                <Form.Item label="Frequency?">
                    {getFieldDecorator('frequency', {
                        initialValue: '1',
                        rules: [
                            {
                                required: true,
                                message: 'Please select Frequency!',
                            },
                        ],
                    })(
                        <Select
                            placeholder="Select driver"
                            suffixIcon={<Icon name="triangle-down" />}>
                            <Select.Option value="1">Daily</Select.Option>
                            <Select.Option value="7">Weekly</Select.Option>
                            <Select.Option value="30">Monthly</Select.Option>
                        </Select>,
                    )}
                </Form.Item>

                <Form.Item label="Date to">
                    {getFieldDecorator('date_to')(
                        <DatePicker placeholder="DATE TO" format={dateFormat} />,
                    )}
                </Form.Item>
                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                        className="btn extrawide"
                        size="large">
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

EventFilterForm.propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    assetOptions: PropTypes.array.isRequired,
    driverList: PropTypes.array.isRequired,
    divisionList: PropTypes.array.isRequired,
    departments: PropTypes.array.isRequired,
};

const EventFilter = Form.create({ name: 'event_filter_form' })(EventFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        event_types: state.asset.event_types,
        assetOptions: state.asset.options,
        driverList: state.driver.driverList,
        divisionList: state.asset.divisionList,
        departments: state.driver.departments,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
            ...driverActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventFilter);
