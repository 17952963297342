/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Input } from 'antd';
import Icon from '../../../components/elements/Icon';
import GridView from '../../../components/elements/GridView/index';
import Loading from '../../../components/elements/Loading';

export default function () {
    const {
        actions, divisions, divisionsPagination, divisionIsFetching, user, handleTabChange,
    } = this.props;
    const {
        divisionSearchParams,
        divisionOrderParams,
        showAddDivisionModal,
        newDivisionName,
    } = this.state;

    const data = divisions.map((d) => ({
        division_id: d.division_id,
        name: d.name,
        number_of_users: (
            <Link
                to={{
                    pathname: '/users',
                    state: { currentTabKey: '1' },
                }}
                onClick={() => {
                    this.filterUsers({ division_id: [parseInt(`${d.division_id}`)] });
                    // actions.getUsersPageRequest({ page: 1, searchParams, orderParams });
                    //
                    // actions.filterAssetsDivisionRequest({
                    //     divisions: [parseInt(`${d.division_id}`)], currentTabKey: '1', page: 1, searchParams: { divisions: [parseInt(`${d.division_id}`)] },
                    // });
                    this.setState({ currentTabKey: '1' });
                }}>
                {d.number_of_users}
            </Link>
        ),
        status: (
            d.status == 1 ? 'Active' : 'Inactive'
        ),
        actions: (
            user.permissions.adding_divisions === true
                ? (
                    <Link to={`/division/view/${d.division_id}`} className="btn btn-empty ml-2">
                        <Icon name="pencil" />
                    </Link>
                )
                : null
        ),
    }));

    const paginationChange = (page) => {
        // console.log({ page });
        if (page !== divisionsPagination.currentPage) {
            actions.getDivisionsGridRequest({ page, divisionSearchParams, divisionOrderParams });
        }
    };

    const gridChange = (pagination, filters, sorter) => {
        // console.log('##############', pagination.current, divisionsPagination.currentPage, pagination.current == divisionsPagination.currentPage);
        if (pagination && pagination.current == divisionsPagination.currentPage) {
            const params = { orderBy: `${sorter.order == 'descend' ? '-' : ''}${sorter.field}` };
            // divisionOrderParams[sorter.field] = sorter.order ? sorter.order : false;
            this.setState({ divisionOrderParams: params });
            actions.getDivisionsGridRequest({ page: divisionsPagination.currentPage, ...divisionSearchParams, ...params });
        }
    };

    return (
        (<div className="section__body">
            {divisionIsFetching ? <Loading /> : null}
            <div className="section__action-bar">
                {user.permissions.adding_divisions === true ? (
                    <Button
                        onClick={() => this.setState({ showAddDivisionModal: true })}
                        type="primary"
                        className="btn ant-btn-primary ant-btn-xs middlewide">
                        Add division
                    </Button>
                )
                    : null}
            </div>
            <GridView
                data={data}
                onChange={gridChange}
                sortableColumns={['division_id', 'name', 'number_of_assets', 'status']}
                pagination={{
                    total: parseInt(divisionsPagination.totalRecords),
                    pageSize: parseInt(divisionsPagination.perPageCount),
                    showSizeChanger: false,
                    onChange: paginationChange,
                }} />
            <Modal
                open={showAddDivisionModal}
                onCancel={() => this.setState({ showAddDivisionModal: false })}
                onOk={() => {
                    actions.createDivisionRequest({ name: newDivisionName });
                    this.setState({ showAddDivisionModal: false });
                }}
                okText="Add">
                <h3>New division</h3>
                <label htmlFor="division-name-input">Division name</label>
                <Input id="division-name-input" value={newDivisionName} onChange={(v) => this.setState({ newDivisionName: v.target.value })} />
            </Modal>
        </div>)
    );
}
