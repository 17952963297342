import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    notification, Input, Checkbox, Button, Spin,
} from 'antd';
import _ from 'underscore';
import {
    CheckOutlined, CloseOutlined, CheckSquareOutlined, BorderOutlined,
} from '@ant-design/icons';
import GridView from '../../../components/elements/GridView/index';
import Icon from '../../../components/elements/Icon';
import * as userActions from '../../../core/user/userActions';

class AssetPermissions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            divisionPermissionsSearchParams: {},
            divisionPermissionsOrderParams: {},
            assetPermissionsSearchParams: {
                has_access: false,
            },
            assetPermissionsOrderParams: {},
        };
        this.timeout = 0;
    }

    componentDidMount() {
        const { actions, userId } = this.props;

        actions.getDivisionAssetPermissionsGridRequest({ user_id: userId });
    }

    selectDivision = (division) => {
        const { actions, userId } = this.props;

        actions.saveDivisionRequest(division);
        this.setState({ divisionId: division.division_id });
        actions.getAssetPermissionsGridRequest({
            division_id: parseInt(division.division_id),
            user_id: userId,
            division_name: division.name,
        });
    };

    changeAccess = (asset, access) => {
        const { actions, userId, singleDivision } = this.props;

        const newAccess = access === 1 ? 0 : 1;
        actions.changeUserAccessRequestRequest({
            type: 'asset',
            access: newAccess,
            user_id: userId,
            asset_id: asset.asset_id,
            division_id: singleDivision.division_id,
        });
    };

    addAccessToAllDivision(division, event) {
        event.stopPropagation();
        const { actions, userId, divisionAssetPermissionsPagination } = this.props;

        actions.saveDivisionRequest(division);
        actions.addAccessToDivisionRequest({
            division_id: division.division_id,
            division_name: division.name,
            user_id: userId,
            type: 'add',
            data_type: 'assets',
            page: divisionAssetPermissionsPagination.currentPage,
        });
    }

    removeAccessFromAllDivision(division, event) {
        event.stopPropagation();

        const {
            actions,
            userId,
            divisionAssetPermissionsPagination,
        } = this.props;

        actions.saveDivisionRequest(division);
        actions.addAccessToDivisionRequest({
            division_id: division.division_id,
            division_name: division.name,
            user_id: userId,
            type: 'remove',
            data_type: 'assets',
            page: divisionAssetPermissionsPagination.currentPage,
        });
    }

    searchDivisions(keyword) {
        const {
            actions,
            userId,
        } = this.props;

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            actions.getDivisionAssetPermissionsGridRequest({
                user_id: userId,
                search: keyword,
            });
        }, 1000);
    }

    searchAssets(keyword) {
        const { actions, userId, singleDivision } = this.props;
        const { assetPermissionsSearchParams } = this.state;

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            assetPermissionsSearchParams.search = keyword;
            actions.getAssetPermissionsGridRequest({
                user_id: userId,
                division_id: singleDivision.division_id,
                assetPermissionsSearchParams,
            });
            this.setState(assetPermissionsSearchParams);
        }, 1000);
    }

    changeAccessSetting(access) {
        const { actions, userId, singleDivision } = this.props;
        const { assetPermissionsSearchParams } = this.state;

        assetPermissionsSearchParams.has_access = access;
        if (_.isEmpty(singleDivision)) {
            return notification.error({
                message: 'Error',
                description: 'Please select a division',
            });
        }
        actions.getAssetPermissionsGridRequest({
            user_id: userId,
            division_id: singleDivision.division_id,
            assetPermissionsSearchParams,
        });
        this.setState(assetPermissionsSearchParams);
    }

    render() {
        const {
            divisionAssetPermissions,
            assetPermissions,
            singleDivision,
            loading,
            actions,
            assetPermissionsPagination,
            divisionAssetPermissionsPagination,
        } = this.props;

        const leftData = divisionAssetPermissions.map((d) => {
            let color = 'black';
            const user_has_access = parseInt(d.user_has_access, 10);
            const total_assets = parseInt(d.total_assets, 10);
            if (user_has_access > 0 && user_has_access === total_assets) {
                color = 'green';
            }
            if (user_has_access > 0 && user_has_access < total_assets) {
                color = 'yellow';
            }

            return ({
                division_id: d.division_id,
                name: (
                    <div className={color}>
                        {color !== 'green' ? (
                            <>
                                <Button
                                    className="btn btn-empty ml-2"
                                    onClick={(event) => this.addAccessToAllDivision(d, event)}>
                                    <BorderOutlined style={{ fontSize: 18 }} />
                                </Button>
                                <span
                                    className="ml-1"
                                    style={{
                                        fontWeight: 400,
                                        fontSize: 14,
                                    }}>{d.name}
                                </span>
                            </>
                        ) : (
                            <>
                                <Button
                                    className="btn btn-empty ml-2"
                                    onClick={(event) => this.removeAccessFromAllDivision(d, event)}>
                                    <CheckSquareOutlined style={{ fontSize: 18 }} />
                                </Button>
                                <span
                                    className="ml-1"
                                    style={{
                                        fontWeight: 400,
                                        fontSize: 14,
                                    }}>{d.name}
                                </span>
                            </>
                        )}
                    </div>
                ),
                total_assets: `${d.user_has_access}/${d.total_assets}`,
                actions: (
                    <Button
                        onClick={() => this.selectDivision(d)}
                        className="btn btn-empty ml-2">
                        <Icon name="chevron-right" />
                    </Button>
                ),
            });
        });

        const rightData = assetPermissions.map((d) => ({
            ...d,
            access: (
                <Button
                    type="link"
                    onClick={() => this.changeAccess(d, parseInt(d.access, 10))}>
                    {parseInt(d.access, 10) === 1 ? (
                        <CheckOutlined style={{ color: '#389e0d' }} />
                    ) : (
                        <CloseOutlined style={{ color: '#f5222d' }} />
                    )}
                </Button>
            ),
        }));

        const gridChangeDivisionPermissions = (pagination, filters, sorter) => {
            const {
                divisionPermissionsSearchParams,
                divisionPermissionsOrderParams,
            } = this.state;
            const { userId } = this.props;
            if (pagination && pagination.current === divisionAssetPermissionsPagination.currentPage) {
                divisionPermissionsOrderParams[sorter.field] = sorter.order ? sorter.order : false;
                this.setState({ divisionPermissionsOrderParams });
                actions.getDivisionAssetPermissionsGridRequest({
                    user_id: userId,
                    page: divisionAssetPermissionsPagination.currentPage,
                    divisionPermissionsSearchParams,
                    divisionPermissionsOrderParams,
                });
            }
        };
        const paginationChangeDivisionPermissions = (page) => {
            const {
                divisionPermissionsSearchParams,
                divisionPermissionsOrderParams,
            } = this.state;
            const { userId } = this.props;
            if (page !== divisionAssetPermissionsPagination.currentPage) {
                actions.getDivisionAssetPermissionsGridRequest({
                    user_id: userId,
                    page,
                    divisionPermissionsSearchParams,
                    divisionPermissionsOrderParams,
                });
            }
        };
        const gridChangeAssetPermissions = (pagination, filters, sorter) => {
            const {
                assetPermissionsSearchParams,
                assetPermissionsOrderParams,
                singleDivision,
                userId,
            } = this.state;

            if (pagination && pagination.current === assetPermissionsPagination.currentPage) {
                assetPermissionsOrderParams[sorter.field] = sorter.order ? sorter.order : false;
                this.setState({ assetPermissionsOrderParams });
                actions.getAssetPermissionsGridRequest({
                    user_id: userId,
                    division_id: singleDivision.division_id,
                    page: assetPermissionsPagination.currentPage,
                    assetPermissionsSearchParams,
                    assetPermissionsOrderParams,
                });
            }
        };
        const paginationChangeAssetPermissions = (page) => {
            const {
                assetPermissionsSearchParams,
                assetPermissionsOrderParams,
            } = this.state;

            const {
                userId,
                singleDivision,
            } = this.props;

            if (page !== assetPermissionsPagination.currentPage) {
                actions.getAssetPermissionsGridRequest({
                    user_id: userId,
                    division_id: singleDivision.division_id,
                    page,
                    assetPermissionsSearchParams,
                    assetPermissionsOrderParams,
                });
            }
        };

        return (
            <div className="division-tab-wraper">
                <div className="left-side">
                    <Input
                        placeholder="Search for a division"
                        onChange={(data) => this.searchDivisions(data.target.value)} />
                    <Spin spinning={loading}>
                        <GridView
                            data={leftData}
                            onChange={gridChangeDivisionPermissions}
                            sortableColumns={['start_time', 'end_time', 'time_driven', 'driver', 'area_driven', 'journey_score', 'journey_events']}
                            pagination={{
                                total: parseInt(divisionAssetPermissionsPagination.totalRecords, 10),
                                showSizeChanger: false,
                                pageSize: divisionAssetPermissionsPagination.perPage,
                                onChange: paginationChangeDivisionPermissions,
                            }} />
                    </Spin>
                </div>
                <div className="right-side">
                    <h4>Set asset permissions within {singleDivision.name}</h4>
                    <div className="search-bar">
                        <Input
                            placeholder="Search by asset reg"
                            onChange={(data) => this.searchAssets(data.target.value)} />
                        <Checkbox onChange={(data) => this.changeAccessSetting(data.target.checked)}>
                            Show only assets user has access to
                        </Checkbox>
                    </div>
                    <Spin spinning={loading}>
                        <GridView
                            data={rightData}
                            onChange={gridChangeAssetPermissions}
                            sortableColumns={['start_time', 'end_time', 'time_driven', 'driver', 'area_driven', 'journey_score', 'journey_events']}
                            pagination={{
                                total: parseInt(assetPermissionsPagination.totalRecords, 10),
                                showSizeChanger: false,
                                pageSize: assetPermissionsPagination.perPage,
                                onChange: paginationChangeAssetPermissions,
                            }} />
                    </Spin>
                </div>
            </div>
        );
    }
}

AssetPermissions.defaultProps = {
    userId: null,
    actions: {},
    divisionAssetPermissions: [],
    divisionAssetPermissionsPagination: {},
    divisionPermissionsIsFetching: true,
    assetPermissions: [],
    assetPermissionsPagination: {},
    assetPermissionsIsFetching: true,
    singleDivision: {},
    loading: true,
};

AssetPermissions.propTypes = {
    userId: PropTypes.number,
    actions: PropTypes.object.isRequired,
    divisionAssetPermissions: PropTypes.array,
    divisionAssetPermissionsPagination: PropTypes.object.isRequired,
    divisionPermissionsIsFetching: PropTypes.bool.isRequired,
    assetPermissions: PropTypes.array,
    assetPermissionsPagination: PropTypes.object.isRequired,
    assetPermissionsIsFetching: PropTypes.bool.isRequired,
    singleDivision: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        divisionAssetPermissions: state.user.divisionAssetPermissions,
        divisionAssetPermissionsPagination: state.user.divisionAssetPermissionsPagination,
        divisionPermissionsIsFetching: state.user.isFetching,
        assetPermissions: state.user.assetPermissions,
        assetPermissionsPagination: state.user.assetPermissionsPagination,
        assetPermissionsIsFetching: state.user.isFetching,
        singleDivision: state.user.singleDivision,
        loading: state.user.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssetPermissions);
