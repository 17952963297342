import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Empty, Spin } from 'antd';

import { isEmpty } from 'underscore';

import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import * as assetActions from '../../../../../core/asset/assetActions';
import * as driverActions from '../../../../../core/driver/driverActions';

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    render() {
        const {
            notMovedReport,
            isFetching,
        } = this.props;
        let listReports = [];

        if (notMovedReport && notMovedReport.length > 0) {
            listReports = notMovedReport.map((report) => ({
                ...report,
            }));
        }

        const customEmpty = (
            <Empty
                description={(
                    <span style={{ color: '#64879c', display: 'block' }}>
                        <span style={{
                            fontSize: '180%', fontWeight: 'bold', marginBottom: '5px', display: 'block',
                        }}>
                            Enter your search requirements to generate a report
                        </span>
                        <span style={{ display: 'block' }}>
                            Use the filter bar on the left to select Assets or Drivers,
                            the date and the frequency (if applicable) of the data you would like to display
                        </span>
                    </span>
                )}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{ display: 'none' }} />
        );
        const exportableColumns = listReports && listReports[0] ? Object.keys(listReports[0]) : [];

        return (
            <Spin spinning={isFetching}>
                <div className="report-table">
                    {isEmpty(listReports) ? customEmpty
                        : (
                            <GridView
                                exportableColumns={exportableColumns}
                                data={notMovedReport}
                                onChange={this.onTableChange}
                                disablePagination
                                showExportButton />

                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    notMovedReport: [],
    filters: {},
    actions: {},
};

ReportTab.propTypes = {
    notMovedReport: PropTypes.array,
    filters: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    actions: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        notMovedReport: state.report.notMovedReport,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
            ...driverActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
