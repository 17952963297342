import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './AddAsset';
import * as assetActions from '../../core/asset/assetActions';

class AddAsset extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getVehicleMakesRequest();
    }

    uploadImage = (data) => {
        const { actions } = this.props;
        actions.uploadNewAssetImageRequest({
            image: data,
        });
    };

    render() {
        return this.view();
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.asset.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...assetActions,
            },
            dispatch,
        ),
    };
}
export const AddAssetTest = AddAsset;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddAsset);
