/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { size } from 'underscore';
import GridView from '../../../components/elements/GridView/index';
import Loading from '../../../components/elements/Loading';

export default function () {
    const {
        asset,
        user,
        selectedAssets,
        actions,
    } = this.props;

    const {
        data,
        currentTabKey,
    } = this.state;

    const dataWithCheckbox = data.map((dataPoint) => {
        const newData = {
            select: (
                <Checkbox
                    onClick={(event) => {
                        event.stopPropagation();
                        actions.toggleAssetSelected(dataPoint.id);
                    }}
                    checked={selectedAssets.includes(dataPoint.id)}
                />
            ),
            ...dataPoint,
        };
        return newData;
    });
    const sortableColumns = dataWithCheckbox && dataWithCheckbox[0] ? (Object.keys(dataWithCheckbox[0])
        .filter((oK) => oK.toLowerCase() !== 'actions' && oK.toLowerCase() !== 'select')) : [];
    return (

        <div className="section__body">
            {size(asset.isFetching) > 0 ? <Loading/> : null}
            <div className="section__action-bar">
                {selectedAssets.length > 0
                    ? (
                        <Link
                            to="#"
                            onClick={() => actions.clearSelectedAssets()}
                            style={{ marginLeft: 10 }}
                            className="btn ant-btn-primary ant-btn-xs middlewide">
                            Clear selection
                        </Link>
                    )
                    : null}
                {selectedAssets.length > 0
                    ? (
                        <Link
                            to={{ pathname: '/map/' }}
                            style={{ marginLeft: 10 }}
                            className="btn ant-btn-primary ant-btn-xs middlewide"
                            onClick={() => actions.mapAssetSearchRequest({ search_ids: selectedAssets })}>
                            View selected
                        </Link>
                    )
                    : null}
                {user.permissions.editing_assets === true
                    ? (
                        <Link
                            to="/assets/new"
                            style={{ marginLeft: 10 }}
                            className="btn ant-btn-primary ant-btn-xs middlewide">
                            Add asset
                        </Link>
                    )
                    : null}
            </div>
            <GridView
                data={dataWithCheckbox}
                onChange={this.handleTableChange}
                sortableColumns={sortableColumns}
                hiddenColumns={['id']}
                pagination={{
                    total: asset.pagination.totalCount,
                    pageSize: asset.pagination.perPage,
                    showSizeChanger: false,
                    onChange: (page) => this.paginationChange(page),
                }}/>
        </div>
    );
}
