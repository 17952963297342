import React from 'react';

function SpeedingSVG() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 396.9 396.9"
            width="1em"
            height="1em"
            xmlSpace="preserve">
            <g>
                <path d="M60.5,306.3h114.3v-16.3h-45.6l-89.6-89.6l42.8-40.4L71.1,148l-42.8,40.4c-3.2,3.1-5.1,7.2-5.1,11.6
		c-0.1,4.5,1.6,8.6,4.8,11.8l78.1,78.1H60.5V306.3z" />
                <path d="M387.1,147.8L276.2,36.8h-88.3l-95.3,89.8H3.3V143H134v-16.3h-17.6l78-73.5h75l106.1,106.1L191.1,343.7
		l-26.9-26.9l-11.5,11.5l26.9,26.9c3.2,3.2,7.4,4.8,11.5,4.8c4.2,0,8.4-1.6,11.5-4.8l184.4-184.4
		C393.4,164.5,393.4,154.1,387.1,147.8z" />
                <polygon points="262.2,75.3 250.7,63.7 232,82.4 213.2,63.7 201.7,75.3 232,105.5 	" />

                <rect x="227.9" y="111.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -46.3937 214.7545)" width="16.3" height="103.9" />

                <rect x="203.4" y="135.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -70.8874 204.6104)" width="16.3" height="103.9" />

                <rect x="162.6" y="167.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -88.6174 178.1322)" width="16.3" height="57.7" />
                <rect x="60.5" y="36.8" width="65.3" height="16.3" />
                <rect x="27.8" y="36.8" width="16.3" height="16.3" />
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default SpeedingSVG;
