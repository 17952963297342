import React from 'react';
import { Tooltip, Checkbox, Button, Input, Select } from 'antd';
import { useSelector } from 'react-redux';
import { CustomIcon } from '../../../../Map/elements/elements';
import { Channel } from '../../..';
import Icon from '../../../../elements/Icon';
import { VideoRequestLength } from '../../../../../pages/VideoNew/tabs/VideoSearchTab';

import './videoToolbar.scss';

export interface VideoToolbarProps {
    channelList: Channel[] | null;
    toggleAssetLabels: () => void;
    sliderTextValue: string;
    setSliderTextValue: (value: string) => void;
    videoRequestLength: VideoRequestLength;
    onChangeVideoRequestLength: (videoRequestLength: string) => void;
    selectedDvrIsOnline: boolean;
    showVideoStreamingSidebar: () => void;
    selectedChannels: { channel: string, selected: boolean }[] | null;
    toggleSelectedChannel: (channel: string) => void;
}

const VideoToolbar: React.FC<VideoToolbarProps> = ({
    channelList = null,
    toggleAssetLabels = () => {},
    sliderTextValue = '00:00:00',
    setSliderTextValue = (value) => {},
    videoRequestLength = VideoRequestLength.PlusMinusFifteenSeconds,
    onChangeVideoRequestLength = (value) => {},
    selectedDvrIsOnline = false,
    showVideoStreamingSidebar = () => {},
    selectedChannels = null,
    toggleSelectedChannel = () => {},
}) => {
    const globalShowAssetLabels = useSelector((state: any) => state.user.profile.show_info_preference);
    const [ showAssetLabels, setShowAssetLabels ] = React.useState<boolean>(globalShowAssetLabels > 0);

    const tooltipStyle = { zIndex: 1002 };
    const viewVideosButtonComponent = (
        <Button
            id='btn-view-video'
            type='primary'
            disabled={!selectedDvrIsOnline || !selectedChannels?.some((c) => c.selected)}
            onClick={() => showVideoStreamingSidebar()}
        >
            <Icon name='video' />
            View
        </Button>
    );

    return (
        <div className='camera-toolbar'>
            <div className='map-buttons'>
                <Tooltip 
                    title={showAssetLabels ? 'Hide Asset Names' : 'Show Asset Names'} 
                    style={tooltipStyle}
                >
                    <button
                        type='button'
                        className={`btn btn-empty`}
                        onClick={() => {
                            setShowAssetLabels(!showAssetLabels);
                            toggleAssetLabels();
                        }}
                    >
                        <CustomIcon 
                            name='info'
                            style={{ color: showAssetLabels ? 'red' : '#64879c' }} 
                        />
                    </button>
                </Tooltip>
            </div>
            <div className="dvr-status">
                {selectedDvrIsOnline ? (
                    <div className='online'>DVR Online</div>
                ) : (
                    <div className='offline'>DVR Offline</div>
                )}
            </div>
            <div className='channel-list-container'>
                <div className='channel-list'>
                    {selectedChannels && channelList && channelList.map((channel: Channel) => {
                        const selectedChannel = selectedChannels.find((c) => c.channel === channel.channel);
                        return (
                            <Checkbox 
                                checked={selectedChannel.selected}
                                onChange={() => toggleSelectedChannel(selectedChannel.channel)}
                            >
                                {channel.label != '' ? channel.label : `Channel ${channel.channel}`}
                            </Checkbox>
                        ); 
                    })}
                </div>
            </div>
            <div className='slider-input-container'>
                <Input 
                    placeholder='HH:MM:SS'
                    size='small'
                    onChange={(e) => setSliderTextValue(e.target.value)}
                    value={sliderTextValue}
                />
                <Select
                    onChange={onChangeVideoRequestLength}
                    suffixIcon={<Icon name="triangle-down" />}
                    defaultValue={videoRequestLength}
                >
                    {(Object.values(VideoRequestLength)).map((v, i) => (
                        <Select.Option 
                            key={`video-request-length-option-${i}`} 
                            value={v}
                        >
                            {v}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div className='video-buttons'>
                {selectedDvrIsOnline ? (
                    viewVideosButtonComponent
                ) : (
                    <Tooltip 
                        title='DVR is currently offline' 
                        style={tooltipStyle}
                    >
                        {viewVideosButtonComponent}
                    </Tooltip>
                )}
                
            </div>
        </div>
    );
}

export default VideoToolbar;