/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { contains } from 'underscore';
import DOM from './smartWidgetItem';
import * as globalActions from '../../../core/global/globalActions';
import history from '../../../core/utils/history';

import * as smartWidgetActions from '../../../core/smartWidget/smartWidgetActions';
import * as assetActions from '../../../core/asset/assetActions';
import * as eventsActions from '../../../core/events/eventsActions';

class SmartWidgetItem extends Component {
    constructor(props) {
        super(props);

        const { data } = this.props;
        let timeframe = 'weekly';

        if (data && data.timeframe) {
            timeframe = data.timeframe;
        }

        this.view = DOM;
        this.state = {
            visible: false,
            meters: [],
            timeframe,
            datatype: {
                datatype_a: data.datatype_a,
                datatype_b: data.datatype_b,
                datatype_c: data.datatype_c,
            },
        };

        props.getChartData(this.getCurrentChartData.bind(this));
    }

    componentDidMount() {
        const { actions, data, date } = this.props;
        const { timeframe } = this.state;

        actions.getSmartWidgetInputDataRequest();

        if (data.chart_type_id === 14) {
            actions.getBestDriverChartDataRequest({
                id: data.id,
            });
        } else {
            actions.getSmartWidgetDataRequest({
                id: data.id,
                date,
                timeframe,
            });
        }
    }

    changeTimeframe(timeframe) {
        const { actions, date, data } = this.props;
        this.setState({ timeframe });
        actions.getSmartWidgetDataRequest({ id: data.id, date, timeframe });
    }

    changeVORTimeframe(timeframe, datatype) {
        const { actions } = this.props;
        this.setState({ timeframe });
        actions.getVorChartDataRequest({ timeframe, datatype });
    }

    getCurrentChartData(date) {
        const { actions, data } = this.props;
        const { timeframe } = this.state;
        actions.getSmartWidgetDataRequest({ id: data.id, date, timeframe });
    }

    // split datatype to asset id, divisions and drivers
    convertDataType(data) {
        const division = [];
        const driver = [];
        let assetId = null;

        if (data.datatype_a !== 'All') {
            if (data.datatype_b !== 'All') {
                division.push(data.datatype_b);
            }
            if (data.datatype_a === 'Drivers' && data.datatype_c !== 'All') {
                driver.push(data.datatype_c);
            }
            if (data.datatype_a === 'Assets') {
                assetId = data.datatype_c;
            }
        }

        return {
            assetId,
            divisions: division,
            drivers: driver,
        };
    }

    eventConverter(input_data, from, to, timeframe) {
        const { smartWidget } = this.props;
        const { datatype } = this.state;

        const inputDataMap = {};
        smartWidget.inputData.map((v) => { inputDataMap[v.id] = v.key; });

        let event_types = input_data.map((v, i) => (inputDataMap[v.toString()]));

        if (contains(event_types, '253') || contains(event_types, 253)) {
            event_types = event_types.filter((v) => v !== '253' && v !== 253);
            event_types.push('253_1', '253_2', '253_3');
        }

        const options = {};
        if (datatype.datatype_b && Number.isInteger(Number(datatype.datatype_b))) {
            options.divisions = [datatype.datatype_b];
        }

        if (datatype.datatype_a == 'Assets') {
            if (datatype.datatype_c && Number.isInteger(Number(datatype.datatype_c))) {
                options.assetId = datatype.datatype_c;
            }
        }

        if (datatype.datatype_a == 'Drivers') {
            if (datatype.datatype_c && Number.isInteger(Number(datatype.datatype_c))) {
                options.drivers = datatype.datatype_c;
            }
        }

        return {
            params: {
                dateFrom: moment(from, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
                dateTo: moment(to, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
                eventType: event_types,
                ...options,
            },
            url: '/events/search',
            type: 'event_type',
        };
    }

    vorConverter(assets) {
        const url = '/assets';

        return {
            params: {
                assets,
            },
            url,
            type: 'vor_cache',
        };
    }

    reportConverter(single_input_data, from, to, timeframe) {
        const { datatype } = this.state;
        const options = { asset_or_driver: 'drivers' };

        if (datatype.datatype_b && Number.isInteger(Number(datatype.datatype_b))) {
            options.division = datatype.datatype_b;
        }

        if (datatype.datatype_a == 'Assets') {
            options.asset_or_driver = 'assets';
            if (datatype.datatype_c && Number.isInteger(Number(datatype.datatype_c))) {
                options.asset_id = datatype.datatype_c;
            }
        }

        if (datatype.datatype_a == 'Drivers') {
            options.asset_or_driver = 'drivers';
            if (datatype.datatype_c && Number.isInteger(Number(datatype.datatype_c))) {
                options.driver_id = datatype.datatype_c;
            }
        }

        let frequency = 1;
        if (timeframe == 'yearly' || timeframe == 'monthly') {
            frequency = '30';
        } else if (timeframe == 'weekly') {
            frequency = '7';
        }

        if (single_input_data == 1 && timeframe == 'monthly') {
            to = moment().format('YYYY-MM-DD');
        }

        const urlMapping = {
            1: { key: 'mileage', url: '/reports/mileage-report/', extraParams: {} },
            2: { key: 'fuel_used', url: '/reports/fuel-report/', extraParams: { report_type: 'fuel_volume' } },
            3: { key: 'time_driven', url: '/reports/driving-time-report/', extraParams: { time_type: 'usage' } },
            4: { key: 'mpg', url: '/reports/fuel-report/', extraParams: { report_type: 'mpg' } },
            5: { key: 'idle_time', url: '/reports/idle-report/', extraParams: {} },
            6: { key: 'pto_time', url: '/reports/pto-report/', extraParams: {} },
            7: { key: 'true_idle_time', url: '/reports/true-idle-report/', extraParams: {} },
            8: { key: 'over_speeding', url: '/reports/speeding-report/', extraParams: {} },
            9: { key: 'down_time', url: '/reports/driving-time-report/', extraParams: { time_type: 'down-time' } },
            16: { key: 'carbon_usage', url: '/reports/carbon-report/', extraParams: {} },
            19: { key: 'driver_score', url: '/reports/driver-score-report/', extraParams: {} },
        };
        const url = urlMapping[single_input_data.toString()];

        return {
            params: {
                ...url.extraParams,
                key: url.key,
                ...options,
                date_from: from,
                date_to: to,
                frequency,
            },
            url: url.url,
            type: 'report_cache',
        };
    }

    selectMeter(meter) {
        const { actions } = this.props;
        switch (meter.type) {
        case 'report_cache':
            actions.setDataEventToReportParams(meter.params);
            break;
        case 'event_type':
            actions.updateEventSearch(meter.params);
            // actions.saveEventsSearchParamsRequest(meter['params']);
            break;
        case 'vor_cache':
            let asset = [];
            if (meter.params.assets) {
                asset = meter.params.assets;
            }

            actions.saveAssetSearchParamsRequest({ asset });
            break;
        default:
            break;
        }
    }

    goToPage(input_data, timeframe, from, to, chartData) {
        const { actions, smartWidget } = this.props;

        const reportMap = [];
        const vorMap = [];
        const eventMap = [];
        const meterMap = {};
        smartWidget.inputData.map((v) => {
            if (v.type == 'report_cache') { reportMap.push(v.id); }
            if (v.type == 'vor_cache') { vorMap.push(v.id); }
            if (v.type == 'event_type') { eventMap.push(v.id); }
            meterMap[v.id] = v.title;
        });

        /**
         * 1. if all input_data go to the same page (i.e. only have 1 input data or all of them are events)
         *     -> directly go to page
         * 2. else open a popup and ask user which page to go.
         * 3. when going to a page,
         *     -> if event page, go to /events/search with filters, (date_from, date_to, event_type(s))
         *     -> if report page, go to /reports/{report type} with extra parameter (date_to, frequency, extraParams)
         *     -> if vor page, go to /assets with extra parameter (asset), which contains a list of asset id and stored in chartData[i].custom
         * */

        if (input_data.filter((x) => !eventMap.includes(x)).length == 0) {
            // if all input_data are events, go to event page
            const { params, url } = this.eventConverter(input_data, from, to, timeframe);

            actions.updateMapDate(null);
            actions.updateEventSearch(params);
            // actions.saveEventsSearchParamsRequest(params);
            history.push(url);
        } else if (input_data.length == 1) {
            // if only have 1 input data and is report type
            if (reportMap.includes(input_data[0])) {
                const {
                    params,
                    url,
                } = this.reportConverter(input_data[0], from, to, timeframe);

                // driver score report doesn't exist yet
                if (params && params.key && params.key !== 'driver_score') {
                    actions.setDataEventToReportParams(params);
                    history.push(url);
                }
            }

            // if only have 1 input data and is vor type
            if (vorMap.includes(input_data[0])) {
                let custom = [];
                if (chartData[0].custom) {
                    custom = chartData[0].custom;
                }

                const {
                    params,
                    url,
                } = this.vorConverter(custom.assets);

                actions.saveAssetSearchParamsRequest({ asset: params.assets });
                history.push(url);
            }
        } else {
            const meters = [];
            input_data.forEach((v, i) => {
                if (reportMap.includes(v)) {
                    meters.push({
                        ...this.reportConverter(v, from, to, timeframe),
                        title: meterMap[v],
                    });
                } else if (eventMap.includes(v)) {
                    meters.push({
                        ...this.eventConverter([v], from, to, timeframe),
                        title: meterMap[v],
                    });
                } else {
                    let custom = [];
                    if (chartData[i].custom) {
                        custom = chartData[i].custom;
                    }

                    meters.push({
                        ...this.vorConverter(custom.assets),
                        title: meterMap[v],
                    });
                }
            });
            this.setState({ meters, visible: true });
        }
    }

    goToDot(timeframe, index, date) {
        const { data } = this.props;
        const { input_data, chartData } = data;

        const { from } = this.getPeriodFromTimeframe(date, timeframe);
        const { start, end } = this.getPeriodFromDataByIndex(from, index, timeframe);

        this.goToPage(input_data, timeframe, start, end, chartData);
    }

    goToInputType(timeframe, index, date) {
        const { data, actions } = this.props;
        const { input_data, chartData } = data;

        const selectedInput = input_data.slice(index, index + 1);

        const { from, to } = this.getPeriodFromTimeframe(date, timeframe);
        // const { start, end } = this.getPeriodFromDataByIndex(from, index, timeframe);

        this.goToPage(selectedInput, timeframe, from, to, chartData);
    }

    actionRightMenu(menuItem) {
        const { data, actions, onTabSwitch } = this.props;

        if (menuItem === 'edit widget') {
            // actions.deleteSmartWidgetRequest({ id: data.id });
            // actions.getSmartWidgetsRequest({ id: data.id });
            actions.resetPresetWidgetRequest();
            history.push({ pathname: '/smart-widget', editWidgetData: { editWidgetId: data.id } });
        }

        if (menuItem === 'delete') {
            actions.deleteSmartWidgetRequest({ id: data.id });
        }

        if (menuItem === 'see data in table') {
            const { timeframe } = this.state;
            const { input_data, chartData } = data;
            const { from, to } = this.getPeriodFromTimeframe(data.start, timeframe);

            this.goToPage(input_data, timeframe, from, to, chartData);
            // const { input_data } = data;
            //
            // const dataType = this.convertDataType(data);
            //
            // const payload = {
            //     ...dataType,
            //     dateFrom: from,
            //     dateTo: to,
            //     inputData: input_data,
            // };
            //
            // actions.updateDataEventSearch(payload);
            // history.push('/reports/data-events');
        }
    }

    // when click on specific point, compare x with the data set to see how many period of time to go.
    getPeriodFromDataByIndex(from, index, timeframe) {
        // event page currently do not support hourly search
        if (timeframe == 'hourly') {
            return {
                start: moment(from).format('YYYY-MM-DD'),
                end: moment(from).format('YYYY-MM-DD'),

                // start: moment(from).startOf('day').add(index, 'hour').format('YYYY-MM-DD HH:00:00'),
                // end: moment(from).startOf('day').add(index, 'hour').format('YYYY-MM-DD HH:59:59'),
            };
        }
        if (timeframe == 'daily' || timeframe == 'weekly') {
            return {
                start: moment(from).add(index, 'day').format('YYYY-MM-DD'),
                end: moment(from).add(index, 'day').format('YYYY-MM-DD'),
            };
        }
        if (timeframe == 'monthly') {
            return {
                start: moment(from).add(index, 'month').format('YYYY-MM-DD'),
                end: moment(from).add(index, 'month').endOf('month').format('YYYY-MM-DD'),
            };
        }
        if (timeframe == 'yearly') {
            return {
                start: moment(from).add((index), 'year').startOf('year').format('YYYY-MM-DD'),
                end: moment(from).add((index), 'year').endOf('year').format('YYYY-MM-DD'),
            };
        }
    }

    // check date range from timeframe
    getPeriodFromTimeframe(date, timeframe) {
        if (timeframe == 'hourly') {
            return {
                from: moment(date).format('YYYY-MM-DD'),
                to: moment(date).format('YYYY-MM-DD'),
            };
        }
        if (timeframe == 'daily') {
            return {
                from: moment(date).format('YYYY-MM-DD'),
                to: moment(date).format('YYYY-MM-DD'),

                // from: moment(date).startOf('month').format('YYYY-MM-DD'),
                // to: moment(date).endOf('month').format('YYYY-MM-DD'),
            };
        }
        if (timeframe == 'weekly') {
            return {
                from: moment(date).isoWeekday(1).format('YYYY-MM-DD'),
                to: moment(date).isoWeekday(7).format('YYYY-MM-DD'),
            };
        }
        if (timeframe == 'monthly') {
            return {
                from: moment(date).startOf('year').format('YYYY-MM-DD'),
                to: moment(date).endOf('month').format('YYYY-MM-DD'),
            };
        }
        if (timeframe == 'yearly') {
            return {
                from: moment(date).startOf('year').format('YYYY-MM-DD'),
                to: moment(date).format('YYYY-MM-DD'),
            };
        }
    }

    saveDatatypeValues(datatypeValues, chart_type_id) {
        const { actions, data, date } = this.props;
        const { timeframe } = this.state;

        const datatype = {
            datatype_a: datatypeValues.firstOptionValue,
            datatype_b: datatypeValues.secondOptionValue,
            datatype_c: datatypeValues.thirdOptionValue,
            datatype_d: datatypeValues.fourOptionValue,
        };

        this.setState({ datatype });

        actions.getSmartWidgetDataRequest({
            id: data.id,
            date,
            timeframe,
            datatype,
            chart_type_id,
        });
    }

    saveBestDriverDatatypeValues(datatypeValues) {
        const { actions, data, date } = this.props;
        const { timeframe } = this.state;

        const datatype = {
            datatype_a: datatypeValues.firstOptionValue,
            datatype_b: datatypeValues.secondOptionValue,
            datatype_c: datatypeValues.thirdOptionValue,
            datatype_d: datatypeValues.fourOptionValue,
        };

        actions.getBestDriverChartDataRequest({
            id: data.id,
            date,
            timeframe,
            datatype,
        });
    }

    render() {
        return this.view();
    }
}

SmartWidgetItem.defaultProps = {
    getChartData: () => null,
};

SmartWidgetItem.propTypes = {
    smartWidget: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    division: PropTypes.object.isRequired,
    driver: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    getChartData: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        smartWidget: state.smartWidget,
        division: state.division,
        driver: state.driver,
        asset: state.asset,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...smartWidgetActions,
                ...globalActions,
                ...assetActions,
                ...eventsActions,
            },
            dispatch,
        ),
    };
}
export const DashboardTest = SmartWidgetItem;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SmartWidgetItem);
