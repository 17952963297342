/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { each, isEmpty } from 'underscore';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { FlagFilled } from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import GridView from '../../../components/elements/GridView/index';
import Icon from '../../../components/elements/Icon';
import * as eventsActions from '../../../core/events/eventsActions';
import * as globalActions from '../../../core/global/globalActions';

class DataEventsTab extends PureComponent {
    constructor(props) {
        super(props);
        this.tableEventsRef = React.createRef();
    }

    componentDidMount() {
        const {
            actions, setLoadedState, eventsTabLoaded, dataEventsTabFilter,
            reload, dataEventsPagination, onPageChange, assetId, dateFrom, dateTo, inputData, drivers, divisions,
        } = this.props;
        if (!eventsTabLoaded) {
            if (reload) {
                actions.getDataEventsRequest({
                    ...dataEventsTabFilter,
                    page: dataEventsPagination.currentPage,
                });
            } else {
                const params = {};

                // forcing date to and from not be more than one month
                let startdate = moment();
                startdate = startdate.subtract(30, 'days');
                startdate = startdate.format('YYYY-MM-DD');
                params.date_from = startdate;

                params.date_to = moment().format('YYYY-MM-DD');
                params.page = 1;

                if (assetId) {
                    params.asset_info = assetId;
                }

                if (dateFrom) {
                    params.date_from = dateFrom;
                }

                if (dateTo) {
                    params.date_to = dateTo;
                }

                if (inputData.length > 0) {
                    params.input_data = inputData;
                }

                if (drivers.length > 0) {
                    params.driver = drivers;
                }

                if (divisions.length > 0) {
                    params.division = divisions;
                }

                actions.getDataEventsRequest({
                    ...params,
                    page: 1,
                });

                actions.saveDataEventsSearchParamsRequest(params);
            }

            setLoadedState();
        }
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    setSearchingParams = (e, event) => {
        const { actions } = this.props;

        const params = {
            ...event.url.extraParams,
            asset_id: event.asset_id,
            date_from: moment(event.time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
            date_to: moment(event.time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
            frequency: 1,
        };

        // e.preventDefault();
        actions.setDataEventToReportParams(params);
    };

    render() {
        const {
            dataEvents,
            dataEventsPagination,
            isFetching,
            dataEventsTabFilter,
            assetId,
            mapDate,
        } = this.props;

        const listEvents = dataEvents.map((event) => {
            const params = [];
            each(dataEventsTabFilter, (eventParamValue, eventParamKey) => {
                if (eventParamValue !== 'undefined' && !isEmpty(eventParamValue)) {
                    params.push(`${eventParamKey}=${eventParamValue}`);
                }
            });

            const iconStyle = {};
            iconStyle.fontSize = '16px';
            iconStyle.color = '#CF2B28';

            let eventTitle = event.event_name;

            if (event.event_type == 253) {
                if (event.green_driving_type == 0) {
                    eventTitle = 'Eco-friendly driving';
                } else if (event.green_driving_type == 1) {
                    eventTitle = 'Harsh accelerating';
                } else if (event.green_driving_type == 2) {
                    eventTitle = 'Harsh braking';
                } else if (event.green_driving_type == 3) {
                    eventTitle = 'Harsh cornering';
                }
            }

            let event_name = eventTitle;
            event_name = event.icon && event.icon !== ''
                ? (
                    <Tooltip placement="top" title={eventTitle}><LegacyIcon
                        style={iconStyle}
                        theme="filled"
                        type={event.icon} /> {event_name}
                    </Tooltip>
                )
                : (
                    <Tooltip placement="top" title={eventTitle}><FlagFilled style={iconStyle} /> {event_name}
                    </Tooltip>
                );

            const tempEvent = {};
            tempEvent.event = event_name;
            const locationString = (event.location_name) ? <span><Icon name="location" /> {event.location_name}</span> : '(Not Set)';
            tempEvent.date = moment(event.time, 'YYYY-MM-DD HH:mm:ss').format('ddd, DD/MM/YYYY');
            tempEvent.time = moment(event.time, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
            tempEvent.location = locationString;
            tempEvent.driver = <Link to={`/drivers/view/${event.user_id}`}>{event.full_name}</Link>;
            tempEvent.asset_reg = <Link to={`/assets/view/${event.asset_id}`}>{event.registration}</Link>;
            tempEvent.count = event.count || 1;
            tempEvent.actions = (
                <Link to={event.url.url} style={{ float: 'right' }} onClick={(e) => this.setSearchingParams(e, event)}>
                    <Icon name="chevron-right" className="ml-2" />
                </Link>
            );
            return tempEvent;
        });
        const exportableColumns = listEvents && listEvents[0] ? (Object.keys(listEvents[0]).filter((oK) => oK.toLowerCase() != 'actions')) : [];
        return (
            <Spin spinning={isFetching}>
                <div className="section__action-bar" />
                <div ref={this.tableEventsRef}>
                    <GridView
                        data={listEvents}
                        showExportButton
                        exportButtonClassName="btn ant-btn-primary ant-btn-xs middlewide"
                        onChange={this.onTableChange}
                        exportableColumns={exportableColumns}
                        sortableColumns={['event', 'date', 'time', 'driver', 'asset_reg', 'count']}
                        pagination={{
                            total: parseInt(dataEventsPagination.totalRecords, 10),
                            showSizeChanger: false,
                            pageSize: parseInt(dataEventsPagination.perPageCount, 10),
                            current: parseInt(dataEventsPagination.currentPage, 10),
                            onChange: this.onPageChange,
                        }} />
                </div>
            </Spin>
        );
    }
}

DataEventsTab.defaultProps = {
    dataEvents: [],
    onPageChange: () => null,
    onTableChange: () => null,
    setLoadedState: () => null,
    eventsTabLoaded: false,
};

DataEventsTab.propTypes = {
    actions: PropTypes.object.isRequired,
    dataEvents: PropTypes.array,
    dataEventsPagination: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    dataEventsTabFilter: PropTypes.object.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
    setLoadedState: PropTypes.func,
    assetId: PropTypes.number,
    dateFrom: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        dataEvents: state.events.dataEvents,
        dataEventsPagination: state.events.dataEventsPagination,
        isFetching: state.events.isFetching,
        assetId: state.global.dataEventSearchFilters.assetId,
        dateFrom: state.global.dataEventSearchFilters.dateFrom,
        dateTo: state.global.dataEventSearchFilters.dateTo,
        inputData: state.global.dataEventSearchFilters.inputData,
        drivers: state.global.dataEventSearchFilters.drivers,
        divisions: state.global.dataEventSearchFilters.divisions,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DataEventsTab);
