import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './EditAsset';

import * as assetActions from '../../core/asset/assetActions';

class EditAsset extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { match } = this.props;
        const { asset_id } = match.params;
        const { actions } = this.props;
        actions.getAssetInfoRequest(asset_id);
        actions.getVehicleTypesRequest();
        actions.getVehicleMakesRequest();
    }

    uploadImage = (data) => {
        const { actions, match } = this.props;
        const { asset_id } = match.params;
        actions.uploadAssetImageRequest({
            assetId: asset_id,
            image: data,
        });
    };

    render() {
        return this.view();
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        asset: state.asset.singleAsset,
        isFetching: state.asset.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...assetActions,
            },
            dispatch,
        ),
    };
}
export const EditAssetTest = EditAsset;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditAsset);
