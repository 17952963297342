/* global document:true, localStorage, window */
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import Routes from './core/routes';
import history from './core/utils/history';
import appConstants from './core/constants';

import './styles/style.css';

/**
 * ## Actions
 *  The necessary actions for dispatching our bootstrap values
 */
import { setVersion } from './core/global/globalActions';

/**
 *  The version of the app but not displayed yet
 */
import pack from '../package.json';

/**
 * ### configureStore
 */
import configureStore from './core/store';

let ErrorBoundary = Fragment;

const { BUGSNAG_KEY, DEBUG } = appConstants;

if (BUGSNAG_KEY != null && !DEBUG && window.location.hostname !== 'localhost') {
    const bugsnagClient = bugsnag(BUGSNAG_KEY);
    bugsnagClient.use(bugsnagReact, React);
    ErrorBoundary = !DEBUG ? bugsnagClient.getPlugin('react') : Fragment;
}

const globalStore = configureStore({});
const { store, persistor } = globalStore;

const VERSION = pack.version;

store.dispatch(setVersion(VERSION));

// clearing cache for app new version
const cachedVersion = localStorage.getItem('appVersion');
if (cachedVersion !== VERSION) {
    if (cachedVersion) {
        localStorage.clear();

        if (caches in window) {
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
        }
        window.location.reload(true);
    }

    localStorage.setItem('appVersion', VERSION);
}

// wrap your entire app tree in the ErrorBoundary provided
ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <PersistGate 
                loading={null} 
                persistor={persistor}
            >
                <ConfigProvider theme={{ hashed: false }}>
                    <Router 
                        history={history} 
                        basename="/"
                    >
                        <Routes />
                    </Router>
                </ConfigProvider>
            </PersistGate>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
);
