import React from 'react';

function TrueIdleSVG() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 396.9 396.9"
            width="1em"
            height="1em"
            xmlSpace="preserve">
            <g>
                <path d="M360.5,53h-61.4l-97-42.2L20,81.2l5,12.9l176.7-68.4L264.3,53h-1c-11.5,0-20.8,9.3-20.8,20.8V171
		c0,11.5,9.3,20.8,20.8,20.8h97.1c11.5,0,20.8-9.3,20.8-20.8V73.8C381.3,62.3,371.9,53,360.5,53z M367.4,171c0,3.8-3.1,6.9-6.9,6.9
		h-97.1c-3.8,0-6.9-3.1-6.9-6.9V73.8c0-3.8,3.1-6.9,6.9-6.9h97.1c3.8,0,6.9,3.1,6.9,6.9V171z" />
                <path d="M315.4,80.7h-31.2V164H298v-20.8h17.3c17.2,0,31.2-14,31.2-31.2C346.6,94.8,332.6,80.7,315.4,80.7z
		 M315.4,129.3H298V94.6h17.3c9.6,0,17.3,7.8,17.3,17.3S324.9,129.3,315.4,129.3z" />
                <rect x="15.6" y="108.5" width="13.9" height="276.6" />
                <path d="M298,288.9h-51.3c-9.3,0-17.9,4.6-23.1,12.4l-1.5,2.3c-2.6,3.9-6.9,6.2-11.5,6.2h-33.2c-4.7,0-9-2.3-11.5-6.2
		l-1.5-2.3c-5.2-7.7-13.8-12.4-23.1-12.4h-37.4v13.9h37.4c4.7,0,9,2.3,11.5,6.2l1.5,2.3c5.2,7.7,13.8,12.4,23.1,12.4h33.2
		c9.3,0,17.9-4.6,23.1-12.4l1.5-2.3c2.6-3.9,6.9-6.2,11.5-6.2H298v34.7c0,3.8-3.1,6.9-6.9,6.9H96.8c-3.8,0-6.9-3.1-6.9-6.9V143.2
		c0-15.3,12.5-27.8,27.8-27.8h111v-13.9h-111c-23,0-41.6,18.7-41.6,41.6H69c-11.5,0-20.8,9.3-20.8,20.8v34.7
		c0,11.5,9.3,20.8,20.8,20.8H76v118c0,9,5.8,16.7,13.9,19.6v8.2c0,11.5,9.3,20.8,20.8,20.8h13.9c11.5,0,20.8-9.3,20.8-20.8v-6.9
		h97.1v6.9c0,11.5,9.3,20.8,20.8,20.8h13.9c11.5,0,20.8-9.3,20.8-20.8v-8.2c8.1-2.9,13.9-10.6,13.9-19.6v-118h20.8v-13.9H298V288.9z
		 M69,205.6c-3.8,0-6.9-3.1-6.9-6.9V164c0-3.8,3.1-6.9,6.9-6.9H76v48.6H69z M131.5,365.2c0,3.8-3.1,6.9-6.9,6.9h-13.9
		c-3.8,0-6.9-3.1-6.9-6.9v-6.9h27.8V365.2z M277.2,372.2h-13.9c-3.8,0-6.9-3.1-6.9-6.9v-6.9h27.8v6.9
		C284.1,369.1,281,372.2,277.2,372.2z" />
                <path d="M124.5,275h138.8v-41.6H124.5V275z M138.4,247.3h111v13.9h-111V247.3z" />
                <rect x="103.7" y="316.7" width="34.7" height="13.9" />
                <rect x="249.4" y="316.7" width="34.7" height="13.9" />
                <path d="M277.2,205.6H124.5c-3.8,0-6.9-3.1-6.9-6.9v-48.6c0-3.8,3.1-6.9,6.9-6.9h104.1v-13.9H124.5
		c-11.5,0-20.8,9.3-20.8,20.8v48.6c0,11.5,9.3,20.8,20.8,20.8h152.7V205.6z" />
                <rect x="345.2" y="205.8" width="13.9" height="179.3" />
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default TrueIdleSVG;
