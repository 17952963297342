/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin, Empty, Switch } from 'antd';
import { isEmpty, size } from 'underscore';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import * as assetsActions from '../../../../../core/asset/assetActions';

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            unit: false,
        };
    }

    componentDidMount() {
        const { profile } = this.props;
        const { unit_of_measurement_preference } = profile;

        if (unit_of_measurement_preference === 'british-imperial') {
            this.setState({ unit: true });
        } else {
            this.setState({ unit: false });
        }
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    toggleUnit = () => {
        const { unit } = this.state;
        this.setState({ unit: !unit });
    };

    render() {
        const {
            mileageReport,
            isFetching,
        } = this.props;

        const { unit } = this.state;

        const convertedMileageReport = [];

        mileageReport.forEach((report) => {
            const newReport = {};
            const keys = Object.keys(report);

            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = report[key];

                if (unit && value != null && key != 'name' && key != 'id') {
                    // convert km to miles
                    if (!Number.isNaN(+value) && value != 0) {
                        const miles = value / 1.609344;
                        newReport[key] = miles.toFixed(2)
                            .toString();
                    } else if (parseFloat(value) === 0) {
                        newReport[key] = '-';
                    } else {
                        newReport[key] = value.toString();
                    }
                } else if (key == 'id') {
                    newReport[key] = Number(value)
                        .toFixed(0)
                        .toString();
                } else {
                    newReport[key] = value != null ? value.toString() : null;
                }
            }
            convertedMileageReport.push(newReport);
        });

        const listReports = convertedMileageReport.map((report) => {
            const trimmedReport = {
                ...report,
                name: (
                    <Link
                        to={`/assets/view/${report.id}`}
                        className="btn btn-empty">
                        {report.name}
                    </Link>
                ),
            };
            delete trimmedReport.id;
            return trimmedReport;
        });

        const customEmpty = (
            <Empty
                description={(
                    <span style={{
                        color: '#64879c',
                        display: 'block',
                    }}>
                        <span style={{
                            fontSize: '180%',
                            fontWeight: 'bold',
                            marginBottom: '5px',
                            display: 'block',
                        }}>
                            Enter your search requirements to generate a report
                        </span>
                        <span style={{ display: 'block' }}>
                            Use the filter bar on the left to select Assets or Drivers,
                            the date and the frequency (if applicable) of the data you would like to display
                        </span>
                    </span>
                )}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{ display: 'none' }} />
        );

        const exportableColumns = listReports && listReports[0] ? Object.keys(listReports[0]) : [];

        return (
            <Spin spinning={isFetching}>
                <div className="report-table">
                    {isEmpty(listReports) ? customEmpty
                        : (
                            <>
                                {size(listReports) === 1
                                    ? <Empty />
                                    : (
                                        <>
                                            <Switch
                                                checkedChildren="Miles"
                                                unCheckedChildren="Kilometres"
                                                checked={unit}
                                                onChange={() => this.toggleUnit()}
                                                className="ml-2" />
                                            <GridView
                                                showExportButton
                                                exportableColumns={exportableColumns}
                                                exportFileName=""
                                                data={listReports}
                                                onChange={this.onTableChange}
                                                disablePagination />
                                        </>
                                    )}
                            </>
                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    mileageReport: [],
    onPageChange: () => null,
    onTableChange: () => null,
};

ReportTab.propTypes = {
    mileageReport: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        mileageReport: state.report.mileageReport,
        isFetching: state.report.isFetching,
        profile: state.user.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
