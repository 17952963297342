/* eslint-disable react/no-this-in-sfc */
/* global window:true */
import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Spin, Col } from 'antd';
import moment from 'moment';
import { isEmpty, last, indexOf } from 'underscore';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';

import './recordsView.scss';
import RecordDetailView from '../../components/RecordDetailView';

export default function () {
    const {
        selectedRecord, match, isFetching,
    } = this.props;
    const { sliderValue } = this.state;

    let title = '';

    if (selectedRecord && selectedRecord.info && selectedRecord.info.record_time) {
        title = moment(selectedRecord.info.record_time).format('dddd M/YY h:mm');
    }

    if (selectedRecord && selectedRecord.info && selectedRecord.info.registration) {
        title += ` - ${selectedRecord.info.registration}`;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const entries = urlParams.entries();
    const params = [];
    for (const pair of entries) {
        params.push(`${pair[0]}=${pair[1]}`);
        // // console.log('---------->', pair[0], pair[1]);
    }
    const backLink = '/reports/geofence-report';

    let backState = (
        <Link
            to={backLink}
            className="d-flex align-center">
            <Icon name="arrow-left" />
            <span>{params.length == 0 ? 'All geofence report' : 'Filtered geofence report'}</span>
        </Link>
    );

    return (
        <>
            <Layout
                data-test="pages-events-view"
                className="events-view"
                title={<div>Record View</div>}
                headerTitle={<div>Record View Page</div>}
                showFooter={false}>
                <div className="content-wrap">
                    <FilterSidebar />
                    <div className="content">
                        <Spin spinning={isFetching}>
                            <div className="content-inner">
                                <section className="section">
                                    <div className="section__header">
                                        <div className="section__header-item">
                                            {backState}
                                        </div>
                                        <div className="section__header-item is-active">
                                            <span>Record: {title}</span>
                                        </div>
                                    </div>
                                    <div className="section__body">
                                        <RecordDetailView
                                            selectedRecord={selectedRecord}
                                            sliderValue={sliderValue}
                                            onForward={this.requestEventInfo}
                                            onRewind={this.requestEventInfo}
                                            onIncreaseOptionClick={this.requestEventInfo}
                                            onReset={this.requestEventInfo}
                                            geoPoints={this.state.geoPoints}
                                            showStreamingSectionOnLoad />
                                    </div>
                                </section>
                            </div>
                        </Spin>
                    </div>
                </div>
            </Layout>
        </>
    );
}
