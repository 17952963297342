/* global document:true */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Col, Button,
} from 'antd';
import { size } from 'underscore';
import moment from 'moment';
import { ExpandAltOutlined } from '@ant-design/icons';

import DOM from './trimVideo';
import FlvPlayer from '../../../../components/FlvPlayer';
import * as videoActions from '../../../../core/video/videoActions';

class TrimVideo extends PureComponent {
    constructor(props) {
        super(props);
        this.view = DOM;

        const {
            availablevideosCount, videosBeginTime, videosEndTime, startTime, endTime, startFinalTime, endFinalTime,
        } = this.getVideoStartValues();

        this.state = {
            currentVideoIndex: 0,
            startTime,
            endTime,
            startFinalTime,
            middleFinalTime: 0,
            endFinalTime,
            availablevideosCount,
            start: 0,
            middle: 0,
            end: 100,
            hideVideos: false,
            videosBeginTime,
            videosEndTime,
            activeVideo: null,
        };

        this.videosRefs = [];
        this.timeout = 0;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.video.searchedSingleVideo !== prevProps.video.searchedSingleVideo) {
            const {
                availablevideosCount, videosBeginTime, videosEndTime, startTime, endTime, startFinalTime, endFinalTime,
            } = this.getVideoStartValues();
            this.setState({
                availablevideosCount,
                videosBeginTime,
                videosEndTime,
                startTime,
                endTime,
                startFinalTime,
                endFinalTime,
            });
        }
    }

    getVideoStartValues() {
        const { video } = this.props;

        let availablevideosCount = 0;
        let videosBeginTime = 0;
        let videosEndTime = 0;

        let startTime = 0;
        let endTime = 0;

        let startFinalTime = 0;
        let endFinalTime = 0;

        if (this.props.video && this.props.video.searchedSingleVideoChannels && this.props.video.searchedSingleVideoChannels[0]) {
            availablevideosCount = size(this.props.video.searchedSingleVideoChannels[0]);

            const lastVideoIndex = availablevideosCount - 1;

            let firstVideoUrl = '';
            let lastVideoUrl = '';
            if (this.props.video.searchedSingleVideoChannels[0][0] && this.props.video.searchedSingleVideoChannels[0][0].url) {
                firstVideoUrl = this.props.video.searchedSingleVideoChannels[0][0].url;
            }

            if (this.props.video.searchedSingleVideoChannels[0][lastVideoIndex] && this.props.video.searchedSingleVideoChannels[0][lastVideoIndex].url) {
                lastVideoUrl = this.props.video.searchedSingleVideoChannels[0][lastVideoIndex].url;
            }

            const urlParamsStartVideo = new URLSearchParams(firstVideoUrl);
            videosBeginTime = parseInt(urlParamsStartVideo.get('Begintime'), 10);

            const urlParamsEndVideo = new URLSearchParams(lastVideoUrl);
            videosEndTime = parseInt(urlParamsEndVideo.get('Endtime'), 10);

            if (video && video.searchedSingleVideo && video.searchedSingleVideo.date_from && video.searchedSingleVideo.date_to) {
                let beginDateTimestamp = moment(video.searchedSingleVideo.date_from).format('YYYY-MM-DD 00:00:00');
                beginDateTimestamp = parseInt(moment(beginDateTimestamp).format('X'), 10);

                startTime = beginDateTimestamp + videosBeginTime;
                endTime = beginDateTimestamp + videosEndTime;

                startFinalTime = moment.unix(startTime).format('YYYY-MM-DD HH:mm:ss');
                endFinalTime = moment.unix(endTime).format('YYYY-MM-DD HH:mm:ss');
            }
        } else if (video && video.searchedSingleVideo && video.searchedSingleVideo.date_from && video.searchedSingleVideo.date_to) {
            startTime = video.searchedSingleVideo.date_from;
            endTime = video.searchedSingleVideo.date_to;

            startFinalTime = startTime;
            endFinalTime = endTime;

            // convert to timestamp
            startTime = parseInt(moment(startTime).format('X'), 10);
            endTime = parseInt(moment(endTime).format('X'), 10);
        }

        return {
            availablevideosCount,
            videosBeginTime,
            videosEndTime,
            startTime,
            endTime,
            startFinalTime,
            endFinalTime,
        };
    }

    renderVideo(videos, chanelKey) {
        const { currentVideoIndex, activeVideo } = this.state;
        const loading = false;

        let thisVideoUrl = '';
        const videoId = `${chanelKey}_${currentVideoIndex}`;
        if (videos && videos[currentVideoIndex] && videos[currentVideoIndex].url) {
            thisVideoUrl = videos[currentVideoIndex].url;
        }

        let extraClass = '';
        if (chanelKey == activeVideo) {
            extraClass = 'activeVideo';
        }

        return (
            <Col
                className="grid-video"
                key={`camera-${videoId}`}
                style={{
                    overflow: 'hidden',
                    width: '50%',
                }}>

                <div className={`singleVideo ${extraClass}`}>
                    <Button
                        className="expandButton"
                        type="link"
                        onClick={() => {
                            this.zoomVideo(chanelKey);
                        }}>
                        <ExpandAltOutlined style={{ color: '#64879c', fontSize: '20px' }} />
                    </Button>
                    { /* video.aws_url_converted ? <VideoPayer identity={video.id} url={video.aws_url_converted} /> : null */ }

                    {thisVideoUrl ? (
                        <div>
                            <FlvPlayer url={thisVideoUrl} id={videoId} />
                        </div>
                    )
                        : null}
                </div>
            </Col>
        );
    }

    zoomVideo(chanelKey) {
        let { activeVideo } = this.state;

        if (chanelKey === activeVideo) {
            activeVideo = null;
        } else {
            activeVideo = chanelKey;
        }
        this.setState({ activeVideo });
    }

    createVideoRef = (ref, video_id) => {
        if (!this.videosRefs[video_id]) {
            this.videosRefs[video_id] = ref;
        }
    };

    formatTooltip = (value) => {
        const { startTime, endTime } = this.state;

        // get time difference in seconds
        const timedifference = endTime - startTime;
        const timestampDifference = (timedifference * value) / 100;

        const finalTimestamp = startTime + timestampDifference;
        const formatedTime = moment.unix(finalTimestamp).format('HH:mm:ss');

        return formatedTime;
    };

    sliderChangeValue = ([start, middle, end]) => {
        const { startTime, endTime } = this.state;

        const beforeMiddleFinalTime = this.state.middleFinalTime;
        const beforeEndFinalTime = this.state.endFinalTime;

        const timedifference = endTime - startTime;

        const startTimestampDifference = (timedifference * start) / 100;
        const middleTimestampDifference = (timedifference * middle) / 100;
        const endTimestampDifference = (timedifference * end) / 100;

        const startTimestamp = startTime + startTimestampDifference;
        const middleTimestamp = startTime + middleTimestampDifference;
        const endTimestamp = startTime + endTimestampDifference;

        const startFinalTime = moment.unix(startTimestamp).format('YYYY-MM-DD HH:mm:ss');
        const middleFinalTime = moment.unix(middleTimestamp).format('YYYY-MM-DD HH:mm:ss');
        const endFinalTime = moment.unix(endTimestamp).format('YYYY-MM-DD HH:mm:ss');

        let loadNewVideos = false;
        if (middleFinalTime != beforeMiddleFinalTime || endFinalTime != beforeEndFinalTime) {
            loadNewVideos = true;
        }

        this.setState({
            startFinalTime,
            middleFinalTime,
            endFinalTime,
            start,
            middle,
            end,
        });

        if (loadNewVideos) {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.requestNewVideos();
            }, 700);
        }
    };

    requestNewVideos() {
        const { actions, video } = this.props;
        const { middleFinalTime, endFinalTime } = this.state;

        let device_id = 0;

        if (video && video.searchedSingleVideo && video.searchedSingleVideo.device_id) {
            device_id = video.searchedSingleVideo.device_id;
        }

        actions.getChannelsAndVideosRequest({
            date_from: middleFinalTime,
            date_to: endFinalTime,
            device_id,
        });
    }

    loadNextVideo() {
        const { currentVideoIndex, availablevideosCount, middle } = this.state;
        const nextVideo = currentVideoIndex + 1;

        let stepSize = 0;
        if (availablevideosCount > 0) {
            stepSize = 100 / availablevideosCount;
        }

        let nextMiddle = middle + stepSize;
        if (nextMiddle > 100) {
            nextMiddle = 100;
        }

        if ((nextVideo) < availablevideosCount) {
            this.setState({
                middle: nextMiddle,
                currentVideoIndex: nextVideo,
                hideVideos: true,
            });

            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => this.setState({
                hideVideos: false,
            }), 500);
        }
    }

    loadPreviousVideo() {
        const { currentVideoIndex, middle, availablevideosCount } = this.state;
        const nextVideo = currentVideoIndex - 1;

        let stepSize = 0;
        if (availablevideosCount > 0) {
            stepSize = 100 / availablevideosCount;
        }

        let nextMiddle = middle - stepSize;
        if (nextMiddle < 0) {
            nextMiddle = 0;
        }

        if ((nextVideo) >= 0) {
            this.setState({
                middle: nextMiddle,
                currentVideoIndex: nextVideo,
                hideVideos: true,
            });

            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => this.setState({
                hideVideos: false,
            }), 500);
        }
    }

    trimVideo() {
        const { startFinalTime, endFinalTime } = this.state;
        const { actions, video } = this.props;

        let device_id = 0;
        let asset_id = 0;
        if (video && video.searchedSingleVideo && video.searchedSingleVideo.device_id && video.searchedSingleVideo.asset_id) {
            device_id = video.searchedSingleVideo.device_id;
            asset_id = video.searchedSingleVideo.asset_id;
        }

        actions.queueSearchedVideoRequest({
            start_time: startFinalTime,
            end_time: endFinalTime,
            asset_id,
            device_id,
        });
    }

    render() {
        return this.view();
    }
}

TrimVideo.defaultProps = {
    setShouldShowSidebar: () => null,
};

TrimVideo.propTypes = {
    actions: PropTypes.object.isRequired,
    video: PropTypes.object.isRequired,
    setShouldShowSidebar: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        video: state.video,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...videoActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TrimVideo);
