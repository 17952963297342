/* eslint-disable react/prop-types */
/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Col,
    Row,
    Spin,
    Empty
} from 'antd';

import { isEmpty } from 'underscore';
import Layout from '../../components/layout/Layout';
import Avatar from '../../components/elements/Avatar';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';
import AssetUpdateForm from './forms/AssetUpdateForm';
import history from '../../core/utils/history';

export default function () {
    const { asset, isFetching } = this.props;

    if (isEmpty(asset)) {
        return (
            <Layout
                data-test="pages-edit-asset"
                className="assets"
                title={<div>Assets</div>}
                headerTitle={<div>Assets Page</div>}
                showFooter={false}>
                <div className="content-wrap">
                    <br />
                    <br />
                    <Empty description="You don't have permission to access this asset" />
                </div>
            </Layout>
        );
    }

    return (
        <Layout
            className="assets"
            title={<div>Assets</div>}
            headerTitle={<div>Assets Page</div>}
            showFooter={false}>
            <div className="content-wrap">
                <FilterSidebar />
                <div className="content">
                    <div className="content-inner">
                        <Spin spinning={isFetching && isFetching.length > 0}>
                            <section className="section">

                                <div className="section__header">
                                    <div className="section__header-item">
                                        <Link
                                            to="/assets"
                                            className="d-flex align-center">
                                            <Icon name="arrow-left" />
                                            <span>All assets</span>
                                        </Link>
                                    </div>
                                    <div className="section__header-item is-active">
                                        <span>Edit Asset</span>
                                    </div>
                                </div>
                                <div className="section__body">
                                    <div className="section__action-bar justify-center">
                                        <span className="text-bold">
                                            Asset details for:
                                        </span>
                                        <span className="ml-1">{asset.name}</span>
                                    </div>
                                    <Row
                                        gutter={40}
                                        type="flex"
                                        justify="space-between"
                                        className="data-row">
                                        <Col xl={6}>
                                            <AssetUpdateForm
                                                initialValues={asset}
                                                updateForm />
                                        </Col>

                                        <Col xl={11}>
                                            <div className="actions">
                                                <Row
                                                    type="flex"
                                                    gutter={40}
                                                    justify="space-between">
                                                    <Col>
                                                        <Avatar placeholder="car" pictureUrl={asset.pictureThumb || null} onChange={this.uploadImage} />
                                                    </Col>
                                                    <Col>
                                                        <div className="actions__buttons">
                                                            <Button
                                                                form="asset-form"
                                                                htmlType="submit"
                                                                type="primary"
                                                                className="btn smallwide">
                                                                Update
                                                            </Button>
                                                            <Button
                                                                onClick={() => history.push(`/assets/view/${asset.id}`)}
                                                                type="primary"
                                                                className="btn">
                                                                <Icon name="eye" />
                                                                <span>
                                                                view
                                                                </span>
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </section>
                        </Spin>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
