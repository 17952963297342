/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Input, Select, Button } from 'antd';

import * as DepartmentActions from '../../../core/department/departmentActions';

const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;

class DepartmentsLeftSide extends React.Component {
    componentDidMount() {
        const { registerSubmitHandler } = this.props;
        registerSubmitHandler(this.submitDetails.bind(this));
    }

    submitDetails() {
        const { actions, form, department } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                values.department_id = department.departmentDetail.id
                actions.updateDepartmentRequest(values);
            } else {
                return false;
            }
        });
    }

    render() {
        const { form, department } = this.props;
        const { getFieldDecorator } = form;

        return (
            <>
                <label>
                    Name
                </label>
                <FormItem>
                    {getFieldDecorator('name', {
                        initialValue: department.departmentDetail.name,
                        rules: [
                            {
                                required: true,
                                message: 'Please enter department name',
                            },
                        ],
                    })(<Input placeholder="Department Name" />)}
                </FormItem>
                <label>
                    Description
                </label>
                <FormItem>
                    {getFieldDecorator('description', {
                        initialValue: department.departmentDetail.description,
                    })(<TextArea rows={4} style={{ height: 200 }} />)}
                </FormItem>
                <label>
                    Department status
                </label>
                <FormItem>
                    {getFieldDecorator('status', {
                        initialValue: department.departmentDetail.status,
                        rules: [
                            {
                                required: true,
                                message: 'Please select status',
                            },
                        ]
                    })(
                        <Select style={{ width: '100%' }}>
                            <Option value={1}>Active</Option>
                            <Option value={0}>Inactive</Option>
                        </Select>,
                    )}
                </FormItem>
            </>
        );
    }
}

DepartmentsLeftSide.defaultProps = {
    registerSubmitHandler: () => null,
};

DepartmentsLeftSide.propTypes = {
    registerSubmitHandler: PropTypes.func,
    department: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
};

const DepartmentMainForm = Form.create()(DepartmentsLeftSide);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        department: state.department,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...DepartmentActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DepartmentMainForm);
