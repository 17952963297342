/* global window document */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactToPdf from 'react-to-pdf';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { FlagFilled } from '@ant-design/icons';
import {
    Button, Col, Row, Select, Input, Spin, Tooltip, Card, Progress, 
} from 'antd';

import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as ToolTipChart,
} from 'recharts';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'underscore';
import Map from '../Map';

import Icon from '../elements/Icon';
import DefaultDrivePicture from '../../assets/images/default-image.png';
import * as eventsActions from '../../core/events/eventsActions';
import * as videoActions from '../../core/video/videoActions';
import GridView from '../elements/GridView';
import ProgressCircle from '../elements/ProgressCircle';
import journeyScore from '../../assets/images/journey-score.png';
import journeyTime from '../../assets/images/clock.png';
import journeySpeed from '../../assets/images/speed.png';
import cyclearLogo from '../../assets/images/cyclear-logo.png';
import averageMpgIcon from '../../assets/images/average-mpg.png';
import fuelIcon from '../../assets/images/fuel.png';
import idClearLogo from '../../assets/images/id-clear-no-trademark.jpg';
import reclearLogo from '../../assets/images/reclear-logo.png';
import MapAssetPopup from '../elements/MapAssetPopup';
import { secondsToHms } from '../../core/utils/functions';
import Layout from '../layout/Layout';

import './eventViewPagePdf.scss';
import { calculateSpeedAndUnit, getMapAssetPopupContent, getMapAssetPopupHead } from '../../core/utils/mapUtils';

const { GOOGLE_MAP_API_KEY, DEBUG } = require('../../core/constants').default;

class EventViewPage extends Component {
    constructor(props) {
        super(props);
        this.googleMapAccess = React.createRef();
        this.textFieldRef = React.createRef();
        this.pdfDocumentRef = React.createRef();
        this.centerMap = {};
        this.asset_picture = DefaultDrivePicture;
        this.profile_picture = DefaultDrivePicture;

        this.state = {
            asset_picture: '',
            profile_picture: '',
            copySuccess: '',
            gettingPdf: false,
            sliderValue: props.selectedEvent.interval || props.selectedEvent.startInterval,
            parentWidth: parseInt(window?.frameElement?.getAttribute('data-parent-width'), 10) || window.innerWidth,
            parentHeight: parseInt(window?.frameElement?.getAttribute('data-parent-height'), 10) || window.innerHeight,
            sliderRangeMinValue: props.selectedEvent.startInterval,
            sliderRangeMaxValue: props.selectedEvent.endInterval,
        };
    }

    componentDidMount() {
        const { actions, params, selectedEvent } = this.props;
        const { eventId, deviceId } = params;
        // dont call api again as it is already fetched on event view component
        // actions.getVideosAroundEventRequest({
        //     page: 1,
        //     event_id: eventId,
        //     device_id: deviceId,
        // });
        if (selectedEvent.info) {
            if (selectedEvent.info.asset_picture) {
                fetch(selectedEvent.info.asset_picture, { method: 'HEAD' })
                    .then((res) => {
                        if (res.ok) {
                            this.asset_picture = selectedEvent.info.asset_picture;
                            this.setState({
                                asset_picture: selectedEvent.info.asset_picture,
                            });
                        } else {
                            this.asset_picture = DefaultDrivePicture;
                            this.setState({
                                asset_picture: DefaultDrivePicture,
                            });
                        }
                    }).catch((err) => {
                        this.asset_picture = DefaultDrivePicture;
                        this.setState({
                            asset_picture: DefaultDrivePicture,
                        });
                    });
            } else {
                this.asset_picture = DefaultDrivePicture;
                this.setState({
                    asset_picture: DefaultDrivePicture,
                });
            }
            if (selectedEvent.info.profile_picture) {
                fetch(selectedEvent.info.profile_picture, { method: 'HEAD' })
                    .then((res) => {
                        if (res.ok) {
                            this.profile_picture = selectedEvent.info.profile_picture;
                            this.setState({
                                profile_picture: selectedEvent.info.profile_picture,
                            });
                        } else {
                            this.profile_picture = DefaultDrivePicture;
                            this.setState({
                                profile_picture: DefaultDrivePicture,
                            });
                        }
                    }).catch((err) => {
                        this.profile_picture = DefaultDrivePicture;
                        this.setState({
                            profile_picture: DefaultDrivePicture,
                        });
                    });
            } else {
                this.setState({
                    profile_picture: DefaultDrivePicture,
                });
            }
        }
        // setTimeout(() => {
        //     if (this.googleMapAccess && this.googleMapAccess.current && this.googleMapAccess.current._googleMap) {
        //         const map = this.googleMapAccess.current._googleMap.map_;
        //         if (map) {
        //             map.setCenter(this.centerMap);
        //         }
        //     }
        // }, 2500);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedEvent, updateMapEventData, actions } = this.props;
        if (updateMapEventData === true) {
            actions.resetUpdateMapEventDataFlagRequest();
            this.googleMapAccess.current.executeAutoZoom();
            this.googleMapAccess.current.setState({ sliderMaxValue: selectedEvent.endInterval });
        }
    }

    paginationChange = (page) => {
        const { sliderRangeMinValue, sliderRangeMaxValue } = this.state;
        const { actions, pagination, params } = this.props;
        const { eventId, deviceId } = params;
        const { currentPage } = pagination;
        if (page !== currentPage) {
            actions.getVideosAroundEventRequest({
                page,
                event_id: eventId,
                device_id: deviceId,
                slider_range_min_value: sliderRangeMinValue,
                slider_range_max_value: sliderRangeMaxValue,
            });
        }
    };

    handleTableChange = (pagination) => {
        const { sliderRangeMinValue, sliderRangeMaxValue } = this.state;
        const { actions, pagination: p, params } = this.props;
        const { eventId, deviceId } = params;
        const { currentPage: page } = p;
        // checking when only order changes
        if (pagination && pagination.current === page) {
            actions.getVideosAroundEventRequest({
                page,
                event_id: eventId,
                device_id: deviceId,
                slider_range_min_value: sliderRangeMinValue,
                slider_range_max_value: sliderRangeMaxValue,
            });
        }
    };

    /**
     * Converts seconds since midnight to a formatted MM:SS or HH:MM:SS string
     * @param {*} secondsSinceMidnight Number of seconds since midnight
     */
    convertFromSeconds(secondsSinceMidnight) {
        const t = secondsSinceMidnight;
        const hours = Math.floor(t / 3600);
        const minutes = `${Math.floor((t / 60) % 60)}`.padStart(2, '0');
        const seconds = `${Math.floor(t % 60)}`.padStart(2, '0');
        if (hours !== 0) {
            return `${hours}:${minutes}:${seconds}`;
        }
        return `${minutes}:${seconds}`;
    }

    mapDevicePath = () => {
        const { selectedEvent } = this.props;
        let foundFirstEvent = false;

        return selectedEvent && selectedEvent.path ? selectedEvent.path.map((event, eventIndex) => {
            const tempEvent = {};
            tempEvent.time_interval = event.time_interval;
            tempEvent.lat = event.lat;
            tempEvent.lng = event.lng;
            tempEvent.angle = event.angle;
            tempEvent.speed = event.speed;
            tempEvent.position = 'path';
            if ((event.priority === 1 && foundFirstEvent === false) || eventIndex === 0) {
                this.centerMap.lat = parseFloat(event.lat);
                this.centerMap.lng = parseFloat(event.lng);
                if (eventIndex > 0) {
                    foundFirstEvent = true;
                }
            }
            if (event.show_in_events === 1) {
                this.centerMap.lat = parseFloat(event.lat);
                this.centerMap.lng = parseFloat(event.lng);
                const iconStyle = {};
                iconStyle.fontSize = '16px';
                iconStyle.color = '#CF2B28';
                tempEvent.position = 'marker';
                tempEvent.currentMarker = {};
                let eventTitle = event.event_name;

                if (event.event_type == 253) {
                    if (event.green_driving_type == 0) {
                        eventTitle = 'Eco-friendly driving';
                    } else if (event.green_driving_type == 1) {
                        eventTitle = 'Harsh accelerating';
                    } else if (event.green_driving_type == 2) {
                        eventTitle = 'Harsh braking';
                    } else if (event.green_driving_type == 3) {
                        eventTitle = 'Harsh cornering';
                    }
                }

                if (event.icon && event.icon !== '') {
                    tempEvent.currentMarker.icon = <Tooltip placement="top" title={eventTitle}><LegacyIcon style={iconStyle} theme="filled" type={event.icon} /></Tooltip>;
                } else {
                    tempEvent.currentMarker.icon = <Tooltip placement="top" title={eventTitle}><FlagFilled style={iconStyle} /></Tooltip>;
                }
                // tempEvent.currentMarker.icon = event.icon && event.icon !== ''
                //     ? (
                //         <Tooltip title={event.event_name}><AntIcon
                //             type={event.icon}
                //             theme="filled"
                //             style={iconStyle} />
                //         </Tooltip>
                //     )
                //     : <AntIcon type="flag" theme="filled" />;
            }
            return tempEvent;
        })
            : [];
    };

    getAssetCurrentPin = (mappedDevicePath) => {
        const { sliderValue } = this.state;
        const { selectedEvent, user } = this.props;
        const assets = mappedDevicePath.filter((path) => path.time_interval == sliderValue);
        if (assets && assets[0]) {
            const { speed, unit } = calculateSpeedAndUnit(assets[0], user);
            const title = getMapAssetPopupHead(assets[0]);
            const content = getMapAssetPopupContent(this, assets[0], speed, unit);

            assets[0].arrowIcon = true;
            assets[0].hasEvents = true;
            assets[0].imei = '';
            assets[0].online = 0;
            assets[0].registration = '';
            assets[0].title = '';
            assets[0].markers = [];
            assets[0].currentMarker = {};
            assets[0].currentMarker.lat = parseFloat(assets[0].lat);
            assets[0].currentMarker.lng = parseFloat(assets[0].lng);
            assets[0].currentMarker.angle = assets[0].angle;
            assets[0].currentMarker.imei = '';
            assets[0].currentMarker.showArrowIcon = true;
            assets[0].currentMarker.speed = speed;
            assets[0].currentMarker.time_interval = assets[0].time_interval;
            assets[0].currentMarker.title = '';
            assets[0].markers[assets[0].time_interval] = {};
            assets[0].markers[assets[0].time_interval].lat = parseFloat(assets[0].lat);
            assets[0].markers[assets[0].time_interval].lng = parseFloat(assets[0].lng);
            assets[0].markers[assets[0].time_interval].angle = assets[0].angle;
            assets[0].markers[assets[0].time_interval].imei = '';
            assets[0].markers[assets[0].time_interval].showArrowIcon = true;
            assets[0].markers[assets[0].time_interval].speed = speed;
            assets[0].markers[assets[0].time_interval].time_interval = assets[0].time_interval;
            assets[0].markers[assets[0].time_interval].title = title;
            assets[0].markers[assets[0].time_interval].content = content;
        }
        return assets;
    };

    renderPdf(toPdf) {
        window.scrollTo(0, 0);
        setTimeout(() => {
            toPdf();
            this.setState({
                gettingPdf: true,
                parentWidth: parseInt(window?.frameElement?.getAttribute('data-parent-width'), 10) || window.innerWidth,
                parentHeight: parseInt(window?.frameElement?.getAttribute('data-parent-height'), 10) || window.innerHeight,
            });
        }, 200);
    }

    render() {
        const {
            copySuccess, sliderValue, gettingPdf, parentWidth, parentHeight,
        } = this.state;
        const {
            selectedEvent, onTimeDataChange, allowEdit, params, before_time, after_time, loading, pagination, videos, user,
        } = this.props;
        let shareLink = null;
        let sharePassword = null;
        if (selectedEvent && selectedEvent.share && selectedEvent.share.unique_code && selectedEvent.share.expires > 0) {
            shareLink = DEBUG || window.location.hostname === 'localhost'
                ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/event/${selectedEvent.share.unique_code}`
                : `${window.location.protocol}//${window.location.hostname}/event/${selectedEvent.share.unique_code}`;

            sharePassword = selectedEvent.share.password;
        }

        const isSharedAndValid = selectedEvent.share && selectedEvent.share.expires && selectedEvent.share.expires > 0;
        const isSharedAndExpired = selectedEvent.share && selectedEvent.share.expires && selectedEvent.share.expires <= 0;

        const mappedDevicePath = this.mapDevicePath();
        const assets = this.getAssetCurrentPin(mappedDevicePath);

        let idleTime = 0;
        let trueIdleTime = 0;
        let score = 0;
        let timeDrivenFormatted = 0;
        let averageSpeed = 0;
        let averageMpg = 0;
        let fuelLevel = 0;
        let eventType = '';
        let eventStart = '';
        let eventStartLocation = '';
        let driverAverageScore = '';
        let speedUnit = 'kph';
        let mpgUnit = 'L/100km';

        if (!isEmpty(selectedEvent.telematicsData) && !isEmpty(selectedEvent.telematicsData.journey)) {
            idleTime = selectedEvent.telematicsData.journey.idle_time;
            trueIdleTime = selectedEvent.telematicsData.journey.true_idle_time;
            score = selectedEvent.telematicsData.journey.score;
            timeDrivenFormatted = selectedEvent.telematicsData.journey.time_driven_formatted;
            averageSpeed = selectedEvent.telematicsData.journey.average_speed;
            averageMpg = selectedEvent.telematicsData.journey.mpg;
            fuelLevel = selectedEvent.telematicsData.journey.fuel_level;
        }
        if (!isEmpty(selectedEvent.telematicsData)) {
            eventType = selectedEvent.telematicsData.eventType;
            eventStart = selectedEvent.telematicsData.eventStart;
            eventStartLocation = selectedEvent.telematicsData.eventStartLocation;
            driverAverageScore = selectedEvent.telematicsData.driverAverageScore;
            if (user && user.profile && user.profile.unit_of_measurement_preference === 'british-imperial') {
                speedUnit = 'mph';
                averageSpeed = Math.floor(averageSpeed * 0.62);
                mpgUnit = 'UK mpg';
                averageMpg = averageMpg > 0 ? (282.481 / averageMpg).toFixed(2) : 0;
            }
        }

        const chartData = [
            {
                name: 'Idle Time',
                pv: idleTime || 0,
            },
            {
                name: 'True Idle',
                pv: trueIdleTime || 0,
            },
        ];

        const columns = (videos && videos.map((v) => ({
            video_id: v.id,
            date: v.start_time && moment(v.start_time)
                .format('DD/MM/YYYY'),
            time: v.start_time && v.start_time.substring(11),
            location: (
                <div className="d-flex align-center">
                    <Icon name="location" />
                    <span className="ml-1">{v.location || 'Not specified'}</span>
                </div>
            ),
            duration: (
                <div className="d-flex align-center">
                    <Icon name="clock" />
                    <span className="ml-1">{this.convertFromSeconds(v.duration)} </span>
                </div>
            ),
            actions: (
                <Button type="link" onClick={() => this.selectVideo(v)}>
                    <Icon name="chevron-right" className="ml-2" />
                </Button>
            ),
        }))) || [];

        const mapToolBarButtons = [
            {
                key: 'side-bar',
                visible: false,
            },
            {
                key: 'info-box',
                visible: true,
            },
            {
                key: 'traffic',
                visible: false,
            },
            {
                key: 'geo-fence',
                visible: false,
            },
            {
                key: 'center-map',
                visible: false,
            },
            {
                key: 'satellite',
                visible: true,
            },
            {
                key: 'heat-map',
                visible: false,
            },
            {
                key: 'live-map',
                visible: false,
            },
        ];

        const pdfRef = React.createRef();

        const pdfWidth = 3000;
        const pdfHeight = 6000;
        if (parentWidth >= 1600) {
            // pdfHeight = 1000;
        }

        return (
            <Layout
                className="events-view"
                title={<div>Event View</div>}
                headerTitle={<div>Event View Page</div>}
                showFooter={false}>
                <div className="content-wrap">
                    <div className="content">
                        <div className="content-inner" style={{ padding: 0 }}>
                            <section className="section" style={{ padding: 0 }}>
                                <div className="section__header">
                                    <div className="section__header-item" />
                                </div>

                                <div className="section__body" style={{ padding: 0 }}>
                                    <div id="pdfDocumentRef" ref={pdfRef} style={{ paddingLeft: 50 }}>
                                        <Row
                                            gutter={40}
                                            type="flex"
                                            justify="space-between"
                                            className="data-row map-row">
                                            <Col
                                                xl={24}
                                                style={{
                                                    height: 350,
                                                    width: '100%',
                                                }}>
                                                <Map
                                                    ref={this.googleMapAccess}
                                                    mapDate={moment()
                                                        .subtract('1', 'days')
                                                        .format('DD/MM/YYYY')}
                                                    apiKey={GOOGLE_MAP_API_KEY}
                                                    mapToolBarButtons={mapToolBarButtons}
                                                    sliderStep={1}
                                                    sliderValue={parseInt(sliderValue, 10)}
                                                    sliderMinValue={selectedEvent.startInterval}
                                                    sliderMaxValue={selectedEvent.endInterval}
                                                    sliderValueUnit="seconds"
                                                    hideDatePicker
                                                    center={this.centerMap}
                                                    onSliderChange={(value, formattedValue) => this.setState({ sliderValue: value })}
                                                    // heatMap={heatMap}
                                                    assets={assets}
                                                    // showToolbar={false}
                                                    allowStreetView={false}
                                                    showFindCenterButton={false}
                                                    path={mappedDevicePath} />
                                            </Col>
                                        </Row>
                                        <div className="event-view-summary-block">
                                            {allowEdit
                                                ? (
                                                    <Row gutter={[24, 24]} className="data-row" data-html2canvas-ignore="true">
                                                        <Col xs={24} md={24} lg={12} className="events-view-filter-row">
                                                            {'Showing vehicle location data '}
                                                            <Select
                                                                className="events-view-select"
                                                                size="small"
                                                                // defaultValue="900"
                                                                value={selectedEvent.startThreshold || '900'}
                                                                onChange={(value) => onTimeDataChange(value, 'before')}>
                                                                <Select.Option value="900">15 Mins.</Select.Option>
                                                                <Select.Option value="600">10 Mins.</Select.Option>
                                                                <Select.Option value="300">5 Mins.</Select.Option>
                                                                <Select.Option value="240">4 Mins.</Select.Option>
                                                                <Select.Option value="180">3 Mins.</Select.Option>
                                                                <Select.Option value="120">2 Mins.</Select.Option>
                                                                <Select.Option value="60">1 Min.</Select.Option>
                                                                <Select.Option value="45">45 Secs.</Select.Option>
                                                                <Select.Option value="30">30 Secs.</Select.Option>
                                                                <Select.Option value="15">15 Secs.</Select.Option>
                                                            </Select>
                                                            {' before and '}
                                                            <Select
                                                                className="events-view-select"
                                                                size="small"
                                                                // defaultValue="900"
                                                                value={selectedEvent.endThreshold || '900'}
                                                                onChange={(value) => onTimeDataChange(value, 'after')}>
                                                                <Select.Option value="900">15 Mins.</Select.Option>
                                                                <Select.Option value="600">10 Mins.</Select.Option>
                                                                <Select.Option value="300">5 Mins.</Select.Option>
                                                                <Select.Option value="240">4 Mins.</Select.Option>
                                                                <Select.Option value="180">3 Mins.</Select.Option>
                                                                <Select.Option value="120">2 Mins.</Select.Option>
                                                                <Select.Option value="60">1 Min.</Select.Option>
                                                                <Select.Option value="45">45 Secs.</Select.Option>
                                                                <Select.Option value="30">30 Secs.</Select.Option>
                                                                <Select.Option value="15">15 Secs.</Select.Option>
                                                            </Select>
                                                            {' after the event'}
                                                        </Col>
                                                        {allowEdit
                                                            ? (
                                                                <Col
                                                                    sm={24}
                                                                    md={24}
                                                                    lg={12}
                                                                    style={{ textAlign: 'right' }}
                                                                    className="action-column">

                                                                    {user.permissions.web_report_sharing === true ? (

                                                                        <Button
                                                                            type="primary"
                                                                            className="btn"
                                                                            onClick={() => {
                                                                                if (!isSharedAndExpired && !isSharedAndValid) {
                                                                                    const { actions } = this.props;
                                                                                    actions.shareWebReportRequest({
                                                                                        eventId: params.eventId,
                                                                                        deviceId: params.deviceId,
                                                                                        before_time,
                                                                                        after_time,
                                                                                        sliderValue,
                                                                                    });
                                                                                } else if (isSharedAndExpired) {
                                                                                    // renew the web report, add 30 days to generated_on
                                                                                    const { actions } = this.props;
                                                                                    actions.renewSharedReportRequest({
                                                                                        eventId: params.eventId,
                                                                                        deviceId: params.deviceId,
                                                                                        before_time,
                                                                                        after_time,
                                                                                        uniqueCode: selectedEvent.share.unique_code,
                                                                                    });
                                                                                }
                                                                            }}>
                                                                            <Icon name="share" />
                                                                            {isSharedAndValid ? <span>{`shared, expires in ${selectedEvent.share.expires} days`}</span> : null}
                                                                            {isSharedAndExpired ? <span>Renew web report link</span> : null}
                                                                            {!isSharedAndExpired && !isSharedAndValid ? <span>Share web report</span> : null}

                                                                        </Button>

                                                                    )
                                                                        : null}

                                                                    {/* {user.permissions.report_downloading === true ? (
                                                    <Button
                                                        type="primary"
                                                        className="btn ml-2"
                                                        onClick={() => {
                                                            if (params && params.eventId && params.deviceId) {
                                                                const { actions } = this.props;
                                                                actions.downloadPdfReportRequest({
                                                                    eventId: params.eventId,
                                                                    deviceId: params.deviceId,
                                                                    before_time,
                                                                    after_time,
                                                                    file: 'event-report.pdf',
                                                                });
                                                            }
                                                        }}>
                                                        <Spin spinning={gettingPdf}>
                                                            <Icon name="share2" />
                                                            <span>download pdf report</span>
                                                        </Spin>
                                                    </Button>
                                                ) : null} */}

                                                                    {user.permissions.report_downloading === true ? (
                                                                        <ReactToPdf
                                                                            x={0}
                                                                            y={0}
                                                                            targetRef={pdfRef}
                                                                            filename={`event_report_${moment().format('YYYY_MM_DD_HH_MM_SS')}.pdf`}
                                                                            onComplete={() => this.setState({ gettingPdf: false })}
                                                                            scale={1}
                                                                            options={{
                                                                                orientation: 'l',
                                                                                unit: 'px',
                                                                                // format: [pdfWidth, pdfHeight],
                                                                                format: [1500, 1500],
                                                                            }}>
                                                                            {({ toPdf }) => (
                                                                                <Button id="download-pdf-button" type="primary" className="btn ml-2" onClick={(e) => { this.renderPdf(toPdf); }}>
                                                                                    <Spin spinning={gettingPdf}>
                                                                                        <Icon name="share2" />
                                                                                        <span>download pdf report</span>
                                                                                    </Spin>
                                                                                </Button>
                                                                            )}
                                                                        </ReactToPdf>
                                                                    )
                                                                        : null}
                                                                </Col>
                                                            )
                                                            : (
                                                                <Col
                                                                    sm={12}
                                                                    md={12}
                                                                    lg={6}
                                                                    style={{ textAlign: 'center' }}
                                                                    className="action-column">
                                                                    <Button
                                                                        id="download-pdf-button"
                                                                        type="primary"
                                                                        className="btn ml-2"
                                                                        onClick={() => {
                                                                            if (params && params.code) {
                                                                                const { actions } = this.props;
                                                                                actions.downloadPdfReportRequest({
                                                                                    code: params.code,
                                                                                    file: 'event-report.pdf',
                                                                                });
                                                                            }
                                                                        }}>
                                                                        <Icon name="share2" />
                                                                        <span>download pdf report</span>
                                                                    </Button>
                                                                </Col>
                                                            )}
                                                    </Row>
                                                )
                                                : null}

                                            {allowEdit && shareLink && sharePassword
                                                ? (
                                                    <Row className="data-row">
                                                        <Col sm={1} md={6} />
                                                        <Col sm={20} md={7}>
                                                            <Input ref={this.textFieldRef} readOnly value={shareLink} />
                                                        </Col>
                                                        <Col sm={2} md={3} style={{ padding: '8px' }}>
                                                            <Button
                                                                type="primary"
                                                                size="small"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    fontSize: '12px',
                                                                }}
                                                                onClick={(e) => {
                                                                    this.textFieldRef.current.select();
                                                                    document.execCommand('copy');
                                                                    e.target.focus();
                                                                    this.setState({ copySuccess: 'Copied' });
                                                                    setTimeout(() => {
                                                                        this.setState({ copySuccess: '' });
                                                                    }, 3000);
                                                                }}><span style={{
                                                                    fontSize: '10px',
                                                                    lineHeight: '18px',
                                                                }}>COPY LINK
                                                                   </span> <span
                                                                    style={{ color: '#FF0000' }}>{copySuccess}
                                                                        </span>
                                                            </Button>
                                                        </Col>
                                                        <Col sm={2} md={2}>
                                                            Password: {sharePassword}
                                                        </Col>
                                                        <Col sm={1} md={6} />
                                                    </Row>
                                                )
                                                : null}
                                        </div>

                                        <Row className="data-row" />
                                        <Row gutter={[24, 24]} className="data-row event-view-driver-info" ref={this.pdfDocumentRef}>
                                            <Col xs={24} sm={24} md={12} lg={5} style={{ verticalAlign: 'top' }}>
                                                <div className="data-row-header">DRIVER INFORMATION</div>
                                                <div className="data-block">

                                                    <Row gutter={[24, 24]} className="">
                                                        <Col xs={12} sm={24} md={12} lg={10}>
                                                            <img
                                                                className="driver-info-image"
                                                                alt={(selectedEvent.info && selectedEvent.info.driver) || '[not-set]'}
                                                                // src={(selectedEvent.info && selectedEvent.info.profile_picture) || DefaultDrivePicture}
                                                                src={this.state.profile_picture} />
                                                        </Col>

                                                        <Col xs={12} sm={8} md={12} lg={14}>
                                                            <div className="title">
                                                                <p> {selectedEvent.info && selectedEvent.info.user_id
                                                                    ? (
                                                                        <Link
                                                                            to={`/drivers/view/${(selectedEvent.info && selectedEvent.info.user_id) || null}`}>{(selectedEvent.info && selectedEvent.info.driver) || '[not-set]'}
                                                                        </Link>
                                                                    )
                                                                    : (selectedEvent.info && selectedEvent.info.driver) || '[not-set]'}
                                                                </p>
                                                            </div>
                                                            <p><span className="driver">Driver</span></p>
                                                        </Col>
                                                    </Row>

                                                </div>
                                                <div className="data-block">

                                                    <Row gutter={[24, 24]} className="">
                                                        <Col xs={12} sm={8} md={12} lg={10}>
                                                            <img
                                                                className="driver-info-image"
                                                                alt={(selectedEvent.info && selectedEvent.info.registration) || '[not-set]'}
                                                                // src={(selectedEvent.info && selectedEvent.info.asset_picture) || DefaultAssetPicture}
                                                                src={this.state.asset_picture} />
                                                        </Col>
                                                        <Col xs={12} sm={8} md={12} lg={14}>
                                                            <div className="title">
                                                                {selectedEvent.info && selectedEvent.info.asset_id
                                                                    ? (
                                                                        <Link
                                                                            to={`/assets/view/${(selectedEvent.info && selectedEvent.info.asset_id) || null}`}>{(selectedEvent.info && selectedEvent.info.registration) || '[not-set]'}
                                                                        </Link>
                                                                    )
                                                                    : (selectedEvent.info && selectedEvent.info.registration) || '[not-set]'}
                                                            </div>
                                                            <Row gutter={[24, 24]} className="">
                                                                <Col xs={12} sm={12} md={24} lg={12}>
                                                                    <div className="asset-info-heading"><strong>Last service:</strong></div>
                                                                </Col>

                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    <div className="asset-info-heading"><strong>Registration</strong></div>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={[24, 24]} className="">
                                                                <Col xs={12} sm={12} md={24} lg={12}>
                                                                    <div
                                                                        className="asset-info">{(selectedEvent.info && moment(selectedEvent.info.last_service).format('DD-MM-YYYY HH:mm:ss')) || 'Unknown'}
                                                                    </div>
                                                                </Col>

                                                                <Col xs={12} sm={4} md={24} lg={12}>
                                                                    <div
                                                                        className="asset-info">{(selectedEvent.info && selectedEvent.info.registration) || '[not-set]'}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                </div>

                                                <div className="data-block">
                                                    <div className="data-row-header">EVENT INFORMATION</div>
                                                    <Row className="journey-info-row">
                                                        <Col xs={12}><strong>Event type</strong></Col>
                                                        <Col xs={12}>{eventType}</Col>
                                                    </Row>

                                                    <Row className="journey-info-row">
                                                        <Col xs={12}><strong>Event time</strong></Col>
                                                        <Col xs={12}>{eventStart}</Col>
                                                    </Row>

                                                    <Row className="journey-info-row">
                                                        <Col xs={12}><strong>Event location</strong></Col>
                                                        <Col xs={12}>{eventStartLocation}</Col>
                                                    </Row>

                                                </div>

                                            </Col>
                                            <Col xs={24} sm={32} md={12} lg={19}>
                                                <div className="data-row-header">TELEMATICS DATA</div>

                                                <Row>
                                                    <Col xs={12} sm={12} md={20} lg={4} className="telematics-data-card-col">
                                                        <div className="telematics-data-card-title"><span
                                                            className="telematics-data-card-title-text">Driver Score
                                                                                                    </span>
                                                        </div>
                                                        <Card className="telematics-data-card" style={{ height: 230 }}>
                                                            <ProgressCircle
                                                                width={160}
                                                                className="driver-score"
                                                                percent={parseFloat(driverAverageScore)}
                                                                type="circle"
                                                                strokeColor="#FF8733"
                                                                strokeWidth={11} />
                                                        </Card>
                                                    </Col>

                                                    <Col xs={12} sm={12} md={20} lg={12} className="telematics-data-card-col">
                                                        <div className="telematics-data-card-title"><span
                                                            className="telematics-data-card-title-text">Journey Info
                                                                                                    </span>
                                                        </div>
                                                        <Card style={{ padding: 0 }} className="telematics-data-card journey-info-card">
                                                            <table className="journey-info">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="journey-score-icon"><img
                                                                                src={journeyScore}
                                                                                alt="journey score" />
                                                                            </div>
                                                                            Journey Score
                                                                        </td>
                                                                        <td>{score}%</td>
                                                                        {/* <td><ArrowUpOutlined style={{ color: '#52c41a' }} /></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="journey-score-icon"><img
                                                                                src={journeyTime}
                                                                                alt="journey score" />
                                                                            </div>
                                                                            Journey time
                                                                        </td>
                                                                        <td>{timeDrivenFormatted}</td>
                                                                        {/* <td><ArrowDownOutlined style={{ color: 'red' }} /></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="journey-score-icon"><img
                                                                                src={journeySpeed}
                                                                                alt="journey score" />
                                                                            </div>
                                                                            Journey Average speed
                                                                        </td>
                                                                        <td>{averageSpeed} {speedUnit}</td>
                                                                        {/* <td><ArrowUpOutlined style={{ color: '#52c41a' }} /></td> */}
                                                                    </tr>
                                                                </tbody>

                                                            </table>
                                                        </Card>
                                                    </Col>

                                                    <Col xs={24} sm={4} md={6} lg={4} className="telematics-data-card-col">
                                                        <div className="telematics-data-card-title">
                                                            <span
                                                                className="telematics-data-card-title-text">Idle
                                                            </span>
                                                        </div>
                                                        <Card
                                                            className="telematics-data-card"
                                                            style={{
                                                                width: 250,
                                                                height: 230,
                                                            }}>
                                                            <BarChart
                                                                className="idle-chart"
                                                                width={260}
                                                                height={200}
                                                                data={chartData}
                                                                barSize={30}
                                                                barGap={5}
                                                                margin={{}}>
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="name" />
                                                                <YAxis />
                                                                <ToolTipChart formatter={(value) => secondsToHms(value)} />
                                                                <Bar dataKey="pv" stackId="a" fill="#FF8733" />
                                                                <Bar dataKey="uv" stackId="a" fill="#eff3f5" />
                                                            </BarChart>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs={24} sm={5} md={0} lg={5} style={{ verticalAlign: 'top' }} />

                                            <Col xs={32} sm={4} md={18} lg={32}>
                                                <Row>
                                                    <Col xs={32} sm={4} md={9} lg={5} className="telematics-data-card-col first">
                                                        <div className="telematics-data-card-title">
                                                            <span className="telematics-data-card-title-text">Activations
                                                            </span>
                                                        </div>
                                                        <Card className="telematics-data-card" style={{ height: 100 }}>

                                                            <Row gutter={[24, 24]}>
                                                                <Col xs={8} sm={4} md={8} lg={6}>
                                                                    <div className="activations-icon">
                                                                        <img src={cyclearLogo} alt="journey score" />
                                                                    </div>
                                                                </Col>
                                                                <Col xs={8} sm={4} md={8} lg={14}>
                                                                    <div className="activations-title">
                                                                        Cyclear Activations
                                                                    </div>
                                                                </Col>
                                                                <Col xs={8} sm={4} md={8} lg={2}>
                                                                    <div className="activations-stat">
                                                                        0
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>

                                                    <Col xs={32} sm={4} md={9} lg={5} className="telematics-data-card-col middle">
                                                        <Card style={{ height: 121 }}>
                                                            <div className="telematic-box-stat-icon">
                                                                <img src={averageMpgIcon} alt="journey score" />
                                                            </div>
                                                            <div className="telematic-box-stat-text">
                                                                AVERAGE MPG
                                                            </div>
                                                            <div className="telematic-box-stat">
                                                                {averageMpg} {mpgUnit}
                                                            </div>
                                                        </Card>
                                                    </Col>

                                                    <Col xs={32} sm={4} md={9} lg={5} className="telematics-data-card-col fuel-progress middle">
                                                        <Card style={{ height: 121 }}>
                                                            <div className="telematic-box-stat-icon">
                                                                <img src={fuelIcon} alt="journey score" />
                                                            </div>
                                                            <div className="telematic-box-stat-text">
                                                                FUEL LEVEL
                                                            </div>
                                                            <div className="telematic-box-stat">
                                                                {fuelLevel}%
                                                                <Progress
                                                                    className="telematics-fuel-level-progress"
                                                                    type="line"
                                                                    percent={parseFloat(fuelLevel)}
                                                                    showInfo={false}
                                                                    strokeColor="#FF8733"
                                                                    strokeLinecap="square"
                                                                    strokeWidth={10} />

                                                            </div>

                                                        </Card>
                                                    </Col>

                                                    <Col xs={32} sm={4} md={9} lg={9} xl={5} className="telematics-data-card-col first">
                                                        <div className="telematics-data-card-title">
                                                            <span
                                                                className="telematics-data-card-title-text">Activations
                                                            </span>
                                                        </div>
                                                        <Card className="telematics-data-card" style={{ height: 100 }}>

                                                            <Row gutter={[24, 24]}>

                                                                <Col xs={8} sm={4} md={14} lg={14}>
                                                                    <div className="activations-title">
                                                                        Reverse Activations
                                                                    </div>
                                                                </Col>
                                                                <Col xs={8} sm={4} md={2} lg={2}>
                                                                    <div className="activations-stat">
                                                                        2
                                                                    </div>
                                                                </Col>

                                                            </Row>

                                                        </Card>
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <Col xs={24} sm={5} md={0} lg={5} style={{ verticalAlign: 'top' }} />

                                            <Col xs={32} sm={4} md={18} lg={28}>
                                                <Row>
                                                    <Col xs={32} sm={4} md={9} lg={5} className="telematics-data-card-col first">
                                                        <div className="telematics-data-card-title"><span
                                                            className="telematics-data-card-title-text">Activations
                                                                                                    </span>
                                                        </div>
                                                        <Card className="telematics-data-card" style={{ height: 100 }}>

                                                            <Row gutter={[24, 24]}>
                                                                <Col xs={8} sm={4} md={8} lg={8}>
                                                                    <div className="activations-icon">
                                                                        <img src={idClearLogo} alt="journey score" />
                                                                    </div>
                                                                </Col>
                                                                <Col xs={8} sm={4} md={12} lg={12}>
                                                                    <div className="activations-title">
                                                                        IDClear Activations
                                                                    </div>
                                                                </Col>
                                                                <Col xs={8} sm={4} md={2} lg={2}>
                                                                    <div className="activations-stat">
                                                                        0
                                                                    </div>
                                                                </Col>

                                                            </Row>

                                                        </Card>
                                                    </Col>

                                                    <Col xs={32} sm={4} md={9} lg={5} className="telematics-data-card-col">
                                                        <div className="telematics-data-card-title"><span
                                                            className="telematics-data-card-title-text">Activations
                                                                                                    </span>
                                                        </div>
                                                        <Card className="telematics-data-card" style={{ height: 100 }}>

                                                            <Row gutter={[24, 24]}>
                                                                <Col xs={8} sm={4} md={8} lg={8}>
                                                                    <div className="activations-icon">
                                                                        <img src={reclearLogo} alt="journey score" />
                                                                    </div>
                                                                </Col>
                                                                <Col xs={8} sm={4} md={12} lg={12}>
                                                                    <div className="activations-title">
                                                                        Reaclear Activations
                                                                    </div>
                                                                </Col>
                                                                <Col xs={8} sm={4} md={2} lg={2}>
                                                                    <div className="activations-stat">
                                                                        0
                                                                    </div>
                                                                </Col>

                                                            </Row>

                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>

                                        </Row>

                                        <Row
                                            className="data-row">
                                            <Col
                                                xs={24}
                                                style={{
                                                    verticalAlign: 'top',
                                                    width: '100%',
                                                }}>
                                                <div className="data-row-header">VIDEOS AROUND THE TIME OF THE EVENT</div>
                                                <div className="data-block" style={{ width: '100%' }}>
                                                    <Spin spinning={loading} style={{ width: '100%' }}>
                                                        <GridView
                                                            data={columns}
                                                            onChange={this.handleTableChange}
                                                            sortableColumns={[]}
                                                            pagination={{
                                                                total: pagination.totalCount || 0,
                                                                pageSize: pagination.perPage,
                                                                showSizeChanger: false,
                                                                onChange: this.paginationChange,
                                                            }} />
                                                    </Spin>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </Layout>

        );
    }
}

EventViewPage.propTypes = {
    selectedEvent: PropTypes.object.isRequired,
    onTimeDataChange: PropTypes.func.isRequired,
    allowEdit: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    before_time: PropTypes.number.isRequired,
    after_time: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
    videos: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    updateMapEventData: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        updateMapEventData: state.events.updateMapEventData,
        selectedEvent: state.events.selectedEvent,
        loading: state.video.isFetching,
        pagination: state.video.surroundingPagination,
        videos: state.video.surroundingVideos,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...videoActions,
                ...eventsActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventViewPage);
