/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import { Spin, Button, Select } from 'antd';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';

import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';
import './divisionsView.scss';
import LeftSide from './partials/LeftSide';
import GridView from '../../components/elements/GridView';


const { Option } = Select;


export default function () {
    const {
        divisionAssets,
        divisionAssetsPagination,
        divisionAssetsIsFetching,
        divisionDetail,
        otherAssets,
    } = this.props;
    const data = divisionAssets.map((d) => ({
        id: d.id,
        name: d.name,
        registration: d.asset_reg,
        last_online: d.last_online,
        status: parseInt(d.status, 10) === 1 ? 'Active' : 'Not Active',
        actions: (
            <Button className="btn btn-empty ml-2" onClick={() => this.removeAsset(d.id)}>
                <CloseOutlined style={{ color: '#f5222d' }} />
            </Button>
        ),
    }));

    const filterOptions = (inputValue, option) => option.key.includes(inputValue);

    return (
        <Layout
            data-test="pages-divisions-view"
            className="events-view"
            title={<div>AssetsView</div>}
            headerTitle={<div>AssetsView Page</div>}
            showFooter={false}>
            <div className="content-wrap">
                <FilterSidebar />
                <div className="content">
                    <Spin spinning={divisionAssetsIsFetching}>
                        <div className="content-inner">
                            <section className="section">
                                <div className="section__header">
                                    <div className="section__header-item">
                                        <Link
                                            to="/divisions"
                                            className="d-flex align-center">
                                            <Icon name="arrow-left" />
                                            <span>All divisions</span>
                                        </Link>
                                    </div>
                                    <div className="section__header-item is-active">
                                        <span>{divisionDetail.name}</span>
                                    </div>
                                </div>
                                <div className="section__body">

                                    <div className="right-header-wrapper">
                                        <h4 className="right-header-head"><span style={{ fontWeight: 'bold' }}>Division details for:</span> {divisionDetail.name}</h4>
                                        <Button
                                            onClick={() => this.submitUpdateDepartmentForm()}
                                            type="primary"
                                            className="btn">
                                            <SaveOutlined />
                                            <span>
                                                Save
                                            </span>
                                        </Button>
                                    </div>
                                    <div className="division-wrapper">

                                        <div className="left-side-wrapper">
                                            <LeftSide registerSubmitHandler={(f) => { this.submitUpdateDepartmentForm = f; }} />
                                        </div>
                                        <div className="right-side-wrapper">
                                            <div>
                                                <GridView
                                                    data={data}
                                                    onChange={this.onGridChange}
                                                    sortableColumns={[]}
                                                    pagination={{
                                                        total: parseInt(divisionAssetsPagination.totalRecords, 10) || 0,
                                                        pageSize: parseInt(divisionAssetsPagination.perPageCount, 10) || 10,
                                                        showSizeChanger: false,
                                                        onChange: this.paginationChange,
                                                    }} />
                                                <div className="assignment-section">
                                                    <h3 className="assignment-header">Assign assets to this division</h3>
                                                    <Select value="" className="assignment-select" showSearch filterOption={filterOptions} onChange={() => null}>
                                                        {otherAssets.map((item) => (
                                                            <Option key={`${item.id}-${item.name}-${item.asset_reg}`} value={item.id}>
                                                                <div className="button-option">
                                                                    {
                                                                        // eslint-disable-next-line no-nested-ternary
                                                                        item.name === null || item.name === ''
                                                                            ? item.asset_reg === null || item.asset_reg === ''
                                                                                ? 'Unknown asset'
                                                                                : item.asset_reg
                                                                            : `${item.name}`
                                                                    }
                                                                    {
                                                                        item.name === item.asset_reg || item.asset_reg === null || item.asset_reg === ''
                                                                            ? ''
                                                                            : `(${item.asset_reg})`
                                                                    }
                                                                    <Button onClick={() => this.assignAsset(item.id)}>Add</Button>
                                                                </div>
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Spin>
                </div>
            </div>
        </Layout>
    );
}
