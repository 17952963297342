import React from 'react';

import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Select, Button, Input } from 'antd';
import { isEmpty } from 'underscore';

// import LogoMain from '../LogoMain';

import Icon from '../../../components/elements/Icon';
import './filterForm.css';

const FormItem = Form.Item;

export default function () {
    const { props } = this;
    const { form, departments, divisions, driverList } = props;
    const { getFieldDecorator } = form;

    const { Option } = Select;

    return (
        <Form onSubmit={this.handleSubmit} layout="vertical">
            <Form.Item label="Driver">
                {getFieldDecorator('driver')(
                    <Select
                        mode="multiple"
                        placeholder="Select driver"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        suffixIcon={<Icon name="triangle-down" />}>
                        <Select.Option value="all">Any</Select.Option>
                        {driverList && Array.isArray(driverList) && driverList.length 
                            ? driverList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)
                            : null
                        }
                    </Select>,
                )}
            </Form.Item>
            <FormItem label="Division">
                {getFieldDecorator('division')(
                    <Select
                        mode="multiple"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        placeholder="Division"
                        suffixIcon={<Icon name="triangle-down" />}>
                        {!isEmpty(divisions)
                            ? divisions.map((item) => (
                                <Option key={item.id} value={item.id}>{item.name}</Option>
                            ))
                            : null}
                    </Select>,
                )}
            </FormItem>
            <div className="mt-2 text-center">
                <Button htmlType="submit" type="primary" className="btn extrawide" size="large">
                    Filter
                </Button>
            </div>
        </Form>
    );
}
