/* eslint-disable react/no-unused-state */
/* global localStorage */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Modal, Tooltip } from 'antd';
import {
    difference,
    each, findIndex, isArray, isEmpty, isNull,
} from 'underscore';
import queryString from 'query-string';
import {
    AlertFilled, ApiFilled, BellFilled, EyeFilled, FlagFilled, KeyOutlined, 
} from '@ant-design/icons';
import history from '../../core/utils/history';
import { getDistanceInMiles, getUserCurrentLocation, getAddressFromLatLong } from '../../core/utils/api';

import DOM from './map';
import * as deviceActions from '../../core/device/deviceActions';
import * as assetActions from '../../core/asset/assetActions';
import * as globalActions from '../../core/global/globalActions';
import * as eventsActions from '../../core/events/eventsActions';
import * as userActions from '../../core/user/userActions';
import * as videoActions from '../../core/video/videoActions';
import * as reportActions from '../../core/report/reportActions';
import MapAssetPopup from '../../components/elements/MapAssetPopup';
import GeofenceCreatePng from '../../assets/images/geofence-create.png';

import {
    openStreetView,
    goToEventPage,
    insidePolygon,
    timeToSeconds,
    getMinutesSliderValueFromDate,
    getSecondsSliderValueFromDate,
} from '../../core/utils/functions';
import { setMarkerPopoverDetails, setMarkerPopoverToLoading } from '../../core/utils/mapUtils';

const {
    MapAssetPopupHead,
    MapAssetPopupContent,
} = MapAssetPopup;
const { MAP_SLIDER_INTERVAL, MAP_LIVE_ASSET_DELAY, GEOFENCE_REPORT_FEATURE } = require('../../core/constants').default;

export const MAP_MODE_DOTS = 'dots';
const SIDEBAR_TOOLTIP_DELAY = 1000;
const AUTOZOOM_DELAY = 1000;
export const MAP_MODE_LINE = 'line';

class Map extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.googleMapAccess = React.createRef();
        this.mapUpdateInterval = null;

        this.state = {
            showEditTrigger: false,
            clearGeofence: false,
            filterSidebar: false,
            modalAlertVisible: false,
            modalCacheVisible: false,
            modalOptionsVisible: false,
            assetsType: 2,
            tags: ['Unremovable', 'Tag 2', 'Tag 3'],
            inputVisible: false,
            inputValue: '',
            selectedAsset: null,
            selectedAssetLocation: '',
            selectedDate: props.mapDate || moment().format('YYYY-MM-DD'),
            selectedTime: null,
            zoom: 6,
            showSidebar: true,
            showDeviceSidebar: false,
            showAssetsSidebar: true,
            assetDistanceFromCentre: 0,
            newGeoFencePath: [],
            sliderChangeTimeout: 0,
            isMapLive: false,
            liveInterval: null,
            selectedAssetDataRefreshInterval: null,
            showLiveAssetsInterval: null,
            mapLiveAssets: [],
            singleTrigger: {},
            selectedAssetRecordId: 0,
            checkedAssets: ['999'],
            checkedHeatmapKeys: [],
            toggleShowGeoFence: false,
            currentHoveredAsset: null,
            sliderValue: 0,
            executeAutoZoom: false,
            markerPopover: null,
            hasGeoFenceReportEnabled: false,
            timesheetReport: null,
            mapMode: MAP_MODE_LINE,
            centreMapLatLong: null,
            prevCheckedAssets: [],
            googleMapTilesLoaded: false,
            selectedAssetIsLoading: false,
        };
        this.getUserCurrentLocation();
    }

    ignoreAutoZoom = false; // used to avoid autozoom under certain conditions

    ignoreHeatmapZoom = false; // used to avoid zooming in on asset on date change when we have heatmaps selected

    componentDidMount() {
        const { actions, selectedSliderValue } = this.props;

        actions.resetFetchings();
        const values = queryString.parse(this.props.location.search);
        let sliderValue;
        if (isEmpty(values)) {
            this.toggleLiveView();
            sliderValue = getMinutesSliderValueFromDate();
        } else {
            this.markerOnClick(values);
            sliderValue = getSecondsSliderValueFromDate();
        }

        this.checkGeofencePermission();

        // Reset the slider
        actions.updateSliderValue(sliderValue);
        actions.getCompanyAllTriggersRequest();
        actions.getEventTypesRequest();
        actions.mapGetLiveAssetsLocationRequest();
        actions.mapDevicesLastLocationRequest({ date: moment().format('YYYY-MM-DD') });

        setTimeout(() => {
            if (this.googleMapAccess && this.googleMapAccess.current) {
                this.googleMapAccess.current.setState({ sliderValue: selectedSliderValue });
            }
        }, 3200);
        setMarkerPopoverToLoading(this);
    }

    componentWillUnmount() {
        const { liveInterval, showLiveAssetsInterval, selectedAssetDataRefreshInterval } = this.state;
        const { actions } = this.props;
        if (this.mapUpdateInterval !== null) {
            clearInterval(this.mapUpdateInterval);
        }
        if (liveInterval !== null) {
            clearInterval(liveInterval);
            this.setState({ liveInterval: null });
        }
        if (showLiveAssetsInterval !== null) {
            clearInterval(showLiveAssetsInterval);
            this.setState({ showLiveAssetsInterval: null });
        }
        if (selectedAssetDataRefreshInterval !== null) {
            clearInterval(selectedAssetDataRefreshInterval);
            this.setState({ selectedAssetDataRefreshInterval: null });
        }

        // clear device data from local storage
        localStorage.removeItem('device');
        actions.resetMapDataRequest();
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            actions, isFetching, mapDeviceLocationRequest, mapDeviceHeatMapRequest, mapHeatMapDataUpdated, mapDevices,
            mapDevicesLoaded, deviceDetailedLocationInfo, deviceDetailedLocationFetching, shardId, user, mapSelectedDeviceInfo,
            isFetchingDeviceLocation, mapSelectedDeviceLocations
        } = this.props;
        const {
            executeAutoZoom, selectedDate, checkedAssets, selectedAsset, selectedAssetIsLoading
        } = this.state;
        let numeratorSliderValue = 1430; //  not understood but working, last step in minutes?
        if (selectedAsset) numeratorSliderValue = 86399; // copying above logic
        const tx = this.googleMapAccess && this.googleMapAccess.current;
        if (selectedAsset && !isFetching && selectedAssetIsLoading) {
            if (!isFetchingDeviceLocation && !mapSelectedDeviceLocations?.length) {
                this.pathGeneratedCallback(); //otherwise the executeAutoZoom will handle it
            }
        }
        if (isFetching === false && mapDeviceLocationRequest === true && this.googleMapAccess && this.googleMapAccess.current) {
            // do not do autozoom this time as the path is not recalcuated yet
            this.setState({ executeAutoZoom: true });
            actions.updateMapDeviceRequestFlag(false);
        }

        if (executeAutoZoom) {
            this.setState({ executeAutoZoom: false });
            if (this.ignoreHeatmapZoom === false) {
                setTimeout(() => {
                    if (this.googleMapAccess.current) {
                        this.googleMapAccess.current.executeAutoZoom(checkedAssets.length === 1 && !selectedAsset ? 8 : null);
                    }
                }, AUTOZOOM_DELAY);
            }
            this.ignoreHeatmapZoom = false;
        }

        if (isFetching === false && mapDevicesLoaded === true && this.googleMapAccess && this.googleMapAccess.current) {
            actions.updateMapDeviceLoaded(false);
            if (this.ignoreAutoZoom === false) {
                this.googleMapAccess.current.executeAutoZoom(checkedAssets.length === 1 ? 8 : null);
            }
            this.ignoreAutoZoom = false;
        }

        if (isFetching === false && mapHeatMapDataUpdated === true) {
            if (this.googleMapAccess && this.googleMapAccess.current) {
                this.googleMapAccess.current.refreshMap();
            }
            actions.setHeatMapDataUpdated(false);
        }

        if (isFetching === false && mapDevices != prevProps.mapDevices) {
            if (selectedAsset) numeratorSliderValue = 86404; // copied from below
            else numeratorSliderValue = 1480; // not understood but working, 4 steps above max?
        }
        this.setMaxSliderValue(numeratorSliderValue);

        if (isFetching === false && mapDeviceHeatMapRequest === true && this.googleMapAccess && this.googleMapAccess.current) {
            actions.updateMapHeatMapRequestFlag(false);
        }

        if (deviceDetailedLocationFetching === true && prevProps.deviceDetailedLocationFetching === false) {
            setMarkerPopoverToLoading(this);
        }

        if (deviceDetailedLocationFetching === false && deviceDetailedLocationInfo !== prevProps.deviceDetailedLocationInfo) {
            const { showLive } = this.googleMapAccess.current.state;
            setMarkerPopoverDetails(this, showLive);
        }

        if (prevProps.shardId !== shardId) {
            actions.mapAssetSearchRequest({ date: selectedDate });
            setTimeout(() => {
                if (this.googleMapAccess && this.googleMapAccess.current) {
                    this.googleMapAccess.current.executeAutoZoom();
                }
            }, 300);
        }

        if (prevState.checkedAssets !== checkedAssets) {
            actions.setCheckedAssets(checkedAssets);
            if (checkedAssets && checkedAssets.length > 0) {
                if (this.ignoreAutoZoom === false) {
                    setTimeout(() => {
                        if (this.googleMapAccess && this.googleMapAccess.current) {
                            this.googleMapAccess.current.executeAutoZoom(checkedAssets.length === 1 && !selectedAsset ? 8 : null);
                        }
                    }, 300);
                }
            } else {
                actions.setMapDevices([]);
            }
        }
        if (prevProps.user?.userCompany != user.userCompany) {
            this.checkGeofencePermission();
        }
    }

    checkGeofencePermission() {
        const { user } = this.props;
        if (isArray(user?.userCompany?.features)) {
            const hasGeoFenceReportEnabled = !!user.userCompany.features.find((feature) => feature.id === GEOFENCE_REPORT_FEATURE);
            this.setState({ hasGeoFenceReportEnabled });
        }
    }

    setMaxSliderValue(value) {
        const { selectedAsset } = this.state;

        let numeratorSliderValue = value;
        if (this.state.selectedDate === moment().format('YYYY-MM-DD') && this.googleMapAccess.current.state.mapAssetDate === moment().format('DD-MM-YYYY')) {
            if (selectedAsset) numeratorSliderValue = Math.floor((parseInt(moment().format('HH')) * 60) + (parseInt(moment().format('mm'))));
            else numeratorSliderValue = Math.floor((parseInt(moment().format('HH')) * 3600) + (parseInt(moment().format('mm')) * 60) + parseInt(moment().format('ss')));
        }
        const maxSliderValue = ((numeratorSliderValue) / (selectedAsset ? 10 : (MAP_SLIDER_INTERVAL || 10)));
        this.googleMapAccess.current.setState({
            sliderMaxValue: maxSliderValue,
        });
    }

    findSingleTrigger(id) {
        const { allTriggers } = this.props;
        if (id) {
            const triggerIndex = findIndex(allTriggers, (val) => val.id === id);
            if (triggerIndex !== -1) {
                this.setState({ singleTrigger: allTriggers[triggerIndex] });
                return true;
            }
        }
        this.setState({ singleTrigger: {} });
    }

    getMapAssets = (date = null) => {
        if (this.mapUpdateInterval != null) {
            clearInterval(this.mapUpdateInterval);
        }
        const {
            actions, mapDevicesFetchTime, mapDevices,
        } = this.props;
        const { selectedDate, checkedAssets } = this.state;
        const validDate = date ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') : selectedDate;

        // Check if devices were loaded 10 mins before, if true then load again
        const mapFetchedTime = moment(mapDevicesFetchTime, 'YYYY-MM-DD HH:mm:ss');
        const nowTime = moment();
        const fetchGapTime = moment.duration(nowTime.diff(mapFetchedTime));

        const sameDayCondition = validDate == moment().format('YYYY-MM-DD') && fetchGapTime.asMinutes() >= 10;
        const otherDaysCondition = validDate != moment().format('YYYY-MM-DD');
        const differentDateSelected = date ? selectedDate != moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') : false;

        if (differentDateSelected || otherDaysCondition) {
            const assets = [];
            checkedAssets.forEach((asset) => {
                if (asset != 999) {
                    const assetData = asset.split('-');
                    if (assetData.length === 3 && assetData[0] === 'asset') {
                        const divisionId = assetData[1];
                        const assetId = assetData[2];
                        assets.push(assetId);
                    }
                }
            });
            if (assets.length > 0) {
                actions.mapDeviceListRequest({
                    date: validDate,
                });
                actions.mapDevicesLastLocationRequest({
                    date: validDate,
                });
            }
        }
    };

    getDeviceLocations = () => {
        const { actions, checkedAssets } = this.props;
        const { selectedDate, checkedAssets: newCheckedAssets } = this.state;
        const assets = [];
        newCheckedAssets.forEach((asset) => {
            if (asset != 999 && !checkedAssets.includes(asset)) {
                const assetData = asset.split('-');
                if (assetData.length === 3 && assetData[0] === 'asset') {
                    const divisionId = assetData[1];
                    const assetId = assetData[2];
                    assets.push(assetId);
                }
            }
        });

        if (assets.length > 0) {
            actions.mapDeviceListRequest({
                date: selectedDate,
                assets,
            });
            actions.mapDevicesLastLocationRequest({
                date: selectedDate,
                assets,
            });
        }
    };

    sidebarToolBarButtonOnclick = () => {
        const { showSidebar, selectedAsset } = this.state;
        // If we have a selected asset, then open the sidebar with all the assets and deselect the current asset
        if (selectedAsset) {
            const { actions } = this.props;
            history.push('/map');
            actions.resetAssetsRequest();

            this.setState({
                selectedAsset: null,
            });

            setTimeout(() => {
                if (this.googleMapAccess && this.googleMapAccess.current) {
                    this.googleMapAccess.current.executeAutoZoom();
                }
            }, 1000);
        } else {
            this.setState({
                showAssetsSidebar: !showSidebar,
                showSidebar: !showSidebar,
                selectedAsset: null,
            });
        }
    };

    closeAssetsSearchSidebar = () => {
        const { actions } = this.props;
        const { isMapLive } = this.state;

        if (!isMapLive) {
            this.toggleLiveView();
            this.setState({
                selectedAsset: null,
                isMapLive: true,
                checkedAssets: this.state.prevCheckedAssets,
                prevCheckedAssets: [],
            }, () => {
                history.push('/map');
            });
        } else {
            history.push('/map');
            actions.resetAssetsRequest();
            this.setState({ showAssetsSidebar: false, showSidebar: false });
            setTimeout(() => {
                if (this.googleMapAccess && this.googleMapAccess.current) {
                    this.googleMapAccess.current.executeAutoZoom();
                }
            }, 1000);
        }
    };

    assetSidebarBackLink = () => {
        const { actions, previousMapStatusLive } = this.props;
        const { selectedAssetDataRefreshInterval } = this.state;
        history.push('/map');
        this.setState({
            showDeviceSidebar: false,
            showSidebar: true,
            showAssetsSidebar: true,
            selectedAsset: null,
        });
        actions.setSelectedAsset(null);
        if (previousMapStatusLive) {
            actions.mapGetLiveAssetsLocationRequest({});
            this.setState({
                selectedDate: moment().format('YYYY-MM-DD'),
                isMapLive: true,
            });
            setTimeout(() => {
                if (this.googleMapAccess && this.googleMapAccess.current) {
                    this.googleMapAccess.current.setState({ showLive: true });
                }
            }, 300);
        }
        if (selectedAssetDataRefreshInterval !== null) {
            clearInterval(selectedAssetDataRefreshInterval);
            this.setState({ selectedAssetDataRefreshInterval: null });
        }

        this.getMapAssets();
        setTimeout(() => {
            if (this.googleMapAccess && this.googleMapAccess.current) {
                this.googleMapAccess.current.executeAutoZoom();
            }
        }, 300);
    };

    mapOnDateChange = (date) => {
        const {
            actions, selectedSliderValue, mapDeviceLocationRequest, isFetching,
        } = this.props;
        const {
            selectedAsset, selectedAssetRecordId, checkedAssets, checkedHeatmapKeys, 
        } = this.state;
        const modifiedDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (!isEmpty(selectedAsset)) {
            this.setState({
                selectedDate: modifiedDate,
            });
            actions.getDeviceTimelineRequest({
                date: modifiedDate,
                imei: selectedAsset,
            });
            actions.getDeviceGeofenceTimelineRequest({
                date: modifiedDate,
                imei: selectedAsset,
            });
        } else {
            this.setState({
                selectedDate: modifiedDate,
                selectedAsset: null,
            });
            actions.setSelectedAsset(null);
        }

        actions.updateMapDate(modifiedDate);

        if (this.googleMapAccess && this.googleMapAccess.current) {
            const { heatMapInstance } = this.googleMapAccess.current.state;
            if (heatMapInstance !== null) {
                heatMapInstance.setMap(null);
                this.googleMapAccess.current.setState({ heatMapInstance: null });
            }
        }

        const { imei } = queryString.parse(this.props.location.search);
        // If it is today's date then start interval
        // else clear interval and make call for that day
        if (date == moment().format('DD/MM/YYYY') && !imei) {
            this.getMapAssets(date);
        } else {
            if (this.mapUpdateInterval != null) {
                clearInterval(this.mapUpdateInterval);
            }

            if (!isEmpty(selectedAsset)) {
                actions.mapDeviceListRequest({
                    date: modifiedDate,
                    imei: selectedAsset,
                    slider_unit_type: 'seconds',
                    slider_unit_size: 10,
                });
                actions.mapDevicesLastLocationRequest({
                    date: modifiedDate,
                    imei: selectedAsset,
                    slider_unit_type: 'seconds',
                    slider_unit_size: 10,
                });
                const selectedSliderValueAsTime = moment.utc(selectedSliderValue * 1000).format('HH:mm:ss');
                actions.mapDeviceLocationsRequest({
                    imei: selectedAsset,
                    date: `${modifiedDate} ${selectedSliderValueAsTime}`,
                    record_id: selectedAssetRecordId,
                });

                if (checkedHeatmapKeys && checkedHeatmapKeys.length) {
                    this.refreshHeatmap(checkedHeatmapKeys);
                    this.ignoreHeatmapZoom = true;
                }
                this.googleMapAccess.current.refreshMap();

                // we might need to refresh the map to update with the new data
            } else {
                const assets = [];
                checkedAssets.forEach((asset) => {
                    if (asset != 999) {
                        const assetData = asset.split('-');
                        if (assetData.length === 3 && assetData[0] === 'asset') {
                            const divisionId = assetData[1];
                            const assetId = assetData[2];
                            assets.push(assetId);
                        }
                    }
                });
                if (assets.length > 0) {
                    actions.mapDeviceListRequest({
                        date: modifiedDate,
                        assets,
                    });
                    actions.mapDevicesLastLocationRequest({
                        date: modifiedDate,
                        assets,
                    });
                    actions.getMapEventsHeatMapRequest({
                        date: modifiedDate,
                        assets,
                    });
                }
            }
        }

        setTimeout(() => {
            if (this.googleMapAccess && this.googleMapAccess.current) {
                if (!mapDeviceLocationRequest && !isFetching) {
                    this.googleMapAccess.current.refreshMap();
                    this.googleMapAccess.current.executeAutoZoom();
                }
                this.googleMapAccess.current.setState({
                    sliderValue: selectedSliderValue,
                });
            }
        }, 100);
    };

    getUserCurrentLocation = () => {
        const center = getUserCurrentLocation() || { lat: 52.48759, lng: -1.91199 };
        setTimeout(() => {
            const { actions } = this.props;
            actions.setUserMapCenter(center);
        }, 300);
    };

    centerMapPreviousLatLng = null;

    centreMap = (e) => {
        const { actions, previousMapStatusLive } = this.props;
        const {
            selectedAsset, selectedAssetDataRefreshInterval, checkedAssets, selectedDate, isMapLive, 
        } = this.state;

        const key = String(e.key);
        const { lat } = e.marker;
        const { lng } = e.marker;
        const sameAssetClicked = (
            this.centerMapPreviousLatLng
            && this.centerMapPreviousLatLng.lat === lat
            && this.centerMapPreviousLatLng.lng === lng
        );

        // If clicked on the same marker asset again (exact same lat/lng) then zoom back to all assets
        if (sameAssetClicked) {
            setMarkerPopoverToLoading(this);
            setTimeout(() => { this.googleMapAccess.current.executeAutoZoom(checkedAssets.length === 1 ? 8 : null); }, 500);
            this.ignoreAutoZoom = true;
        }
        if (this.centerMapPreviousLatLng && sameAssetClicked) this.centerMapPreviousLatLng = null;
        else this.centerMapPreviousLatLng = { lat, lng };

        // Select the checkbox for this asset in the sidebar if it was not selected already.
        //   This makes the marker visible so that when we zoom to marker it is clear what is happening.
        let newCheckedAsset;
        if (!checkedAssets.length) {
            newCheckedAsset = key;
        } else {
            const matches = [];
            for (let i = 0; i < checkedAssets.length; i++) {
                if (checkedAssets[i] === '999') { // 999 = all asset checkboxes selected
                    matches.push('999');
                    break;
                }
                if (String(checkedAssets[i]) === key) {
                    matches.push(checkedAssets[i]);
                }
            }
            if (!matches.length) {
                newCheckedAsset = key;
            }
        }
        const newCheckedAssets = [...checkedAssets];
        if (newCheckedAsset) {
            newCheckedAssets.push(newCheckedAsset);
            this.setState({ checkedAssets: newCheckedAssets });
            actions.setCheckedAssets(newCheckedAssets);

            const newCheckedAssetId = newCheckedAsset.split('-')[2];
            actions.mapDeviceListRequest({
                date: selectedDate,
                assets: [newCheckedAssetId],
            });
            actions.mapDevicesLastLocationRequest({
                date: selectedDate,
                assets: [newCheckedAssetId],
            });
        } else if (newCheckedAssets.length < 1) {
            actions.setMapDevices([]);
        }

        // If an asset was selected then we need to unselect it, refetch live assets, reset URL, etc.
        let centerMapDelay = 0;
        if (selectedAsset) {
            centerMapDelay = 2000;
            history.push('/map');
            actions.setSelectedAsset(null);
            this.setState({ selectedAsset: null });
            this.getMapAssets();
            if (selectedAssetDataRefreshInterval !== null) {
                clearInterval(selectedAssetDataRefreshInterval);
                this.setState({ selectedAssetDataRefreshInterval: null });
            }
            if (previousMapStatusLive) {
                actions.mapGetLiveAssetsLocationRequest({});
                this.setState({
                    selectedDate: moment().format('YYYY-MM-DD'),
                    isMapLive: true,
                });
                setTimeout(() => {
                    if (this.googleMapAccess && this.googleMapAccess.current) {
                        this.googleMapAccess.current.setState({ showLive: true });
                    }
                }, 300);
            }
        } else {
            // If we didn't have an asset selected and we are in offline mode then use map zoom to zoom to selected markers.
            //   Ignore this if we should be zooming out after clicking the same asset twice
            //   centreMapLatLong method only works for live markers.
            if (!isMapLive && !sameAssetClicked) {
                this.ignoreAutoZoom = true;
                setTimeout(() => { this.googleMapAccess.current.executeAutoZoom(16, parseInt(key.split('-')[2])); }, newCheckedAsset ? 2000 : 500);
                return;
            }
        }

        // If map was not live then proceed to centre the map
        this.ignoreAutoZoom = true;
        setTimeout(() => {
            this.setState({
                centreMapLatLong: {
                    lat: Number(lat),
                    lng: Number(lng),
                },
            });
        }, centerMapDelay);
    };

    onSliderValueChange = (value, formattedValue) => {
        const { actions } = this.props;
        const { ignoreSliderValueChange } = this.state;

        // A patch until we release new map component
        //   needed because when snail trail is loaded slider is in 10 seconds intervals,
        //   and when no snail trail is loaded it is in 10 minute intervals
        if (ignoreSliderValueChange) { 
            this.setState({ ignoreSliderValueChange: false });
            return;
        }

        actions.updateSliderValue(value);

        const {
            sliderChangeTimeout, liveInterval, showLiveAssetsInterval, isMapLive,
        } = this.state;

        if (sliderChangeTimeout) {
            clearTimeout(sliderChangeTimeout);
        }
        if (liveInterval != null) {
            clearInterval(liveInterval);
        }
        if (showLiveAssetsInterval != null) {
            clearInterval(showLiveAssetsInterval);
        }

        const localState = {};
        if (this.googleMapAccess && this.googleMapAccess.current) {
            const { showLive } = this.googleMapAccess.current.state;
            if (showLive) {
                localState.checkedAssets = [];
            }
            this.googleMapAccess.current.setState({ showLive: false });
        }

        localState.liveInterval = null;
        localState.showLiveAssetsInterval = null;
        localState.isMapLive = false;

        this.setState(localState);
    };

    updateMapFromSearchTree = (assets, clearSelectedAsset = false) => {
        const { selectedAsset, selectedAssetDataRefreshInterval, selectedDate } = this.state;
        const { actions } = this.props;

        if (selectedAsset && clearSelectedAsset) {
            if (selectedAssetDataRefreshInterval !== null) {
                clearInterval(selectedAssetDataRefreshInterval);
            }
            history.push('/map');
            actions.setSelectedAsset(null);
            this.setState({
                checkedAssets: assets,
                selectedAsset: null,
                selectedAssetDataRefreshInterval: null,
                selectedDate: moment().format('YYYY-MM-DD'),
                isMapLive: true,
                ignoreSliderValueChange: true,
            }, () => {
                setTimeout(() => {
                    if (this.googleMapAccess && this.googleMapAccess.current) {
                        this.googleMapAccess.current.setState({ showLive: true });
                    }
                }, 300);
                this.getHeatMapEvents();
            });
        } else {
            const newCheckedAssets = difference(assets, this.state.checkedAssets);
            if (newCheckedAssets.length) {
                const newCheckedAssetIds = newCheckedAssets.map(newCheckedAsset => newCheckedAsset.split('-')[2]);
                actions.mapDeviceListRequest({
                    date: selectedDate,
                    assets: newCheckedAssetIds,
                });
                actions.mapDevicesLastLocationRequest({
                    date: selectedDate,
                    assets: newCheckedAssetIds
                });
            }

            this.setState({ checkedAssets: assets }, () => {
                this.getHeatMapEvents();
            });
        }
    };

    assetOnMouseOver = (assetKey) => {
        const parts = assetKey.split('-');
        if (!isEmpty(parts)) {
            if (parts[0] === 'asset') {
                const assetId = parts[2];
                if (!isEmpty(assetId)) {
                    this.setState({ currentHoveredAsset: assetId });
                }
            }
        }
    };

    assetOnMouseOut = () => {
        setMarkerPopoverToLoading(this);
    };

    pathGeneratedCallback = () => {
        if (this.state.selectedAssetIsLoading) {
            this.setState({ selectedAssetIsLoading: false });
        }
    };

    markerOnClick = (marker) => {
        this.setState({ selectedAssetIsLoading: true });

        const values = queryString.parse(this.props.location.search);

        if (this.mapUpdateInterval !== null) {
            clearInterval(this.mapUpdateInterval);
        }

        const {
            actions, userMapCenter, selectedSliderValue,
        } = this.props;

        let formattedDate = null;
        if (marker && marker.date) {
            formattedDate = moment(marker.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
            const timeAsArray = (moment(marker.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')).split(':');
            const sliderValue = (
                (parseInt(timeAsArray[0]) * 3600)
                + (parseInt(timeAsArray[1] || 0) * 60)
                + (parseInt(timeAsArray[2] || 0))
            );
            const selectedTime = moment(marker.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
            this.setState({ selectedTime });
            actions.updateMapDate(formattedDate);
            actions.updateSliderValue(sliderValue);
        }

        const { selectedDate, liveInterval } = this.state;
        const markerImei = marker && marker.imei ? marker.imei : null;

        if (liveInterval !== null) {
            clearInterval(liveInterval);
        }

        const selectedSliderValueAsTime = moment.utc(selectedSliderValue * 1000).format('HH:mm:ss');

        actions.getDeviceTimelineRequest({
            date: marker.dateTime || selectedDate,
            imei: markerImei,
        });
        actions.getDeviceGeofenceTimelineRequest({
            date: marker.dateTime || selectedDate,
            imei: markerImei,
        });

        actions.mapAssetSearchRequest({ date: selectedDate });
        this.setState({
            prevCheckedAssets: this.state.checkedAssets,
        });

        let selectedAssetId = marker?.asset_id;
        if (!selectedAssetId) selectedAssetId = marker?.assetId;
        if (!selectedAssetId) selectedAssetId = marker?.title?.props?.assetId;
        let selectedAssetDivisionId = marker?.division_id;
        if (!selectedAssetDivisionId) selectedAssetDivisionId = marker?.divisionId;
        if (!selectedAssetDivisionId) selectedAssetDivisionId = marker?.title?.props?.deviceDetailedLocationInfo?.division_id;
        let checkedAssets;
        if (selectedAssetId && selectedAssetDivisionId) {
            checkedAssets = [`asset-${selectedAssetDivisionId}-${selectedAssetId}`];
        } else {
            checkedAssets = [];
        }

        const calculateDistance = getDistanceInMiles(userMapCenter, { lat: marker.lat, lng: marker.lng });
        this.setState({
            isMapLive: false,
            selectedAssetLocation: getAddressFromLatLong(marker.lat, marker.lng),
            selectedAsset: markerImei,
            showSidebar: true,
            showDeviceSidebar: false,
            showAssetsSidebar: true,
            assetDistanceFromCentre: calculateDistance,
            assets: markerImei,
            liveInterval: null,
            checkedAssets,
        }, () => {
            actions.mapDeviceLocationsRequest({
                imei: markerImei,
                date: marker.dateTime || `${selectedDate} ${selectedSliderValueAsTime}`,
                record_id: marker.record_id,
            });
            actions.mapDeviceListRequest({
                date: selectedDate,
                imei: markerImei,
                slider_unit_type: 'seconds',
                slider_unit_size: 10,
            });
        });
        actions.setSelectedAsset(markerImei);

        const self = this;

        const selectedAssetDataRefreshInterval = setInterval(() => {
            if (moment(self.state.selectedDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                this.ignoreAutoZoom = true;
                actions.mapDeviceListRequest({
                    date: self.state.selectedDate,
                    imei: markerImei,
                    slider_unit_type: 'seconds',
                    slider_unit_size: 10,
                });

                actions.getDeviceTimelineRequest({
                    date: marker.dateTime || self.state.selectedDate,
                    imei: markerImei,
                });
                actions.getDeviceGeofenceTimelineRequest({
                    date: marker.dateTime || self.state.selectedDate,
                    imei: markerImei,
                });
            }
        }, 60000);

        this.setState({ selectedAssetDataRefreshInterval });
        this.ignoreAutoZoom = true;

        if (this.googleMapAccess && this.googleMapAccess.current) {
            this.googleMapAccess.current.setState({ showLive: false });
        }

        return true;
    };

    pathInfoOnClick = (event) => {
        history.push(`/events/custom-view/${event.id}/${event.device_id}`);
    };

    selectMapAsset = (imei) => {
        const { actions } = this.props;
    };

    toggleGeoFence = () => {
        if (this.googleMapAccess && this.googleMapAccess.current) {
            const toggleShowGeoFence = !this.googleMapAccess.current.state.showGeoFence;
            this.setState({ toggleShowGeoFence });
            this.googleMapAccess.current.showGeoFenceOnMap(toggleShowGeoFence);
        }
    };

    toggleMapGeofence = (e) => {
        const drawGeoFenceToggle = !this.state.drawGeoFence;
        this.setState({ drawGeoFence: drawGeoFenceToggle, singleTrigger: {} });
        const _self = this;
        if (drawGeoFenceToggle) {
            Modal.info({
                title: 'Draw a Geo-fence on the map',
                content: (
                    <div>
                        <p>
                            Click on the map to draw the boundaries of your geo-fence.
                            Click the red pin to complete your geo-fence area.
                        </p>
                        <img className="geofence-example-image" src={GeofenceCreatePng} />
                    </div>
                ),
                okText: 'Start Drawing',
                onOk() {
                    const { drawGeoFence } = _self.state;
                    if (_self.googleMapAccess && _self.googleMapAccess.current) {
                        _self.googleMapAccess.current.setState({ drawGeoFence });
                    }
                },
            });
        }
    };

    toggleInfoBox = () => {
        const { actions } = this.props;
        const { showInfoBox } = this.googleMapAccess.current.state;
        const newShowInfoBox = !showInfoBox;
        this.googleMapAccess.current.setState({ showInfoBox: newShowInfoBox });
        actions.setMapPreferencesRequest({
            show_info: newShowInfoBox ? 1 : 0,
        });
    };

    toggleMapMode = () => {
        this.setState({
            mapMode: this.state.mapMode === MAP_MODE_DOTS
                ? MAP_MODE_LINE
                : MAP_MODE_DOTS,
        });
        this.googleMapAccess.current.refreshMap();
    };

    refreshHeatmap = (checkedKeys) => {
        const eventKeys = checkedKeys.filter((key) => key !== 'all');
        this.setState({ checkedHeatmapKeys: eventKeys }, () => {
            this.getHeatMapEvents();
        });
    };

    getHeatMapEvents = () => {
        const { actions } = this.props;
        const { selectedDate, checkedAssets, checkedHeatmapKeys } = this.state;
        const { heatMapInstance } = this.googleMapAccess.current.state;

        if (checkedHeatmapKeys && checkedHeatmapKeys.length > 0 && checkedAssets && checkedAssets.length > 0) {
            const assets = [];
            const allow = checkedAssets.includes('999');
            checkedAssets.forEach((asset) => {
                if (asset != 999) {
                    const assetData = asset.split('-');
                    if (assetData.length === 3 && assetData[0] === 'asset') {
                        const divisionId = assetData[1];
                        const assetId = assetData[2];
                        assets.push(assetId);
                    }
                }
            });
            if (assets.length > 0 || allow) {
                this.googleMapAccess.current.setState({ showHeatMap: true });
                actions.getMapEventsHeatMapRequest({
                    date: selectedDate,
                    eventTypes: checkedHeatmapKeys,
                    assets,
                });
            }
        } else {
            if (heatMapInstance) {
                heatMapInstance.setMap(null);
            }
            this.googleMapAccess.current.setState({ showHeatMap: false });
            actions.resetHeatMapData();
            actions.updateMapHeatMapRequestFlag(true);
        }
    };

    enableOfflineMode = () => {
        const { showLive } = this.googleMapAccess.current.state;

        if (showLive) this.toggleLiveView(null, false);
    };

    toggleLiveView = () => {
        const { actions } = this.props;
        const { showSidebar, checkedAssets } = this.state;
        history.push('/map');
        const localState = {};
        if (showSidebar) {
            localState.showDeviceSidebar = false;
            localState.showAssetsSidebar = true;
        }
        actions.getUserDivisionsRequest();
        if (this.googleMapAccess && this.googleMapAccess.current) {
            const { showLive, mapAssetDate } = this.googleMapAccess.current.state;
            const { liveInterval, showLiveAssetsInterval, selectedDate } = this.state;
            this.googleMapAccess.current.setState({ showLive: !showLive });
            let tempLiveInterval = null;
            let tempShowLiveAssetsInterval = null;
            const tempShowLive = !showLive;
            if (liveInterval !== null) {
                clearInterval(liveInterval);
            }
            if (showLiveAssetsInterval !== null) {
                clearInterval(showLiveAssetsInterval);
            }

            if (tempShowLive) {
                actions.mapGetLiveAssetsLocationRequest({});
                tempLiveInterval = setInterval(() => {
                    actions.mapGetLiveAssetsLocationRequest({});
                }, 20000);
                tempShowLiveAssetsInterval = setInterval(() => {
                    this.getMapLiveAssets(selectedDate);
                }, 1000);
                this.setState({
                    selectedDate: moment().format('YYYY-MM-DD'),
                });
                setTimeout(() => {
                    if (this.googleMapAccess && this.googleMapAccess.current) {
                        // this command is breaking the geofence tool on the map, I have tesetd without this command and live map works perfect
                        this.googleMapAccess.current.executeAutoZoom();
                    }
                }, 1500);
            } else if (!this.props.mapDeviceListIsFetching) {
                actions.updateMapDate(moment().format('YYYY-MM-DD'));
                const sliderValue = Math.floor((parseInt(moment().format('HH')) * 3600) + (parseInt(moment().format('mm')) * 60) + parseInt(moment().format('ss')));

                if (selectedDate !== moment().format('YYYY-MM-DD') || mapAssetDate !== moment().format('DD/MM/YYYY')) {
                    this.googleMapAccess.current.setState({
                        mapAssetDate: moment().format('DD-MM-YYYY'),
                    });
                }

                this.googleMapAccess.current.setState({
                    sliderValue,
                    sliderMaxValue: sliderValue,
                });
                setTimeout(() => {
                    if (this.googleMapAccess && this.googleMapAccess.current) {
                        // this command is breaking the geofence tool on the map, I have tesetd without this command and live map works perfect
                        this.googleMapAccess.current.executeAutoZoom();
                    }
                }, 1500);
            } else if (this.googleMapAccess && this.googleMapAccess.current) {
                // this command is breaking the geofence tool on the map, I have tesetd without this command and live map works perfect
                this.googleMapAccess.current.executeAutoZoom();
            }

            if (!tempShowLive) {
                localState.showAssetsSidebar = true;
                localState.showSidebar = true;
                localState.checkedAssets = this.state.prevCheckedAssets;
                localState.prevCheckedAssets = [];
            } else if (!checkedAssets || checkedAssets.length == 0) {
                localState.checkedAssets = ['999'];
            }

            localState.isMapLive = tempShowLive;
            localState.liveInterval = tempLiveInterval;
            localState.showLiveAssetsInterval = tempShowLiveAssetsInterval;
            localState.mapLiveAssets = [];
            localState.selectedAsset = null;
            this.setState(localState);
            actions.setSelectedAsset(null);
        }
    };

    onMapChange = ({ zoom }) => {
        this.setState({ zoom });
    };

    redrawGeofence = () => {
        this.setState({ clearGeofence: true, showEditTrigger: false, singleTrigger: {} });
        if (this.googleMapAccess && this.googleMapAccess.current) {
            setTimeout(() => {
                const googleMap = this.googleMapAccess.current;
                const { geoFencePolygon, polyLineMarkers } = googleMap.state;
                if (geoFencePolygon && geoFencePolygon.length > 0) {
                    geoFencePolygon.forEach((polygon) => polygon.setMap(null));
                }
                let tempPolyLineMarkers = polyLineMarkers;
                if (googleMap.state.mapPolyLines) {
                    googleMap.state.mapPolyLines.setMap(null);
                    tempPolyLineMarkers.forEach((tempPolyLineMarker) => {
                        tempPolyLineMarker.setMap(null);
                    });
                    tempPolyLineMarkers = [];
                    const newlyCreatedPolygon = geoFencePolygon.pop();
                    if (newlyCreatedPolygon) {
                        newlyCreatedPolygon.setMap(null);
                    }
                }
                googleMap.setState({
                    mapPolyLines: null,
                    geoFencePolyLines: [],
                    drawGeoFence: true,
                    geoFenceCords: [],
                    polyLineMarkers: tempPolyLineMarkers,
                    geoFencePolygon,
                });
            }, 300);
        }
    };

    cancelGeoFence = (redraw = true) => {
        this.setState({ showEditTrigger: false, newGeoFencePath: [], drawGeoFence: false });
        if (this.googleMapAccess && this.googleMapAccess.current) {
            const googleMap = this.googleMapAccess.current;
            const { geoFencePolygon, polyLineMarkers } = googleMap.state;
            let tempPolyLineMarkers = polyLineMarkers;
            if (googleMap.state.mapPolyLines) {
                googleMap.state.mapPolyLines.setMap(null);
                tempPolyLineMarkers.forEach((tempPolyLineMarker) => {
                    tempPolyLineMarker.setMap(null);
                });
                tempPolyLineMarkers = [];
                const newlyCreatedPolygon = geoFencePolygon.pop();
                if (newlyCreatedPolygon) {
                    newlyCreatedPolygon.setMap(null);
                }
            }

            googleMap.setState({
                mapPolyLines: null,
                geoFencePolyLines: [],
                geoFenceCords: [],
                geoFencePolygon,
                polyLineMarkers: tempPolyLineMarkers,
                drawGeoFence: false,
            });
            if (redraw) {
                setTimeout(() => {
                    googleMap.refreshMap();
                }, 3000);
            }
        }
    };

    deleteGeofence = (singleTrigger) => {
        const { confirm } = Modal;
        const { actions } = this.props;
        if (singleTrigger && singleTrigger.id) {
            const previousView = this;

            confirm({
                title: 'Do you Want to delete this geofence?',
                content: `${singleTrigger.name}`,
                onOk() {
                    actions.deleteGeofenceRequest({ id: singleTrigger.id });
                    previousView.setState({ showEditTrigger: false });
                    previousView.toggleGeoFence();
                    setTimeout(() => {
                        previousView.toggleGeoFence();
                    }, 3000);
                },
            });
        }
    };

    geFenceCompleted = (status, points) => {
        if (status == 1) {
            const url = '/reports/geofence-report';
            if (points?.length > 0) {
                const params = {};
                params.geoPoints = points.map((point) => point);
                if (this.state.selectedDate) {
                    params.dateRange = [moment(this.state.selectedDate).startOf('day'), moment(this.state.selectedDate).endOf('day')];
                }
                this.props.actions.setDataEventToReportParams(params);
            }
            history.push(url);
        }
    };

    navigateToExistingGeofence(id) {
        const { allTriggers } = this.props;
        if (id) {
            const triggerIndex = findIndex(allTriggers, (val) => val.id === id);
            if (triggerIndex !== -1) {
                const url = '/reports/geofence-report';
                const params = { existingGeofenceId: id };
                if (this.state.selectedDate) {
                    params.dateRange = [moment(this.state.selectedDate).startOf('day'), moment(this.state.selectedDate).endOf('day')];
                }
                this.props.actions.setDataEventToReportParams(params);
                history.push(url);
            }
        }
    }

    polygonClick = (e, polygon, id) => {
        this.navigateToExistingGeofence(id);
    };

    createTrigger = (values) => {
        const { actions } = this.props;
        const { newGeoFencePath, singleTrigger } = this.state;

        if (isEmpty(singleTrigger)) {
            actions.createTriggerRequest({
                geoFenceCords: newGeoFencePath,
                trigger_name: values.trigger_name,
                selection: values.selection,
                divisions: values.divisions,
            });
        } else {
            actions.updateDeviceTriggerRequest({
                id: singleTrigger.id,
                trigger_name: values.trigger_name,
                selection: values.selection,
                divisions: values.divisions,
            });
        }

        this.toggleGeoFence();
        this.cancelGeoFence(false);

        setTimeout(() => {
            this.toggleGeoFence();
        }, 3000);
    };

    getDivisions = () => {
        const { divisions, actions } = this.props;
        if (divisions.length === 0) {
            actions.getUserDivisionsRequest();
        }
    };

    getMapLiveAssets = (selectedDate) => {
        const {
            mapLiveAssetsLocation,
            user,
            mapDevicesLastLocation,
            actions,
            timeSheetReport,
            isExportingCsv,
            deviceDetailedLocationInfo,
        } = this.props;

        const { isMapLive, currentHoveredAsset } = this.state;

        if (isMapLive === false) {
            return [];
        }

        const assets = [];
        each(mapLiveAssetsLocation, (location) => {
            const marker = (location.markers && location.markers[location.markers.length]) || location.markers[0]; // filter(location.markers);
            if (marker) {
                let assetClass = '';
                if (parseInt(location.online, 10) === 1 || parseInt(location.device_status, 10) === 1) {
                    assetClass = 'asset-marker-online';
                } else {
                    assetClass = 'asset-marker-offline';
                }
                assets.push({
                    showArrowIcon: true,
                    angle: marker.angle,
                    className: assetClass,
                    title: (
                        <MapAssetPopupHead
                            heading={location.title}
                            registration={location.registration}
                            showQuickActionButtons
                            assetId={location.asset_id}
                            user={user}
                            imei={location.imei}
                            selectedDate={selectedDate}
                            onEventSearch={(params) => {
                                actions.updateEventSearch(params);
                            }}
                            onUpdateVideoSearch={(params) => {
                                actions.updateVideoSearch(params);
                            }}
                            onGetCsvAssetRecordsByDayRequest={(params) => {
                                actions.getCsvAssetRecordsByDayRequest(params);
                            }}
                            timeSheetReport={timeSheetReport}
                            isExportingCsv={isExportingCsv}
                            deviceDetailedLocationInfo={deviceDetailedLocationInfo}
                            lat={marker?.lat}
                            lng={marker?.lng} />
                    ),
                    content: (
                        <MapAssetPopupContent
                            showLoading />
                    ),
                    active: location.active,
                    imei: location.imei,
                    registration: location.registration,
                    time: location.time,
                    asset_id: location.asset_id,
                    device_status: location.device_status,
                    name: location.title,
                    division: location.division,
                    division_id: location.division_id,
                    division_color: location.division_color,
                    lat: parseFloat(marker.lat),
                    lng: parseFloat(marker.lng),
                    record_id: marker.record_id,
                    visible: false,
                    is_idle: marker.is_idle,
                    onClick: () => {
                        marker.imei = location.imei;
                        marker.assetId = location.asset_id;
                        marker.divisionId = location.division_id;
                        this.markerOnClick(marker);
                    },
                });
            }
        });

        // adding assets last location
        mapDevicesLastLocation.forEach((lastLocation) => {
            const { markers } = lastLocation;
            const markerKeys = Object.keys(markers);
            if (markerKeys.length > 0) {
                let marker;
                const keys = Object.keys(markers);
                for (let i = 0; i < keys.length; i += 1) {
                    const key = keys[i];
                    if (markers[key].record_id) {
                        marker = markers[key];
                        break;
                    }
                }

                const assetClass = 'asset-marker-last-location';
                if (marker.lat != null && marker.lng != null && marker.angle != null && marker.record_id) {
                    const newMarker = {
                        showArrowIcon: true,
                        angle: marker.angle,
                        className: assetClass,
                        title: (
                            <MapAssetPopupHead
                                heading={lastLocation.title}
                                registration={lastLocation.registration}
                                showQuickActionButtons
                                assetId={lastLocation.asset_id}
                                user={user}
                                imei={lastLocation.imei}
                                selectedDate={selectedDate}
                                onEventSearch={(params) => {
                                    actions.updateEventSearch(params);
                                }}
                                onUpdateVideoSearch={(params) => {
                                    actions.updateVideoSearch(params);
                                }}
                                onGetCsvAssetRecordsByDayRequest={(params) => {
                                    actions.getCsvAssetRecordsByDayRequest(params);
                                }}
                                timeSheetReport={timeSheetReport}
                                isExportingCsv={isExportingCsv}
                                deviceDetailedLocationInfo={deviceDetailedLocationInfo}
                                lat={marker?.lat}
                                lng={marker?.lng} />
                        ),
                        content: (
                            <MapAssetPopupContent
                                showLoading />
                        ),
                        // active: lastLocation.active,
                        imei: lastLocation.imei,
                        registration: lastLocation.registration,
                        time: lastLocation.time,
                        asset_id: lastLocation.asset_id,
                        device_status: lastLocation.device_status,
                        name: lastLocation.title,
                        division: lastLocation.division,
                        division_id: lastLocation.division_id,
                        division_color: lastLocation.division_color,
                        lat: parseFloat(marker.lat),
                        lng: parseFloat(marker.lng),
                        record_id: marker.record_id,
                        visible: false,
                        is_idle: marker.is_idle,
                        onClick: () => {
                            marker.imei = lastLocation.imei;
                            this.markerOnClick(marker);
                        },
                    };
                    const existingIndex = assets.findIndex((marker) => marker.asset_id == newMarker.asset_id);
                    if (existingIndex !== -1) {
                        assets[existingIndex] = newMarker;
                    } else {
                        assets.push(newMarker);
                    }
                }
            }
        });
        this.setState({ mapLiveAssets: assets });
    };

    getMappedDevicePath = () => {
        const { selectedAsset, isMapLive } = this.state;
        const {
            mapSelectedDeviceLocations, mapSelectedDeviceInfo, selectedSliderValue, user, deviceDetailedLocationInfo, company,
        } = this.props;

        if (selectedAsset && !isMapLive) {
            const assetMarkerPlaced = false;
            return mapSelectedDeviceLocations.map((path, pathIndex) => {
                let position = 'path';
                const assetMarker = (selectedSliderValue === parseInt(path.time_interval, 10));

                let subTitle = path.event_key;
                const iconStyle = {};
                iconStyle.fontSize = '16px';
                iconStyle.color = '#CF2B28';
                let EventICon = <FlagFilled style={iconStyle} />;
                if (path.event_type == 253) {
                    if (path.green_driving_type == 0) {
                        subTitle = 'Eco-friendly driving';
                    } else if (path.green_driving_type == 1) {
                        subTitle = 'Harsh accelerating';
                    } else if (path.green_driving_type == 2) {
                        subTitle = 'Harsh braking';
                    } else if (path.green_driving_type == 3) {
                        subTitle = 'Harsh cornering';
                    }
                }
                if (path.icon && path.icon !== '') {
                    EventICon = <Tooltip><AlertFilled style={iconStyle} /></Tooltip>;
                    switch (path.icon) {
                    case 'key':
                        EventICon = <Tooltip><KeyOutlined /></Tooltip>;
                        break;
                    case 'eye':
                        EventICon = <Tooltip><EyeFilled /></Tooltip>;
                        break;
                    case 'api':
                        EventICon = <Tooltip><ApiFilled /></Tooltip>;
                        break;
                    case 'thunderbolt':
                        EventICon = <Tooltip><BellFilled /></Tooltip>;
                        break;
                    default:
                        EventICon = <Tooltip><LegacyIcon type={path.icon} theme="filled" /></Tooltip>;
                    }
                }

                let { speed } = path;
                let unit = 'kph';
                if (user && user.profile && user.profile.unit_of_measurement_preference === 'british-imperial') {
                    unit = 'mph';
                    speed = Math.floor(speed * 0.62);
                }

                let videoFunc = null;
                if (!isNull(path.event_id)) {
                    videoFunc = () => { goToEventPage(path.event_id, mapSelectedDeviceInfo.device_id); };
                }

                let title = (
                    <MapAssetPopupHead
                        speed={speed}
                        heading={subTitle}
                        registration={mapSelectedDeviceInfo.registration} />
                );

                let content = (
                    <MapAssetPopupContent
                        showLoading={false}
                        showPicture
                        assetPicture={mapSelectedDeviceInfo.asset_picture}
                        userPicture={mapSelectedDeviceInfo.profile_picture}
                        event_key={path.event_key}
                        videoFunc={videoFunc}
                        online={path.online}
                        online_status={path.online_status}
                        dateTime={path.time}
                        driver={mapSelectedDeviceInfo.full_name}
                        speed={speed}
                        speedUnit={unit}
                        streetViewIconClicked={openStreetView}
                        googleMapAccessRef={this.googleMapAccess}
                        lat={path.lat}
                        lng={path.lng}
                        location_data={path.location_data}
                        user={user}
                        angle={path.angle}
                        showTimeToDestinationBtn={company && company.features && company.features.find((feature) => feature.id === 'time-to-destination')} />
                );
                if (pathIndex === 0) {
                    position = 'end';
                    title = path.time;
                    content = <div>path.time</div>;
                } else if (pathIndex === (mapSelectedDeviceLocations.length - 1)) {
                    position = 'start';
                    title = path.time;
                    content = <div>path.time</div>;
                } else if (assetMarker && !assetMarkerPlaced) {
                    position = 'marker';
                } else if (path.event_id && path.event_id != null) {
                    position = 'event';
                }

                return {
                    id: path.event_id || path.id,
                    record_id: path.id,
                    // event_id: ,
                    device_id: mapSelectedDeviceInfo.device_id,
                    lat: path.lat,
                    lng: path.lng,
                    icon: EventICon,
                    severity: path.severity,
                    position,
                    title,
                    content,
                    angle: path.angle,
                    seconds: path.all_seconds,
                    onClick: false,
                };
            });
        }
        return null;
    };

    getMappedDevicePathAsset = (assetsModified) => {
        const { selectedAsset, selectedAssetRecordId } = this.state;
        const { selectedSliderValue, mapSelectedDeviceLocations, mapLiveAssetsLocation } = this.props;
        const assets = assetsModified.filter((asset) => asset.imei == selectedAsset);
        if (assets[0]) {
            // assets[0].markers = [];
            // assets[0].markers = mapSelectedDeviceLocations;
            assets[0].markers.forEach((marker, markerIndex) => {
                if (selectedSliderValue === parseInt(marker.time_interval, 10)) {
                    assets[0].className = '';
                }
            });
            assets[0].onClick = false;
        }
        return assets;
    };

    getMapSearchBoundary = (polygon) => {
        const { actions, mapDevices } = this.props;
        const {
            selectedDate, isMapLive, mapLiveAssets, selectedAsset,
        } = this.state;
        let mapSliderValue = 0;
        const devices = [];
        if (this.googleMapAccess && this.googleMapAccess.current) {
            const { maps, sliderValue } = this.googleMapAccess.current.state;
            mapSliderValue = sliderValue;
            const updatedPolygon = polygon.map((point) => [point.lat, point.lng]);
            if (isMapLive) {
                // mapLiveAssets
                mapLiveAssets.forEach((mapLiveAsset) => {
                    if (insidePolygon([mapLiveAsset.lat, mapLiveAsset.lng], updatedPolygon) !== false) {
                        devices.push(mapLiveAsset.asset_id);
                    }
                });
                const currentTime = timeToSeconds(moment().format('HH:mm:ss'));
                // This is to adjust time with the slider
                mapSliderValue = (currentTime / (selectedAsset ? 3600 : 60)) / (selectedAsset ? 10 : MAP_SLIDER_INTERVAL);
            } else {
                // mapDevices
                mapDevices.forEach((mapDevice) => {
                    let found = false;
                    Object.keys(mapDevice.markers).forEach((key) => {
                        if (insidePolygon([mapDevice.markers[key].lat, mapDevice.markers[key].lng], updatedPolygon) !== false) {
                            found = true;
                        }
                    });
                    if (found) {
                        devices.push(mapDevice.asset_id);
                    }
                });
                mapSliderValue = Math.min(143, sliderValue);
            }
        }
        actions.setMapSearchLocationBoundary({
            polygon,
            devices,
            selectedDate,
            mapSliderValue,
        });
        if (polygon.length > 0) {
            history.push('/video/video-search/location');
        }
    };

    render() {
        return this.view();
    }
}

Map.propTypes = {
    actions: PropTypes.object.isRequired,
    checkedAssets: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        mapDevices: state.device.mapDevices,
        checkedAssets: state.device.checkedAssets,
        mapDevicesLastLocation: state.device.mapDevicesLastLocation,
        mapSelectedDeviceLocations: state.device.mapSelectedDeviceLocations,
        mapSelectedDeviceRecord: state.device.mapSelectedDeviceRecord,
        isFetching: state.device.isFetching,
        isFetchingDeviceLocation: state.device.isFetchingDeviceLocation,
        mapDeviceListIsFetching: state.device.mapDeviceListIsFetching,
        mapSelectedDeviceInfo: state.device.mapSelectedDeviceInfo,
        mapSelectedDeviceTelematicsData: state.device.mapSelectedDeviceTelematicsData,
        mapAssets: state.device.mapAssets,
        mapAssetsPagination: state.device.mapAssetsPagination,
        assetIsFetching: state.asset.isFetching,
        eventTypes: state.asset.event_types,
        userMapCenter: state.global.userMapCenter,
        mapDeviceHeatMap: state.device.mapDeviceHeatMap,
        mapHeatMapDataUpdated: state.device.mapHeatMapDataUpdated,
        divisions: state.events.divisions,
        allTriggers: state.device.allTriggers,
        selectedSliderValue: state.global.selectedSliderValue,
        mapDate: state.global.mapDate,
        mapLiveAssetsLocation: state.device.mapLiveAssetsLocation,
        user: state.user,
        mapDeviceLocationRequest: state.device.mapDeviceLocationRequest,
        selectedAssets: state.asset.selectedAssets,
        mapDeviceHeatMapRequest: state.device.mapDeviceHeatMapRequest,
        mapDevicesLoaded: state.device.mapDevicesLoaded,
        mapDevicesFetchTime: state.device.mapDevicesFetchTime,
        deviceDetailedLocationInfo: state.device.deviceDetailedLocationInfo,
        deviceDetailedLocationFetching: state.device.deviceDetailedLocationFetching,
        previousMapStatusLive: state.global.previousMapStatusLive,
        shardId: state.global.shardId,
        selectedDeviceTimeline: state.device.deviceTimeline,
        selectedDeviceGeofenceTimeline: state.device.deviceGeofenceTimeline,
        timeSheetReport: state.asset.timeSheetReport,
        isExportingCsv: state.asset.isExportingCsv,
        company: state.user.userCompany,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...deviceActions,
                ...assetActions,
                ...globalActions,
                ...eventsActions,
                ...userActions,
                ...videoActions,
                ...reportActions,
            },
            dispatch,
        ),
    };
}

export const MapTest = Map;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Map);
