import React, { Component } from 'react';
import {
    Select,
    Input,
    Button,
    Radio,
} from 'antd';
import { Form } from '@ant-design/compatible';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Icon from '../../../components/elements/Icon';
import * as eventsActions from '../../../core/events/eventsActions';
import * as assetActions from '../../../core/asset/assetActions';

class TriggersFilterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            division_or_asset: 'divisions'
        }
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getUserDivisionsRequest();
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    render() {
        const { form, divisions, assetOptions } = this.props;
        const { division_or_asset } = this.state;
        const { getFieldDecorator } = form;
        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <Form.Item label="Geo-fence name">
                    {getFieldDecorator('geofence_name', {

                    })(
                        <Input placeholder="Geo-fence name" />,
                    )}
                </Form.Item>

                <Form.Item label="Report on Divisions or Assets?">
                    {getFieldDecorator('division_or_asset', {
                        initialValue: 'divisions',
                    })(
                        <Radio.Group onChange={(e) => this.setState({ division_or_asset: e.target.value })}>
                            <Radio value="divisions">Divisions</Radio><br />
                            <Radio value="assets">Assets</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
                {division_or_asset === 'divisions' ? (
                    <Form.Item label="Divisions">
                        {getFieldDecorator('geofence_division', {})(
                        <Select
                                placeholder="Select division"
                            suffixIcon={<Icon name="triangle-down" />}>
                                <Select.Option value={''}>All</Select.Option>
                                {divisions.map((division, divisionIndex) => <Select.Option key={divisionIndex} value={division.id}>{division.name}</Select.Option>)}
                            </Select>
                    )}
                </Form.Item>
                ) : null}
                {division_or_asset === 'assets' ? (
                    <Form.Item label="Asset name or reg">
                        {getFieldDecorator('geofence_assets', {})(
                        <Select
                                showSearch
                                mode="multiple"
                                allowClear
                                filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                                placeholder="Asset name or reg"
                            suffixIcon={<Icon name="triangle-down" />}>
                                {assetOptions.map((a) => (
                                    <Select.Option key={a.id} value={a.id}>
                                        {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                    </Select.Option>
                                ))}
                            </Select>
                    )}
                </Form.Item>
                ) : null}


                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                        className="btn extrawide"
                        size="large">
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

const TriggersFilter = Form.create({ name: 'event_filter_form' })(TriggersFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        divisions: state.events.divisions,
        assetOptions: state.asset.options,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TriggersFilter);
