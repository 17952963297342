import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';

import { message } from 'antd';
import * as userActions from '../../core/user/userActions';
import DOM from './resetPassswordForm';

class UserResetPassswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalForgetVisible: false,
            isSucessResetPassword: false,
            isValidResetPassword: false,
        };
        this.view = DOM;
    }

    componentWillUnmount() {
        this.setState({
            isSucessResetPassword: false,
            isValidResetPassword: false,
        });
    }

    componentDidMount() {
        this.setState({
            isValidResetPassword: this.props.isValidResetPassword,
        });
    }

    setModalForgetVisible(modalForgetVisible) {
        this.setState({ modalForgetVisible });
    }

    handleSubmit = (e) => {
        const { token, user } = this.props;
        const { isSucessResetPassword } = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions, form } = this.props;
                let error = false;

                if (values.new_password !== values.new_password_confirm) {
                    error = true;
                    message.error('New password does not match!');
                }

                if (!error) {
                    actions.changeResetPasswordRequest({ ...values, token });
                    this.setState({
                        isSucessResetPassword: user.isSucessResetPassword,
                    });
                    form.resetFields();
                }
            }
        });
    };

    handleForgotsubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(['email'], (err, values) => {
            if (!err) {
                const { actions } = this.props;
                actions.resetPasswordRequest({ email: values.email });
                this.setState({ modalForgetVisible: false });
            }
        });
    };

    render() {
        return this.view();
    }
}

UserResetPassswordForm.propTypes = {
    form: PropTypes.object.isRequired,
};

const ResetPassswordForm = Form.create()(UserResetPassswordForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.user.isFetching,
        isSucessResetPassword: state.user.isSucessResetPassword,
        isValidResetPassword: state.user.isValidResetPassword,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResetPassswordForm);
