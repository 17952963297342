import React from 'react';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Input, Button, Spin } from 'antd';

import srcFleetclearLogo from '../../assets/images/fleetclear-logo.svg';
import './twoFactorAuthForm.css';
import history from '../../core/utils/history';
import Icon from '../elements/Icon';

const FormItem = Form.Item;

export default function () {
    const { form, isFetching } = this.props;
    const { getFieldDecorator } = form;
    return (
        <Spin spinning={isFetching}>
            <div className="twoFactorAuth-wrap">
                <div className="twoFactorAuth-container">
                    <div className="d-flex dir-column align-center text-center">
                        <img src={srcFleetclearLogo} className="login__logo-fleet" alt="Fleetclear" />
                        <h1 className="login__heading">Two-Factor Authentication</h1>
                        <p>You should have received an email with the verification code</p>
                    </div>
                    <Form onSubmit={this.handleSubmit} layout="vertical">
                        <FormItem>
                            {getFieldDecorator('verification_code', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your verification code!',
                                    },
                                ],
                            })(<Input placeholder="verification code" />)}
                        </FormItem>
                        <Button htmlType="submit" className="btn btn-bordered" style={{ marginTop: '10px' }}>
                            Verify
                        </Button>
                        <div style={{ marginTop: '10px' }}>
                            <Button className="btn btn-empty btn-link" onClick={() => history.push('/map')}>
                                Return to login
                            </Button>
                        </div>
                    </Form>
                    <div className="d-flex dir-column align-center text-center">
                        <h3 className="login__heading">New to Fleetclear?</h3>
                        <a href="https://fleetclear.com/contact-us/">
                            <span className="login__contact-us">
                                <span className="login__contact-us-icon">
                                    <Icon name="envelope" />
                                </span>
                                <span>Contact us</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </Spin>

    );
}
