/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import {
    Tabs,
    Spin,
} from 'antd';

import AdvancedTab from './Tabs/AdvancedTab';
import PresetsTab from './Tabs/PresetsTab';
import Layout from '../layout/Layout';

const { TabPane } = Tabs;


export default function () {
    const { currentTabKey, editWidgetData } = this.state;

    return (
        <Layout
            data-test="pages-dashboard"
            className="assets smart-widget"
            title={<div>Insert new widget</div>}
            headerTitle={<div>Insert new widget</div>}
            showFooter={false}>
            <div className="content-wrap">
                <div className="content">
                    <div className="content-inner">
                        <Spin spinning={false} size="large" style={{ minHeight: 300, height: '100%' }}>
                            <section className="section">
                                <div className="section__body">
                                    <h2 className="smart-widget-page-title">Insert New Widget</h2>
                                    <Tabs
                                        onTabClick={(key) => this.clickTab(key)}
                                        defaultActiveKey="1"
                                        activeKey={currentTabKey}
                                        animated={false}
                                        className="tabs-restyled2"
                                        centered
                                    >
                                        <TabPane tab="Presets" key="1">
                                            <PresetsTab onTabSwitch={(tabKey) => this.setState({ currentTabKey: tabKey })}/>
                                        </TabPane>
                                        <TabPane tab="Advanced" key="2">
                                            <AdvancedTab editWidgetData={editWidgetData} />
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </section>
                        </Spin>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
