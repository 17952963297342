/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty, each } from 'underscore';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DOM from './dashboard';
import * as smartWidgetActions from '../../core/smartWidget/smartWidgetActions';
import * as divisionActions from '../../core/division/divisionActions';
import * as driverActions from '../../core/driver/driverActions';
import * as assetActions from '../../core/asset/assetActions';

dayjs.extend(customParseFormat);

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            date: moment().format('YYYY-MM-DD'),
        };
        this.loaded = false;
    }

    UNSAFE_componentWillMount() {
        this.loaded = false;
        const { actions } = this.props;
        actions.getDivisionOptionsStrictRequest();
        actions.getUserDivisionsRequest();
        actions.getAssetOptionsStrictRequest();
        actions.getDriverListStrictRequest();
    }

    componentDidMount() {
        const {
            actions, driver, asset,
        } = this.props;

        actions.getSmartWidgetsRequest();

        if (isEmpty(driver.driverList)) {
            actions.getDriverListRequest();
        }

        if (isEmpty(asset.options)) {
            actions.getAssetOptionsRequest();
        }

        this.loaded = true;
    }

    changeDate(date) {
        const { actions, smartWidget } = this.props;
        const newDate = date.format('YYYY-MM-DD');
        this.setState({ date: newDate });

        actions.getSmartWidgetsRequest({ date: newDate });

        // reloading smart widgets
        each(smartWidget.smartWidgets, (widget) => {
            if (widget.chart_type_id === 14) {
                actions.getBestDriverChartDataRequest({
                    id: widget.id,
                });
            } else {
                actions.getSmartWidgetDataRequest({
                    id: widget.id,
                    date: newDate,
                    timeframe: widget.timeframe,
                });
            }
        });
    }

    saveGridLayoutPositions(positions) {
        const { actions, smartWidget } = this.props;

        if (this.loaded && !smartWidget.isFetching) {
            actions.updateSmartWidgetPositionsRequest({ positions });
        }
    }

    render() {
        return this.view();
    }
}

Dashboard.defaultProps = {

};

Dashboard.propTypes = {
    actions: PropTypes.object.isRequired,
    smartWidget: PropTypes.object.isRequired,
    driver: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        smartWidget: state.smartWidget,
        driver: state.driver,
        asset: state.asset,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...smartWidgetActions,
                ...divisionActions,
                ...driverActions,
                ...assetActions,
            },
            dispatch,
        ),
    };
}
export const DashboardTest = Dashboard;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard);
