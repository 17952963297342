import React from 'react';

function MileageSVG() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 396.9 396.9"
            width="1em"
            height="1em"
            xmlSpace="preserve">
            <g>
                <g>
                    <path d="M198.4,11.9C95.5,11.9,11.9,95.5,11.9,198.4S95.5,385,198.4,385S385,301.3,385,198.4S301.3,11.9,198.4,11.9z
			 M198.4,371.7c-95.5,0-173.2-77.7-173.2-173.2S102.9,25.2,198.4,25.2s173.2,77.7,173.2,173.2S294,371.7,198.4,371.7z" />
                    <path d="M323.7,297.7c-30.6,38.5-76.2,60.6-125.3,60.6c-49.6,0-95.5-22.5-126.2-61.8c-2.3-2.9-1.7-7.1,1.2-9.3
			c2.9-2.3,7.1-1.8,9.3,1.1c28.1,36,70.2,56.6,115.7,56.6c44.9,0,86.8-20.3,114.8-55.6c2.3-2.9,6.5-3.4,9.4-1.1
			C325.5,290.6,326,294.8,323.7,297.7z" />
                    <path d="M358.3,198.4c0,19.6-2.2,33.4-7.8,49.4h-0.1c0,0.1,0,0.1,0,0.1c-0.1,0.4-0.4,0.7-0.5,1
			c-0.2,0.3-0.3,0.5-0.5,0.8c-0.3,0.3-0.6,0.6-0.9,0.9c-0.2,0.2-0.4,0.4-0.6,0.5c-0.4,0.3-0.8,0.5-1.2,0.7c-0.2,0.1-0.4,0.2-0.6,0.3
			c-0.7,0.2-1.3,0.3-2.1,0.3c-0.7,0-1.3-0.1-2.1-0.3l-27.2-8.9c-3.5-1.1-5.4-4.9-4.3-8.4c1.1-3.5,4.9-5.4,8.4-4.3l20.9,6.8
			c3.1-10.3,4.7-20.1,5-32.3h-21.8c-3.7,0-6.7-3-6.7-6.7c0-3.7,3-6.7,6.7-6.7h21.7c-0.5-11.1-2.2-21.9-5.1-32.2l-20.7,6.7
			c-0.7,0.2-1.4,0.3-2.1,0.3c-2.8,0-5.4-1.8-6.3-4.6c-1.1-3.5,0.8-7.3,4.3-8.4l20.7-6.7c-3.9-10.3-8.9-20.1-14.9-29.1l-17.6,12.7
			c-1.2,0.9-2.5,1.3-3.9,1.3c-2.1,0-4.1-0.9-5.4-2.7c-2.2-2.9-1.5-7.1,1.5-9.3l17.5-12.7c-6.8-8.5-14.5-16.3-23.1-23.1l-12.7,17.5
			c-1.3,1.8-3.4,2.8-5.4,2.8c-1.4,0-2.7-0.5-3.9-1.3c-3-2.1-3.7-6.3-1.5-9.3L279,76.1c-9-6-18.7-11-29.1-14.9L243.3,82
			c-0.9,2.8-3.5,4.6-6.3,4.6c-0.7,0-1.4-0.1-2.1-0.3c-3.5-1.1-5.4-4.9-4.3-8.4l6.7-20.7c-10.3-2.9-21.1-4.6-32.2-5.1v21.7
			c0,3.7-3,6.7-6.7,6.7c-3.7,0-6.7-3-6.7-6.7V52c-11.1,0.5-21.9,2.2-32.2,5.1l6.7,20.7c1.1,3.5-0.8,7.3-4.3,8.4
			c-0.7,0.2-1.4,0.3-2.1,0.3c-2.8,0-5.5-1.8-6.3-4.6l-6.7-20.7c-10.3,3.9-20.1,8.9-29.1,14.9l12.7,17.6c2.2,2.9,1.5,7.1-1.5,9.3
			c-1.2,0.9-2.5,1.3-3.9,1.3c-2,0-4.1-1-5.4-2.8L107,84C98.5,90.8,90.8,98.5,84,107l17.5,12.7c3,2.2,3.7,6.4,1.5,9.3
			c-1.3,1.8-3.3,2.7-5.4,2.7c-1.3,0-2.7-0.4-3.9-1.3l-17.6-12.7c-6,9-11,18.7-14.9,29.1l20.7,6.7c3.5,1.1,5.4,4.9,4.3,8.4
			c-0.9,2.8-3.5,4.6-6.3,4.6c-0.7,0-1.4-0.1-2.1-0.3l-20.7-6.7c-2.9,10.3-4.6,21.1-5.1,32.2h21.7c3.7,0,6.7,3,6.7,6.7
			c0,3.7-3,6.7-6.7,6.7H52c0.3,12.2,1.9,22.1,5,32.3l20.9-6.8c3.5-1.1,7.3,0.8,8.4,4.3c1.1,3.5-0.8,7.3-4.3,8.4l-27.3,8.9
			c-0.7,0.2-1.3,0.3-2,0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.5-0.1-0.7-0.1c-0.5-0.1-1.1-0.2-1.6-0.5c-0.3-0.1-0.5-0.2-0.7-0.3
			c-0.5-0.3-0.9-0.6-1.3-0.9c-0.2-0.2-0.4-0.3-0.6-0.6c-0.4-0.5-0.7-1.1-1-1.7c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.1,0-0.1h-0.1
			v-0.1c-2.8-7.9-4.7-15.4-6-23.3c-1.3-7.9-1.8-16.3-1.8-26.1c0-17.2,2.7-33.8,7.8-49.3c0-0.1,0-0.1,0-0.1c0.1,0,0.1-0.1,0.1-0.1
			c5.2-15.9,12.9-30.7,22.5-44c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.1,0.3-0.1,0.3-0.3c9.7-13.3,21.5-25.1,34.8-34.8
			c0.1-0.1,0.1-0.2,0.3-0.3c0.1-0.1,0.3-0.1,0.5-0.2c13.3-9.6,28.1-17.3,44-22.5c0,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.1,0
			c15.5-5.1,32.1-7.8,49.3-7.8s33.8,2.7,49.3,7.8c0.1,0,0.1,0,0.1,0c0,0.1,0.1,0.1,0.1,0.1c15.9,5.2,30.7,12.9,44,22.5
			c0.1,0.1,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.3,0.3c13.3,9.7,25.1,21.5,34.8,34.8c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.2,0.5
			c9.6,13.3,17.3,28.1,22.5,44c0,0,0,0.1,0.1,0.1c0,0.1,0,0.1,0,0.1C355.6,164.6,358.3,181.2,358.3,198.4z" />
                    <path d="M267.2,119.2c-2.8-2.4-7-2.1-9.4,0.7l-49.4,57.4c-3.1-1.4-6.3-2.3-9.9-2.3c-12.9,0-23.3,10.5-23.3,23.3
			s10.5,23.3,23.3,23.3c12.9,0,23.3-10.5,23.3-23.3c0-4.5-1.3-8.6-3.5-12.1l49.5-57.6C270.3,125.8,270,121.6,267.2,119.2z
			 M198.5,208.4c-5.5,0-10-4.5-10-10s4.5-10,10-10c2.7,0,5.1,1.1,6.8,2.7c0,0,0,0,0.1,0c1.9,1.9,3.1,4.4,3.1,7.3
			C208.5,203.9,204,208.4,198.5,208.4z" />
                    <path d="M236,260.1h-75c-7.3,0-13.3,6-13.3,13.3v24.2c0,7.3,6,13.3,13.3,13.3h75c7.3,0,13.3-6,13.3-13.3v-24.2
			C249.3,266.1,243.3,260.1,236,260.1z M236,297.6h-75v-24.2h75V297.6z" />
                </g>
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default MileageSVG;
