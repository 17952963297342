import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Button, Col, Pagination, Row, Empty,
} from 'antd';
import { reject, isEmpty } from 'underscore';
import * as videoActions from '../../../core/video/videoActions';
import VideoFormBox from '../../../components/elements/VideoFormBox';
import srcImgTruck from '../../../assets/images/truck.jpg';

class LiveVideosSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
        }
    }

    componentDidMount() {
        const {
            actions, params, toggleVideoScreen, clearLiveVideos,
        } = this.props;
        const assetId = params && params.assetId ? params.assetId : null;
        const filter = assetId ? { asset_id: assetId } : {};
        actions.getAssetsVideoListRequest(filter);
        actions.checkDvrOnlineStatusRequest();

        const urlParams = new URLSearchParams(window.location.search);
        const imei = urlParams.get('imei');
        if (!isEmpty(imei) && !isEmpty(assetId)) {
            clearLiveVideos();
            actions.getVideoLiveTabRequest({
                assetId,
                imei,
            });
            setTimeout(() => toggleVideoScreen(), 1000);
        }
    }

    toggleVideoSelect = (id, isChecked) => {
        const { selectedCameras, actions } = this.props;
        let currentVideoList = [];
        // let x = 0;
        // const y = 0;
        // let xIncrementStep = 1;
        // let videoBlockSize = 1;
        // if (selectedCameras.length < 4) {
        //     videoBlockSize = 1;
        //     xIncrementStep = 1;
        // } else if (selectedCameras.length >= 17) {
        //     x = -1;
        // }
        if (isChecked === false) {
            currentVideoList = reject(selectedCameras, (obj) => obj.id == id);
        } else {
            currentVideoList = selectedCameras;
            if (selectedCameras.length < 8) {
                const tmp = {};
                tmp.id = id;
                tmp.checked = isChecked;
                // tmp.pinned = currentVideoList.length === 0;
                currentVideoList.push(tmp);
            }
        }
        currentVideoList = currentVideoList.map((camera, cameraIndex) => {
            // x += xIncrementStep;
            // if (x > 4) {
            //     x = 0;
            // }
            // camera.data = {};
            camera.data = {
                key: `camera-sub-${camera.id}`,
                // x,
                // y,
                // w: ((cameraIndex === 0 && currentVideoList.length < 17) ? 2 : videoBlockSize),
                // h: 1,
                // isDraggable: true,
                // isResizable: false,
            };
            // camera.pinned = ((cameraIndex === 0 && currentVideoList.length < 17)  ? true : false);
            return camera;
        });
        // console.log("currentVideoList: ", currentVideoList)
        // console.log("selectedCameras: ", selectedCameras)
        actions.updateVideoCameraSelection(currentVideoList);
    };

    render() {
        const {
            toggleVideoScreen, liveCameras, liveCamerasPagination, selectedCameras, videoOnlineStatusChecking,
        } = this.props;
        const { currentPage } = this.state;

        const allCameras = [];
        return (
            <>
                <div style={{ textAlign: 'center', padding: '24px 16px' }}>
                    <p style={{ fontSize: '14px', color: '#8396a1', fontFamily: 'Lato, sans-serif' }}>Select the cameras to view</p>
                    <div className="videos">
                        {liveCameras.length > 0
                            ? (
                                <Row gutter={24} justify="center" type="flex">
                                    {liveCameras.map((camera, cameraIndex) => {
                                        const assetImage = camera.image_source || srcImgTruck;
                                        return (
                                            <Col
                                                key={`camera-channel-${cameraIndex}`}
                                                className="mt-3 video-box-fixed-width"
                                                xs={24}
                                                sm={12}
                                                md={8}
                                                lg={6}
                                                xl={4}
                                                xxl={3}>
                                                <VideoFormBox
                                                    isFetchingStatus={videoOnlineStatusChecking}
                                                    image={assetImage}
                                                    name={camera.name || 'Unknown'}
                                                    driver={camera.full_name || 'Unknown'}
                                                    registration={camera.registration || 'Unknown'}
                                                    onSelect={this.toggleVideoSelect}
                                                    camera={camera}
                                                    selectedCameras={selectedCameras} />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            )
                            : (
                                <Row style={{ marginTop: '20px' }}>
                                    <div style={{ width: '100%' }}>
                                        <div style={{ margin: 'auto' }}>
                                            <Empty description="No video devices found for the asset" />
                                        </div>
                                    </div>
                                </Row>
                            )}
                    </div>
                </div>
                {liveCameras.length > 0 && (
                    <>
                        <div 
                            style={{ marginBottom: 10 }} 
                            className='custom-pagination'
                        >
                            <Pagination
                                current={currentPage}
                                total={(parseInt(liveCamerasPagination.totalRecords, 10))}
                                pageSize={parseInt(liveCamerasPagination.perPageCount, 10)}
                                onChange={(page, pageSize) => {
                                    this.setState({ currentPage: page });
                                    const { actions } = this.props;
                                    actions.getAssetsVideoListRequest({
                                        page,
                                    });
                                }}
                                showSizeChanger={false}
                            />
                        </div>
                        <div
                            className="mt-4 text-center"
                            style={selectedCameras.length > 0 ? {
                                position: 'fixed', 
                                bottom: 0, 
                                left: 0, 
                                width: '100%', 
                                padding: 10, 
                                backgroundColor: 'white',
                            } : {}}
                        >
                            <Button
                                type="primary"
                                className="btn extrawide"
                                size="large"
                                disabled={isEmpty(selectedCameras)}
                                onClick={() => toggleVideoScreen(false)}
                            >
                                View
                            </Button>
                        </div>
                    </>
                )}
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        liveCameras: state.video.liveCameras,
        liveCamerasPagination: state.video.liveCamerasPagination,
        selectedCameras: state.video.selectedCameras,
        videoOnlineStatusChecking: state.video.videoOnlineStatusChecking,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...videoActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LiveVideosSelection);
