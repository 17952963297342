import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'underscore';
import { connect } from 'react-redux';
import { Spin, Empty } from 'antd';
import * as eventsActions from '../../../core/events/eventsActions';
import DetailView from '../../../components/DetailView';

class EventScreen extends Component {
    componentDidMount() {
        const {
            actions, report, selectedEvent, shardId,
        } = this.props;
        const { eventId, reference, historicVideoId } = report;
        if (eventId) {
            actions.getEventInfoRequest({
                eventId: report.eventId,
                deviceId: report.deviceId,
                eventView: Math.random(),
                before_time: report.before_time,
                after_time: report.after_time,
                eventView: 'true',
                isLoggedIn: false,
            });
        } else if (reference) {
            actions.getEventInfoByVideoReferenceRequest({ videoReference: reference, shardId: shardId || '1' });
        } else if (historicVideoId) {
            const params = {};
            params.videoId = historicVideoId;
            params.startTime = report.start_time;
            params.endTime = report.end_time;
            actions.getEventInfoByVideoRequest(params);
        }
    }

    render() {
        const centerMap = {
            lat: 52.48759,
            lng: -1.91199,
        };

        const {
            selectedEvent, report, actions, targetRef, isFetching, parent,
        } = this.props;

        const { eventId, start_time, end_time } = report;

        return (
            <>
                <div className="section__header">
                    <div className="section__header-item is-active">
                        <span>Event: {eventId}</span>
                    </div>
                </div>
                <div className="section__body">
                    <Spin spinning={isFetching}>
                        {!isEmpty(selectedEvent)
                            ? (
                                <DetailView
                                    allowActions={false}
                                    showShareBlock={false}
                                    selectedEvent={selectedEvent}
                                    parent={parent} />
                            )
                            : <Empty />}
                    </Spin>
                </div>
            </>
        );
    }
}

EventScreen.defaultProps = {
    parent: undefined,
};

EventScreen.propTypes = {
    actions: PropTypes.object.isRequired,
    parent: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedEvent: state.events.selectedEvent,
        report: state.events.report,
        isFetching: state.events.isFetching,
        eventInfoFetched: state.events.eventInfoFetched,
        shardId: state.global.shardId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...eventsActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventScreen);
