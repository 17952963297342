/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button, Spin, Switch, Row, Col,
} from 'antd';
import moment from 'moment';

import Map from '../../../components/Map';
import GridView from '../../../components/elements/GridView/index';
import Icon from '../../../components/elements/Icon';
import MapAssetPopup from '../../../components/elements/MapAssetPopup';

import app from '../../../core/constants';
import { requestMarkerDetails } from '../../../core/utils/mapUtils';
import { getAddressFromLatLong } from '../../../core/utils/api';
import LocationFetcher from '../../../components/elements/LocationFetcher';

const { GOOGLE_MAP_API_KEY } = app;

const {
    MapEventPopupHead,
} = MapAssetPopup;

const onlineIcon = (
    <svg height={12} width={12}>
        <circle cx={6} cy={6} r={3} fill="#2e9d0d" />
    </svg>
);

const offlineIcon = (
    <svg height={12} width={12}>
        <circle cx={6} cy={6} r={3} fill="#a4a4a4" />
    </svg>
);

export default function (videos, loading, locationSearch, setLocationSearch, latitude, longitude, mapSearchLocationBoundary) {
    const {
        pagination, user, mapOpen, setMapOpen,
    } = this.props;

    const { markerPopover } = this.state;

    let unit = 'kph';
    if (user && user.profile && user.profile.unit_of_measurement_preference === 'british-imperial') {
        unit = 'mph';
    }

    const columns = (videos && videos.map((v) => {
        let rowLocation = null;
        if (v.location) {
            rowLocation = v.location;
        } else if (v.latitude && v.longitude) {
            rowLocation = (
                <LocationFetcher
                    key={v.id}
                    lat={v.latitude}
                    lon={v.longitude}
                />
            );
        } else {
            rowLocation = '(No GPS data)';
        }

        const data = {
            id: v.id,
            date: v.start_time && moment(v.start_time).format('DD/MM/YYYY'),
            time: v.start_time && v.start_time.substring(11),
            location: rowLocation,
            driver: (
                v.user_id === null ? '(Not Set)'
                    : v.user_deleted === '1' ? v.user_name : (
                        <div onClick={e => { e.stopPropagation(); }}>
                            <Link to={`/drivers/view/${v.user_id}`} className="btn-link">
                                {v.user_name}
                            </Link>
                        </div>
                    )
            ),
            asset_reg: (
                v.asset_id === null ? '(Not Set)'
                    : v.asset_deleted === '1' ? (
                        <span>{v.online_status == '1' ? onlineIcon : offlineIcon} {v.asset_registration}</span>
                    ) : (
                        <div onClick={e => { e.stopPropagation(); }}>
                            <Link to={`/assets/view/${v.asset_id}`} className="btn-link">
                                {v.online_status == '1' ? onlineIcon : offlineIcon} {v.asset_registration}
                            </Link>
                        </div>
                    )
            ),
        };

        if (locationSearch) {
            if (v.distance) {
                let distance = parseFloat(v.distance);
                let distance_unit = 'mile';
                if (user && user.profile && user.profile.unit_of_measurement_preference !== 'british-imperial') {
                    distance *= 1.6093;
                    distance_unit = 'km';
                }
                if (v.distance > 1) {
                    distance_unit += 's';
                }
                data.proximity = `${distance.toFixed(2)} ${distance_unit}`;
            } else {
                data.proximity = '(Not Set)';
            }
        }

        data.actions = (
            <a onClick={() => { window.open(`/video/view/${v.id}`, '_blank').focus() }}>
                <Icon
                    name="chevron-right"
                    className="ml-2"
                />
            </a>
        );

        return data;
    })) || [];

    const markers = (videos && videos.map((v) => {
        const title = (
            <MapEventPopupHead
                speed={v.speed}
                heading={v.asset_name}
                registration={v.asset_registration} />
        );

        return {
            showArrowIcon: true,
            title,
            name: v.asset_name,
            lat: parseFloat(v.latitude) !== 0 ? v.latitude : null,
            lng: parseFloat(v.longitude) !== 0 ? v.longitude : null,
            record_id: v.record_id || v.latest_record_id,
            date_from: v.date_from,
            asset_id: v.asset_id,
            onClick: false,
        };
    })) || [];

    const optionalProps = {};
    if (latitude !== null && longitude !== null) {
        optionalProps.center = {
            lat: latitude,
            lng: longitude,
        };
    }

    const mapToolBarButtons = [
        { key: 'side-bar', visible: false },
        { key: 'info-box', visible: true },
        { key: 'traffic', visible: false },
        { key: 'geo-fence', visible: false },
        { key: 'center-map', visible: false },
        { key: 'satellite', visible: true },
        { key: 'heat-map', visible: false },
        { key: 'live-map', visible: false },
    ];

    const switchStyle = {
        textAlign: 'right',
        paddingTop: '15px',
    };

    if (mapOpen) {
        // switchStyle.marginBottom = -40;
        switchStyle.zIndex = 1;
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <div style={switchStyle}>
                        <Switch
                            checkedChildren="Hide Map"
                            unCheckedChildren="Show Map"
                            checked={mapOpen}
                            onChange={() => {
                                setMapOpen(!mapOpen);
                            }} />
                    </div>
                </Col>
            </Row>

            {mapOpen
                ? (
                    <Row>
                        <Col span={24}>
                            <div style={{
                                marginBottom: '10px',
                                display: 'flex',
                                height: '50vh',
                                flexDirection: 'row',
                            }}>
                                <Map
                                    ref={this.googleMapAccess}
                                    mapToolBarButtons={mapToolBarButtons}
                                    markerOnMouseEnter={(marker) => requestMarkerDetails(marker, this)}
                                    markerOnMouseLeave={() => { }}
                                    markerPopover={markerPopover}
                                    hideDatePicker
                                    hideSlider
                                    showToolbar
                                    allowStreetView={false}
                                    showFindCenterButton={false}
                                    apiKey={GOOGLE_MAP_API_KEY}
                                    markers={markers}
                                    searchPolygonPath={mapSearchLocationBoundary}
                                    getMapSearchBoundary={this.getMapSearchBoundary}
                                    // zoom={10}
                                    {...optionalProps}
                                    autoZoom />
                            </div>
                        </Col>
                    </Row>
                )
                : null}
            <Spin spinning={loading}>
                <GridView
                    data={columns}
                    onChange={this.handleTableChange}
                    sortableColumns={['video_id', 'date', 'time', 'location', 'driver', 'asset_reg', 'status', 'duration', 'channel']}
                    hiddenColumns={['id']}
                    pagination={{
                        total: parseInt(pagination.totalRecords, 10) || 0,
                        current: parseInt(pagination.currentPage, 10),
                        pageSize: parseInt(pagination.perPageCount, 10),
                        showSizeChanger: false,
                        onChange: this.paginationChange,
                    }} />
            </Spin>
        </>

    );
}
