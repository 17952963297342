import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { isEmpty } from 'underscore';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Switch, Input, DatePicker, Button } from 'antd';

import * as userActions from '../../../core/user/userActions';

const FormItem = Form.Item;

class LeftSide extends React.Component {
    componentDidMount() {
        const { registerSubmitHandler } = this.props;
        registerSubmitHandler(this.submitDetails.bind(this));
    }

    submitDetails() {
        const { actions, form, user } = this.props;

        form.validateFieldsAndScroll((err, vals) => {
            if (err) {
                return false;
            }

            const values = {
                ...vals,
                date_of_birth: vals.date_of_birth_moment.format('YYYY-MM-DD'),
                unit_of_measurement_preference: vals.unit_of_measurement_preference ? 'metric' : 'british-imperial',
                show_info_preference: +vals.show_info_preference,
            };
            actions.updateUserProfileRequest(values);

            return true;
        });
    }

    render() {
        const {
            form, openPasswordChangeModal, user,
        } = this.props;
        const { getFieldDecorator } = form;
        const { unit_of_measurement_preference, show_info_preference } = user?.profile || {};

        const uomChecked = unit_of_measurement_preference !== 'british-imperial';
        const showInfoChecked = !!show_info_preference;

        return (
            <div>
                <div className="main-info-wrapper">
                    <div className="left-side">
                        <div>
                            <span>Role</span>
                            <div style={{ height: '8px' }} />
                            <span style={{ textTransform: 'uppercase' }}>{user.role}</span>
                        </div>
                        <div style={{ marginTop: '8px' }}>
                            <span>Password</span>
                            <div style={{ height: '8px' }} />
                            <span>
                                ********
                                <Button 
                                    type="link" 
                                    onClick={openPasswordChangeModal}
                                    style={{ marginTop: '-8px' }}
                                >
                                    change
                                </Button>
                            </span>
                        </div>
                    </div>
                    <br />
                    <div className="right-side">
                        <div>
                            <span>Email</span>
                            <div style={{ height: '8px' }} />
                            <span>{user.loginUser}</span>
                        </div>
                        <div style={{ marginTop: '8px' }}>
                            <span>Division</span>
                            <div style={{ height: '8px' }} />
                            <span>{user.userDetail.division_name === '(Not Set)' ? '-' : user.userDetail.division_name}</span>
                        </div>
                    </div>
                </div>
                <br />
                <ul className="list left-side-user-list">
                    <li className="list__item">
                        <div className="list__item-name">
                            Name
                        </div>
                        <div 
                            className="list__item-value"
                            style={{ marginTop: '4px' }}
                        >
                            <FormItem style={{ display: 'block' }}>
                                {getFieldDecorator('full_name', {
                                    initialValue: user.profile.full_name,
                                    rules: [
                                        { transform: (value) => value.trim() },
                                        {
                                            required: true,
                                            message: 'Please enter your name',
                                        },
                                        { max: 255, message: 'Maximum 255 characters' }
                                    ],
                                })(<Input placeholder="Enter name" />)}
                            </FormItem>
                        </div>
                    </li>
                    <li className="list__item">
                        <div className="list__item-name">
                            Contact Number
                        </div>
                        <div 
                            className="list__item-value"
                            style={{ marginTop: '4px' }}
                        >
                            <FormItem style={{ display: 'block' }}>
                                {getFieldDecorator('phone_number', {
                                    initialValue: user.profile.phone_number,
                                    rules: [
                                        { transform: (value) => value.trim() },
                                        {
                                            required: true,
                                            message: 'Please input your phone number',
                                        },
                                        { max: 255, message: 'Maximum 255 characters' }
                                    ],
                                })(<Input placeholder="Phone number" />)}
                            </FormItem>
                        </div>
                    </li>
                    <li className="list__item">
                        <div className="list__item-name">
                            Date of birth
                        </div>
                        <div 
                            className="list__item-value"
                            style={{ marginTop: '4px' }}
                        >
                            <FormItem style={{ display: 'block' }}>
                                {getFieldDecorator('date_of_birth_moment', {
                                    initialValue: !isEmpty(user.profile.date_of_birth) ? dayjs(user.profile.date_of_birth, 'YYYYMMDD') : dayjs(),
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select date',
                                        },
                                    ],
                                })(
                                    <DatePicker format="DD/MM/YYYY" placeholder="Date of birth" style={{ width: '100%' }} />,
                                )}
                            </FormItem>
                        </div>
                    </li>
                    <br />
                    <li>
                        <div className="list__item-name" style={{ marginBottom: '8px' }}>
                            Unit of measurement preference
                        </div>
                        <div className="list__item-value" style={{ marginBottom: '8px' }}>
                            <FormItem style={{ marginLeft: '8px' }}>
                                {getFieldDecorator('unit_of_measurement_preference', {
                                    valuePropName: 'checked',
                                    initialValue: uomChecked,
                                })(<Switch checkedChildren="Metric" unCheckedChildren="British Imperial" />)}
                            </FormItem>
                        </div>
                    </li>
                    <li>
                        <div className="list__item-name" style={{ marginBottom: '8px' }}>
                            Show asset names on map
                        </div>
                        <div className="list__item-value" style={{ marginBottom: '8px' }}>
                            <FormItem style={{ marginLeft: '8px' }}>
                                {getFieldDecorator('show_info_preference', {
                                    valuePropName: 'checked',
                                    initialValue: showInfoChecked,
                                })(<Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />)}
                            </FormItem>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }
}

LeftSide.defaultProps = {
    openPasswordChangeModal: () => null,
    registerSubmitHandler: () => null,
    getFieldDecorator: () => null,
};

LeftSide.propTypes = {
    openPasswordChangeModal: PropTypes.func,
    registerSubmitHandler: PropTypes.func,
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    getFieldDecorator: PropTypes.func,
};

const ProfileMainForm = Form.create()(LeftSide);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProfileMainForm);
