/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Input, Button, Avatar as AntAvatar, Modal, List } from 'antd';

import '@rc-component/color-picker/assets/index.css';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import {
    ApiOutlined,
    BugOutlined,
    CameraOutlined,
    CarOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    DashboardOutlined,
    EnvironmentOutlined,
    FireOutlined,
    HeartOutlined,
    HeatMapOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    QuestionCircleOutlined,
    StarOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import * as dashboardActions from '../../../../core/dashboard/dashboardActions';
import * as smartWidgetActions from '../../../../core/smartWidget/smartWidgetActions';

import CarbonReportSVG from '../../../../assets/icons/CarbonReportSVG';
import AutoStopLogoColourSVG from '../../../../assets/icons/AutoStopLogoColourSVG';
import CyclearLogoGreySVG from '../../../../assets/icons/CyclearLogoGreySVG';
import DriverBehaviourSVG from '../../../../assets/icons/DriverBehaviourSVG';
import IDClearLogoColourSVG from '../../../../assets/icons/IDClearLogoColourSVG';
import LowFuelSVG from '../../../../assets/icons/LowFuelSVG';
import AdvancedGPSAccuracySVG from '../../../../assets/icons/AdvancedGPSAccuracySVG';
import AntiTamperHardwareSVG from '../../../../assets/icons/AntiTamperHardwareSVG';
import AutomaticHealthStatusReportsSVG from '../../../../assets/icons/AutomaticHealthStatusReportsSVG';
import CustomisableWidgetDashboardSVG from '../../../../assets/icons/CustomisableWidgetDashboardSVG';
import DriverIdentificationSVG from '../../../../assets/icons/DriverIdentificationSVG';
import EasyReportAndAdminControlSVG from '../../../../assets/icons/EasyReportAndAdminControlSVG';
import EventReportingSVG from '../../../../assets/icons/EventReportingSVG';
import FnolSVG from '../../../../assets/icons/FnolSVG';
import FuelEfficiencySVG from '../../../../assets/icons/FuelEfficiencySVG';
import FuelOptimisationSVG from '../../../../assets/icons/FuelOptimisationSVG';
import GeoFencingSVG from '../../../../assets/icons/GeoFencingSVG';
import ISSInstallationAndServiceQualityAssuranceSVG from '../../../../assets/icons/ISSInstallationAndServiceQualityAssuranceSVG';
import MapAndAssetDisplaySVG from '../../../../assets/icons/MapAndAssetDisplaySVG';
import MultiAssetActivitySideBarSVG from '../../../../assets/icons/MultiAssetActivitySideBarSVG';
import NearestVehicleAndPostcodeSearchSVG from '../../../../assets/icons/NearestVehicleAndPostcodeSearchSVG';
import OperationalAlertsSVG from '../../../../assets/icons/OperationalAlertsSVG';
import PTOConnectionGuaranteeSVG from '../../../../assets/icons/PTOConnectionGuaranteeSVG';
import RealTimeTrackingTelematicsAndVideoSVG from '../../../../assets/icons/RealTimeTrackingTelematicsAndVideoSVG';
import RealTimeTrafficOverlaySVG from '../../../../assets/icons/RealTimeTrafficOverlaySVG';
import RemoteTachographDownloadSVG from '../../../../assets/icons/RemoteTachographDownloadSVG';
import RoadRelatedSpeedingAlertsSVG from '../../../../assets/icons/RoadRelatedSpeedingAlertsSVG';
import RouteOptimisationSVG from '../../../../assets/icons/RouteOptimisationSVG';
import RoutePlanningWithLiveTrafficSVG from '../../../../assets/icons/RoutePlanningWithLiveTrafficSVG';
import RXLiteIntegrationSVG from '../../../../assets/icons/RXLiteIntegrationSVG';
import UKInHouseDevelopmentTeamSVG from '../../../../assets/icons/UKInHouseDevelopmentTeamSVG';
import WebBasedTrackingPlatformSVG from '../../../../assets/icons/WebBasedTrackingPlatformSVG';

const CarbonIcon = (props) => <LegacyIcon component={CarbonReportSVG} {...props} />;
const AutoStopLogoColourIcon = (props) => <LegacyIcon component={AutoStopLogoColourSVG} {...props} />;
const CyclearLogoGreyIcon = (props) => <LegacyIcon component={CyclearLogoGreySVG} {...props} />;
const DriverBehaviourIcon = (props) => <LegacyIcon component={DriverBehaviourSVG} {...props} />;
const IDClearLogoColourIcon = (props) => <LegacyIcon component={IDClearLogoColourSVG} {...props} />;
const LowFuelIcon = (props) => <LegacyIcon component={LowFuelSVG} {...props} />;
const AdvancedGPSAccuracyIcon = (props) => <LegacyIcon component={AdvancedGPSAccuracySVG} {...props} />;
const AntiTamperHardwareIcon = (props) => <LegacyIcon component={AntiTamperHardwareSVG} {...props} />;
const AutomaticHealthStatusReportsIcon = (props) => <LegacyIcon component={AutomaticHealthStatusReportsSVG} {...props} />;
const CustomisableWidgetDashboardIcon = (props) => <LegacyIcon component={CustomisableWidgetDashboardSVG} {...props} />;
const DriverIdentificationIcon = (props) => <LegacyIcon component={DriverIdentificationSVG} {...props} />;
const EasyReportAndAdminControlIcon = (props) => <LegacyIcon component={EasyReportAndAdminControlSVG} {...props} />;
const EventReportingIcon = (props) => <LegacyIcon component={EventReportingSVG} {...props} />;
const FnolIcon = (props) => <LegacyIcon component={FnolSVG} {...props} />;
const FuelEfficiencyIcon = (props) => <LegacyIcon component={FuelEfficiencySVG} {...props} />;
const FuelOptimisationIcon = (props) => <LegacyIcon component={FuelOptimisationSVG} {...props} />;
const GeoFencingIcon = (props) => <LegacyIcon component={GeoFencingSVG} {...props} />;
const ISSInstallationAndServiceQualityAssuranceIcon = (props) => <LegacyIcon component={ISSInstallationAndServiceQualityAssuranceSVG} {...props} />;
const MapAndAssetDisplayIcon = (props) => <LegacyIcon component={MapAndAssetDisplaySVG} {...props} />;
const MultiAssetActivitySideBarIcon = (props) => <LegacyIcon component={MultiAssetActivitySideBarSVG} {...props} />;
const NearestVehicleAndPostcodeSearchIcon = (props) => <LegacyIcon component={NearestVehicleAndPostcodeSearchSVG} {...props} />;
const OperationalAlertsIcon = (props) => <LegacyIcon component={OperationalAlertsSVG} {...props} />;
const PTOConnectionGuaranteeIcon = (props) => <LegacyIcon component={PTOConnectionGuaranteeSVG} {...props} />;
const RealTimeTrackingTelematicsAndVideoIcon = (props) => <LegacyIcon component={RealTimeTrackingTelematicsAndVideoSVG} {...props} />;
const RealTimeTrafficOverlayIcon = (props) => <LegacyIcon component={RealTimeTrafficOverlaySVG} {...props} />;
const RemoteTachographDownloadIcon = (props) => <LegacyIcon component={RemoteTachographDownloadSVG} {...props} />;
const RoadRelatedSpeedingAlertsIcon = (props) => <LegacyIcon component={RoadRelatedSpeedingAlertsSVG} {...props} />;
const RouteOptimisationIcon = (props) => <LegacyIcon component={RouteOptimisationSVG} {...props} />;
const RoutePlanningWithLiveTrafficIcon = (props) => <LegacyIcon component={RoutePlanningWithLiveTrafficSVG} {...props} />;
const RXLiteIntegrationIcon = (props) => <LegacyIcon component={RXLiteIntegrationSVG} {...props} />;
const UKInHouseDevelopmentTeamIcon = (props) => <LegacyIcon component={UKInHouseDevelopmentTeamSVG} {...props} />;
const WebBasedTrackingPlatformIcon = (props) => <LegacyIcon component={WebBasedTrackingPlatformSVG} {...props} />;

const FormItem = Form.Item;

// update icon list please also update iconwithvalueform, iconwithvaluesform and multiiconform
const icons = [
    {
        id: 1,
        title: <ClockCircleOutlined />,
    },
    {
        id: 2,
        title: <InfoCircleOutlined />,
    },
    {
        id: 3,
        title: <QuestionCircleOutlined />,
    },
    {
        id: 4,
        title: <WarningOutlined />,
    },
    {
        id: 5,
        title: <CheckCircleOutlined />,
    },
    {
        id: 6,
        title: <HeatMapOutlined />,
    },
    {
        id: 7,
        title: <ApiOutlined />,
    },
    {
        id: 8,
        title: <BugOutlined />,
    },
    {
        id: 9,
        title: <CameraOutlined />,
    },
    {
        id: 10,
        title: <CarOutlined />,
    },
    {
        id: 11,
        title: <DashboardOutlined />,
    },
    {
        id: 12,
        title: <EnvironmentOutlined />,
    },
    {
        id: 13,
        title: <FireOutlined />,
    },
    {
        id: 14,
        title: <HeartOutlined />,
    },
    {
        id: 15,
        title: <StarOutlined />,
    },
    {
        id: 16,
        title: <HomeOutlined />,
    },
    {
        id: 17,
        title: <CarbonIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 18,
        title: <AutoStopLogoColourIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 19,
        title: <CyclearLogoGreyIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 20,
        title: <DriverBehaviourIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 21,
        title: <IDClearLogoColourIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 22,
        title: <LowFuelIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 23,
        title: <AdvancedGPSAccuracyIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 24,
        title: <AntiTamperHardwareIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 25,
        title: <AutomaticHealthStatusReportsIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 26,
        title: <CustomisableWidgetDashboardIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 27,
        title: <DriverIdentificationIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 28,
        title: <EasyReportAndAdminControlIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 29,
        title: <EventReportingIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 30,
        title: <FnolIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 31,
        title: <FuelEfficiencyIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 32,
        title: <FuelOptimisationIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 33,
        title: <GeoFencingIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 34,
        title: <ISSInstallationAndServiceQualityAssuranceIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 35,
        title: <MapAndAssetDisplayIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 36,
        title: <MultiAssetActivitySideBarIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 37,
        title: <NearestVehicleAndPostcodeSearchIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 38,
        title: <OperationalAlertsIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 39,
        title: <PTOConnectionGuaranteeIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 40,
        title: <RealTimeTrackingTelematicsAndVideoIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 41,
        title: <RealTimeTrafficOverlayIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 42,
        title: <RemoteTachographDownloadIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 43,
        title: <RoadRelatedSpeedingAlertsIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 44,
        title: <RouteOptimisationIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 45,
        title: <RoutePlanningWithLiveTrafficIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 46,
        title: <RXLiteIntegrationIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 47,
        title: <UKInHouseDevelopmentTeamIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
    {
        id: 48,
        title: <WebBasedTrackingPlatformIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
    },
];

class IconPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            modalParent: null,
            iconSelected: null,
        };
    }

    setIcon = (item) => {
        const { form, onChange } = this.props;
        const { modalParent } = this.state;

        this.setState({ iconSelected: item.id });
        form.setFieldsValue({ [modalParent]: item.id });

        onChange({ id: item.id });
    }

    removeIcon = (item) => {
        const { form, onChange } = this.props;
        form.setFieldsValue({ [item]: null });

        onChange({ id: null });
    }

    setVisible = (value, modalParent) => {
        this.setState({ modalVisible: value, modalParent, iconSelected: null });
    };

    getIconById = (id) => {
        const icon = icons.find((item) => item.id === id);
        return icon ? icon.title : null;
    }

    render() {
        const {
            form, name, label, defaultIcon,
        } = this.props;
        const { getFieldDecorator } = form;
        const { modalVisible, iconSelected } = this.state;

        return <>
            <FormItem className="icon-field" label={label}>
                {getFieldDecorator(name, {
                    rules: [
                        {
                            required: true,
                            message: 'Please choose icon',
                        },
                    ],
                    initialValue: defaultIcon,
                })(
                    <>
                        <Input hidden />
                        <AntAvatar 
                            className="icon-avatar" 
                            shape="square" 
                            size={64} 
                            icon={this.getIconById(form.getFieldValue(name))} 
                        />
                        <Button 
                            className="icon-button change-button" 
                            onClick={() => this.setVisible(true, name)}
                        >
                            Change
                        </Button>
                        <Button 
                            className="icon-button danger-button" 
                            onClick={() => this.removeIcon(name)}
                        >
                            Remove
                        </Button>
                    </>
                )}
            </FormItem>
            <br />
            {/* Icon Modal */}
            <Modal
                title="Select Icon"
                centered
                transitionName=""
                maskTransitionName=""
                open={modalVisible}
                onOk={() => this.setVisible(false)}
                onCancel={() => this.setVisible(false)}
                width={1000}>
                <List
                    className="icon-list"
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 4,
                        xl: 4,
                        xxl: 4,
                    }}
                    dataSource={icons}
                    renderItem={(item) => (
                        <List.Item>
                            <AntAvatar
                                style={{ cursor: 'pointer', border: '2px solid #ccc' }}
                                shape="square"
                                size={128}
                                icon={item.title}
                                className={(iconSelected === item.id) ? 'icon_active' : null}
                                onClick={() => this.setIcon(item)} />
                        </List.Item>
                    )} />
            </Modal>
        </>;
    }
}

IconPicker.defaultProps = {
    name: 'icon',
    form: [],
    label: 'Icon',
    defaultIcon: null,
    onChange: () => {},
};

IconPicker.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    defaultIcon: PropTypes.number,
    onChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...dashboardActions,
            ...smartWidgetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IconPicker);
