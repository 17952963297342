import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { FlagFilled } from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import GridView from '../../../components/elements/GridView/index';
import Icon from '../../../components/elements/Icon';
import * as driverActions from '../../../core/driver/driverActions';
import { isoStandardDateTimeFormat } from '../../../core/utils/dateUtils';

class DriverEventHistoryTab extends Component {
    componentDidMount() {
        const {
            actions,
            setLoadedState,
            eventsHistoryTabLoaded,
            driverId,
        } = this.props;
        if (!eventsHistoryTabLoaded) {
            actions.getDriverEventsRequest({
                driverId,
                page: 1,
                date_from: moment(new Date()).startOf('day').format(isoStandardDateTimeFormat),
                date_to: moment(new Date()).format(isoStandardDateTimeFormat),
            });
            setLoadedState();
        }
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange, driverEventsPagination } = this.props;

        if (pagination && pagination.current == driverEventsPagination.currentPage) {
            onTableChange(pagination, filters, sorter, extra);
        }
    };

    render() {
        const {
            driverEvents, driverEventsPagination, isFetching, eventHistoryFirstLoad,
        } = this.props;
        const listDriverEventHistory = driverEvents ? driverEvents.map((event) => {
            const iconStyle = {};
            iconStyle.fontSize = '16px';
            iconStyle.color = '#CF2B28';
            const tempEvent = {};
            // tempEvent.event_id = event.id;
            let eventTitle = event.event_name;

            if (event.event_type == 253) {
                if (event.green_driving_type == 0) {
                    eventTitle = 'Eco-friendly driving';
                } else if (event.green_driving_type == 1) {
                    eventTitle = 'Harsh accelerating';
                } else if (event.green_driving_type == 2) {
                    eventTitle = 'Harsh braking';
                } else if (event.green_driving_type == 3) {
                    eventTitle = 'Harsh cornering';
                }
            }

            tempEvent.event_type = event.icon && event.icon !== ''
                ? <Tooltip placement="top" title={eventTitle}><LegacyIcon style={iconStyle} theme="filled" type={event.icon} /> {event.event_name}</Tooltip>
                : <Tooltip placement="top" title={eventTitle}><FlagFilled style={iconStyle} /> {event.event_name}</Tooltip>;

            tempEvent.date = moment(event.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
            tempEvent.time = moment(event.time, 'hh:mm A').format('hh:mm A');
            tempEvent.location = event.location_name;
            // tempEvent.driver = <Link to={`/drivers/view/${event.user_id}`}>{event.full_name}</Link>;
            tempEvent.asset_reg = <Link to={`/assets/view/${event.asset_id}`}>{event.registration}</Link>;
            tempEvent.actions = (
                <Link to={`/events/custom-view/${event.id}/${event.device_id}`} style={{ float: 'right' }}>
                    <Icon name="chevron-right" className="ml-2" />
                </Link>
            );
            return tempEvent;
        }) : [];

        let spinning = false;
        for (let i = 0; i < isFetching.length; i++) {
            const item = isFetching[i];
            if (item.GET_DRIVER_EVENTS) {
                spinning = true;
                break;
            }
        }

        return (
            <Spin spinning={spinning}>
                {eventHistoryFirstLoad ? (
                    <div className="h-centered-text-container">
                        <p>(Filtering by Today)</p>
                    </div>
                ) : null}
                <GridView
                    data={listDriverEventHistory}
                    onChange={this.onTableChange}
                    sortableColumns={['event_type', 'date', 'time', 'asset_reg']}
                    pagination={{
                        total: parseInt(driverEventsPagination.totalRecords, 10),
                        showSizeChanger: false,
                        pageSize: parseInt(driverEventsPagination.perPageCount, 10),
                        onChange: this.onPageChange,
                    }} />
            </Spin>
        );
    }
}

DriverEventHistoryTab.defaultProps = {
    actions: {},
    setLoadedState: () => null,
    eventsHistoryTabLoaded: false,
    driverId: '',
    onPageChange: () => null,
    onTableChange: () => null,
    driverEvents: [],
    driverEventsPagination: {},
};

DriverEventHistoryTab.propTypes = {
    actions: PropTypes.object,
    setLoadedState: PropTypes.func,
    eventsHistoryTabLoaded: PropTypes.bool,
    driverId: PropTypes.string,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
    driverEvents: PropTypes.array,
    driverEventsPagination: PropTypes.object,
    isFetching: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        driverEvents: state.driver.driverEvents,
        driverEventsPagination: state.driver.driverEventsPagination,
        isFetching: state.driver.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...driverActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DriverEventHistoryTab);
