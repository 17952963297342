/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { DatePicker, Input, Select, Col, Radio } from 'antd';
import { isEmpty, isUndefined } from 'underscore';
import Sticky from 'react-sticky-el';
import '@rc-component/color-picker/assets/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as dashboardActions from '../../../../core/dashboard/dashboardActions';
import * as smartWidgetActions from '../../../../core/smartWidget/smartWidgetActions';
import Icon from '../../../elements/Icon';
import ChartMultiDropDown from '../../ChartComponents/ChartMultiDropDown';
import ColorRule from '../ColorRule';
import IconPicker from '../IconPicker';
import IconChart from '../../ChartComponents/IconChart';
import { getChartIconList } from '../../../../core/utils/functions';

const { Option } = Select;
const FormItem = Form.Item;

class IconWithValueForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    onInputUpdates = () => {
        const { getChartDataFromInput } = this.props;

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            getChartDataFromInput();
        }, 700);
    }

    render() {
        const {
            form, saveDatatypeValues, getChartDataFromInput, division, driver, asset, setTimeframe, smartWidget, data,
        } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        const mappedIcons = getChartIconList();

        const formulas = [
            {
                name: 'Highest value found',
                key: 1,
            },
            {
                name: 'Average (mean) * default',
                key: 2,
            },
            {
                name: 'Average (median)',
                key: 3,
            },
            {
                name: 'Max value in data',
                key: 4,
            },
            {
                name: 'Min value in data',
                key: 5,
            },
            {
                name: 'Sum of all values',
                key: 6,
            },

        ];

        const input_data = getFieldValue('input_data');
        const eventType = input_data[0].event_type;
        const displayReportCacheFields = [1, 2, 3, 4, 5, 6, 7, 8, 9].includes(parseInt(eventType, 10));

        let preview = (
            <div className="smart-widget-preview">
                <h2>Widget Preview</h2>
                <FormItem className="widget-preview-date">
                    {getFieldDecorator('date', {
                        initialValue: dayjs(),
                        rules: [
                        ],
                    })(
                        <DatePicker
                            onChange={() => getChartDataFromInput()}
                            format="DD/MM/YYYY" />,
                    )}
                </FormItem>
                <br />

                <IconChart
                    heightRatio={2}
                    timeframe={smartWidget?.presetWidget?.timeframe || 'hourly'}
                    onTimeframeChange={(timeframe) => setTimeframe(timeframe)}
                    rightMenuItems={[]}
                    tooltipText={smartWidget?.presetWidget?.name || 'This is tooltip text'}
                    data={smartWidget.chartData}
                    onAllOptionChange={(optionsData) => { saveDatatypeValues(optionsData); getChartDataFromInput(); }}
                    divisions={[...division.divisionOptions]}
                    drivers={[...driver.driverList]}
                    assets={[...asset.options]} />
            </div>
        );

        const isSticky = window.innerWidth > 767;
        if (isSticky) {
            preview = <Sticky>{ preview }</Sticky>;
        }

        let icon_position = 'top';
        let main_label_text = 'Miles';
        let secondary_label_text = 'Total miles driven this week';
        let icon = 1;
        let colorRuleData = [];
        let color_type = 'fixed';
        let formula = null;

        if (!isUndefined(data) && !isEmpty(data[0])) {
            icon_position = data[0].icon_position;
            main_label_text = data[0].main_label_text;
            secondary_label_text = data[0].secondary_label_text;
            icon = data[0].icon;
            colorRuleData = (data[0]?.color_type == 'smart-rule') ? data[0].rules : data[0].color;
            color_type = data[0].color_type;
            formula = parseInt(data[0].formula);
        }

        return (<>
            <Col className="gutter-row first-col" xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="input-data-col">
                    <FormItem className="icon-position-checkbox" label="Icon Position">
                        {getFieldDecorator('input_data[0][icon_position]', {
                            rules: [
                            ],
                            initialValue: icon_position,
                        })(
                            <Radio.Group onChange={() => getChartDataFromInput()}>
                                <Radio value="top">Top</Radio>
                                <Radio value="left">Left</Radio>
                            </Radio.Group>,
                        )}
                    </FormItem>
                    <br />

                    <FormItem label="Main label text">
                        {getFieldDecorator('input_data[0][main_label_text]', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter main label text',
                                },
                            ],
                            initialValue: main_label_text,
                        })(
                            <Input
                                placeholder="Main label text"
                                onChange={() => this.onInputUpdates()} />,
                        )}
                    </FormItem>
                    <br />

                    {/* Secondary Label */}
                    <FormItem label="Secondary label text">
                        {getFieldDecorator('input_data[0][secondary_label_text]', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter label text',
                                },
                            ],
                            initialValue: secondary_label_text,
                        })(
                            <Input
                                placeholder="Secondary label text"
                                onChange={() => this.onInputUpdates()} />,
                        )}
                    </FormItem>
                    <br />

                    <IconPicker form={form} name="input_data[0][icon]" defaultIcon={icon} onChange={() => getChartDataFromInput()} />

                    <ColorRule
                        form={form}
                        name="input_data[0]"
                        mode={color_type}
                        data={colorRuleData}
                        onChange={() => getChartDataFromInput()} />

                    {displayReportCacheFields ? (
                        <FormItem label="Formula">
                            {getFieldDecorator('input_data[0][formula]', {
                                rules: [
                                ],
                                initialValue: formula,
                            })(
                                <Select
                                    onChange={() => getChartDataFromInput()}
                                    style={{ width: '100%' }}
                                    placeholder="Formula"
                                    suffixIcon={
                                        <Icon name="triangle-down" />
                                    }>
                                    {
                                        formulas.map((v, i) => (
                                            /* eslint-disable-next-line react/no-array-index-key */
                                            (<Option key={i} value={v.key}>{v.name}</Option>)
                                        ))
                                    }

                                </Select>,
                            )}
                        </FormItem>
                    )
                        : <p style={{ fontWeight: 'bold', textAlign: 'left' }}>Formula = Event Count</p>}
                    <br />

                    <p className="field-label">Default data view</p>

                    <ChartMultiDropDown
                        onDataChange={(optionsData) => { saveDatatypeValues(optionsData); getChartDataFromInput(); }}
                        divisions={[...division.divisionOptions]}
                        drivers={[...driver.driverList]}
                        assets={[...asset.options]} />

                </div>
            </Col>
            <Col className="smart-widget-preview-row gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                { preview }
            </Col>
        </>);
    }
}

IconWithValueForm.defaultProps = {
    saveDatatypeValues: () => {},
    getChartDataFromInput: () => {},
    setTimeframe: () => {},
};

IconWithValueForm.propTypes = {
    actions: PropTypes.object.isRequired,
    smartWidget: PropTypes.object.isRequired,
    division: PropTypes.object.isRequired,
    driver: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    saveDatatypeValues: PropTypes.func,
    getChartDataFromInput: PropTypes.func,
    setTimeframe: PropTypes.func,
    // newImageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        // newImageId: state.smartWidget.newImageId,
        smartWidget: state.smartWidget,
        division: state.division,
        driver: state.driver,
        asset: state.asset,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...dashboardActions,
            ...smartWidgetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IconWithValueForm);
