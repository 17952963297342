/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { each, isEmpty, isUndefined } from 'underscore';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { FlagFilled, VideoCameraOutlined } from '@ant-design/icons';
import {
    Spin, Tooltip, Button, Row, Col, 
} from 'antd';
import Map from '../../../components/Map';

import GridView from '../../../components/elements/GridView/index';
import Icon from '../../../components/elements/Icon';
import * as eventsActions from '../../../core/events/eventsActions';
import * as deviceActions from '../../../core/device/deviceActions';
import * as globalActions from '../../../core/global/globalActions';
import app from '../../../core/constants';
import {
    mapHeatMap, mapHeatMapMarkers, requestMarkerDetails, setMarkerPopoverDetails, setMarkerPopoverToLoading,
} from '../../../core/utils/mapUtils';
import MapAssetPopup from '../../../components/elements/MapAssetPopup';

const { GOOGLE_MAP_API_KEY } = app;

class EventsTab extends PureComponent {
    constructor(props) {
        super(props);
        this.tableEventsRef = React.createRef();
        this.state = {
            showMap: false,
            markerPopover: null,
            zoom: 6,
        };
        this.googleMapAccess = React.createRef();
    }

    componentDidMount() {
        const {
            actions, setLoadedState, eventsTabLoaded, eventsTabFilter,
            reload, eventsPagination, onPageChange, assetId, dateFrom, dateTo, eventType, drivers, divisions, mapDate,
        } = this.props;
        if (!eventsTabLoaded) {
            if (reload) {
                actions.getEventsRequest({
                    ...eventsTabFilter,
                    page: eventsPagination.currentPage,
                });
                actions.getMapEventsHeatMapRequest({ ...eventsTabFilter });
            } else {
                const params = {};

                // forcing date to and from not be more than one month
                let startdate = moment();
                startdate = startdate.startOf('day');
                startdate = startdate.format('YYYY-MM-DD HH:mm:ss');
                params.date_from = startdate;
                params.date_to = moment().format('YYYY-MM-DD HH:mm:ss');
                params.orderBy = '-time';
                params.page = 1;

                if (window.location.pathname.includes('search')) {
                    if (assetId) {
                        params.asset_info = assetId;
                    }

                    if (dateFrom) {
                        const isDatetimeString = moment(dateFrom, 'YYYY-MM-DD HH:mm:ss', true).isValid();
                        if (isDatetimeString) {
                            params.date_from = dateFrom;
                        } else {
                            params.date_from = moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss');
                        }
                    }

                    if (dateTo) {
                        const isDatetimeString = moment(dateTo, 'YYYY-MM-DD HH:mm:ss', true).isValid();
                        if (isDatetimeString) {
                            params.date_to = dateTo;
                        } else {
                            params.date_to = moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss');
                        }
                    }

                    if (!isUndefined(eventType) && eventType.length > 0) {
                        params.event_types = eventType;
                    }

                    if (!isUndefined(drivers) && drivers.length > 0) {
                        params.driver = [parseInt(drivers)];
                    }

                    if (!isUndefined(divisions) && divisions.length > 0) {
                        params.division = divisions;
                    }

                    if (mapDate) {
                        // If user comes from main map screen then use the map date to show events
                        params.date_from = mapDate;

                        actions.updateMapDate(null);
                    }
                }

                actions.getEventsRequest({
                    ...params,
                });
                actions.updateEventSearch({
                    ...params,
                });

                const heatMapParams = { ...params };
                if (heatMapParams.event_types) {
                    heatMapParams.eventTypes = heatMapParams.event_types;
                    delete heatMapParams.event_types;
                }
                actions.getMapEventsHeatMapRequest(heatMapParams);

                actions.saveEventsSearchParamsRequest(params);
            }

            setLoadedState();
            setMarkerPopoverToLoading(this);
        }
    }

    onMapChange = ({
        center, zoom, bounds, marginBounds,
    }) => {
        this.setState({ zoom });
    }

    componentDidUpdate(prevProps) {
        const {
            actions, deviceDetailedLocationFetching, deviceDetailedLocationInfo, mapHeatMapDataUpdated,
        } = this.props;

        if (mapHeatMapDataUpdated) {
            this.reloadHeatMap();
            actions.setHeatMapDataUpdated(false);
        }

        if (deviceDetailedLocationFetching === true && prevProps.deviceDetailedLocationFetching === false) {
            setMarkerPopoverToLoading(this);
        }

        if (deviceDetailedLocationFetching === false && deviceDetailedLocationInfo !== prevProps.deviceDetailedLocationInfo) {
            setMarkerPopoverDetails(this);
        }
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    toggleMap = () => {
        const { showMap } = this.state;
        const newShowMap = !showMap;
        this.setState({ showMap: newShowMap });
        if (newShowMap) {
            setTimeout(() => {
                this.toggleHeatMap();
            }, [2000]);
        }
    };

    reloadHeatMap = () => {
        if (this.googleMapAccess?.current?.state || false) {
            const { heatMapInstance } = this.googleMapAccess.current.state;
            const heatMapState = { showHeatMap: false, showInfoBox: false };
            heatMapState.heatMapInstance = null;
            this.googleMapAccess.current.setState(heatMapState, () => {
                if (heatMapInstance) {
                    heatMapInstance.setMap(null);
                }
                setTimeout(() => {
                    this.googleMapAccess.current.toggleHeatMapLayer();
                    this.googleMapAccess.current.toggleInfoBox();
                }, 500);
            });
        }
    };

    toggleHeatMap = () => {
        const { showHeatMap, heatMapInstance } = this.googleMapAccess.current.state;
        const newShowHeatMap = !showHeatMap;
        const heatMapState = { showHeatMap: newShowHeatMap };
        if (newShowHeatMap === false) {
            heatMapState.heatMapInstance = null;
            if (heatMapInstance) {
                heatMapInstance.setMap(null);
            }
        }
        setTimeout(() => {
            this.googleMapAccess.current.toggleHeatMapLayer();
            this.googleMapAccess.current.toggleInfoBox();
        }, 100);
    };

    render() {
        const {
            events,
            eventsPagination,
            mapDeviceHeatMap,
            isFetching,
            eventsTabFilter,
            assetId,
            mapDate,
        } = this.props;

        const {
            showMap, markerPopover, zoom,
        } = this.state;

        let heatMap = {};
        if (!isEmpty(mapDeviceHeatMap)) {
            heatMap = mapHeatMap(mapDeviceHeatMap);
        }

        const listEvents = events.map((event) => {
            const params = [];
            each(eventsTabFilter, (eventParamValue, eventParamKey) => {
                if (eventParamValue !== 'undefined' && !isEmpty(eventParamValue)) {
                    params.push(`${eventParamKey}=${eventParamValue}`);
                }
            });

            const iconStyle = {};
            iconStyle.fontSize = '16px';
            iconStyle.color = '#CF2B28';

            let eventTitle = event.event_name;
            let { event_name } = event;

            if (event.event_type == 253) {
                if (event.green_driving_type == 0) {
                    eventTitle = 'Eco-friendly driving';
                } else if (event.green_driving_type == 1) {
                    eventTitle = 'Harsh accelerating';
                } else if (event.green_driving_type == 2) {
                    eventTitle = 'Harsh braking';
                } else if (event.green_driving_type == 3) {
                    eventTitle = 'Harsh cornering';
                }
            }

            event_name = eventTitle;

            if (eventsTabFilter.event_type) {
                // if only speeding alerts was chosen from filters
                if (eventsTabFilter.event_type.includes('918') && eventsTabFilter.event_type.length === 1) {
                    event_name = (
                        <Tooltip placement="top" title="Speeding Alert"><LegacyIcon
                            style={iconStyle}
                            theme="filled"
                            type={event.icon} />&ensp;Speeding Alert
                        </Tooltip>
                    );
                } else if (eventsTabFilter.event_type.includes('918') && eventsTabFilter.event_type.length !== 1) {
                    event_name = (
                        <Tooltip placement="top" title={`${eventTitle}, Speeding Alert`}><LegacyIcon
                            style={iconStyle}
                            theme="filled"
                            type={event.icon} /> {eventTitle}, Speeding Alert
                        </Tooltip>
                    );
                } else {
                    event_name = event.icon && event.icon !== ''
                        ? (
                            <Tooltip placement="top" title={eventTitle}><LegacyIcon
                                style={iconStyle}
                                theme="filled"
                                type={event.icon} />&ensp;{event_name}
                            </Tooltip>
                        )
                        : (
                            <Tooltip placement="top" title={eventTitle}><FlagFilled style={iconStyle} />&ensp;{event_name}
                            </Tooltip>
                        );
                }
            } else {
                event_name = event.icon && event.icon !== ''
                    ? (
                        <Tooltip placement="top" title={eventTitle}><LegacyIcon
                            style={iconStyle}
                            theme="filled"
                            type={event.icon} />&ensp;{event_name}
                        </Tooltip>
                    )
                    : (
                        <Tooltip placement="top" title={eventTitle}><FlagFilled style={iconStyle} />&ensp;{event_name}
                        </Tooltip>
                    );
            }

            const tempEvent = {};
            tempEvent.id = event.id;
            tempEvent.event = event_name;
            tempEvent.date = moment(event.time, 'YYYY-MM-DD HH:mm:ss').format('ddd, DD/MM/YYYY');
            tempEvent.time = moment(event.time, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
            tempEvent.location = event.location_name;
            tempEvent.driver = (
                <div onClick={(e) => { e.stopPropagation(); }}>
                    <Link to={`/drivers/view/${event.user_id}`}>{event.full_name}</Link>
                </div>
            );
            tempEvent.asset_reg = (
                <div onClick={(e) => { e.stopPropagation(); }}>
                    <Link to={`/assets/view/${event.asset_id}`}>{event.registration}</Link>
                </div>
            );
            tempEvent.actions = (
                <Link to={`/events/custom-view/${event.id}/${event.device_id}`}>
                    <Icon name="chevron-right" className="ml-2" />
                </Link>
            );
            tempEvent.action = (
                <div 
                    style={{ float: 'right' }}
                    onClick={(e) => { e.stopPropagation(); }}>
                    {(event.video_count > 0)
                        ? (
                            <Link to={`/events/custom-view/${event.id}/${event.device_id}`}>
                                <VideoCameraOutlined />
                            </Link>
                        )
                        : null}
                </div>
            );
            return tempEvent;
        });

        let markers = [];
        if (zoom >= 10) {
            markers = mapHeatMapMarkers(mapDeviceHeatMap, this.googleMapAccess);
        }

        const exportableColumns = listEvents && listEvents[0] ? (Object.keys(listEvents[0]).filter((oK) => oK.toLowerCase() != 'actions')) : [];

        const mapToolBarButtons = [
            { key: 'side-bar', visible: false },
            { key: 'info-box', visible: true },
            { key: 'traffic', visible: false },
            { key: 'geo-fence', visible: false },
            { key: 'center-map', visible: false },
            { key: 'satellite', visible: true },
            { key: 'heat-map', visible: true, onClick: () => { this.toggleHeatMap(); } },
            { key: 'live-map', visible: false },
            { key: 'video-search', visible: false },
        ];

        return (
            <Spin spinning={isFetching}>
                <div ref={this.tableEventsRef} className="events-tab">
                    {showMap ? (
                        <Row>
                            <Col span={24}>
                                <div style={{
                                    display: 'flex',
                                    height: '50vh',
                                    flexDirection: 'row',
                                    marginBottom: '50px',
                                }}>
                                    <Map
                                        ref={this.googleMapAccess}
                                        onChange={this.onMapChange}
                                        markerOnMouseEnter={(marker) => requestMarkerDetails(marker, this)}
                                        markerOnMouseLeave={() => { }}
                                        markerPopover={markerPopover}
                                        mapToolBarButtons={mapToolBarButtons}
                                        hideDatePicker
                                        hideSlider
                                        showToolbar
                                        allowStreetView={false}
                                        showFindCenterButton={false}
                                        apiKey={GOOGLE_MAP_API_KEY}
                                        heatMap={heatMap}
                                        markers={markers}
                                        autoZoom />
                                </div>
                            </Col>

                        </Row>
                    ) : (
                        <div style={{ height: '20px' }} />
                    )}
                    <Button
                        type="primary"
                        className="btn smallwide heatmapBtn" // heatmapBtn styles make the btn not work
                        // className="btn smallwide"
                        onClick={this.toggleMap}>
                        HeatMap
                    </Button>
                    <GridView
                        data={listEvents}
                        showExportButton
                        exportButtonClassName="btn ant-btn-primary ant-btn-xs middlewide"
                        onChange={this.onTableChange}
                        exportableColumns={exportableColumns}
                        sortableColumns={['event', 'date', 'time', 'driver', 'asset_reg']}
                        hiddenColumns={['id']}
                        pagination={{
                            total: parseInt(eventsPagination.totalRecords, 10),
                            showSizeChanger: false,
                            pageSize: parseInt(eventsPagination.perPageCount, 10),
                            current: parseInt(eventsPagination.currentPage, 10),
                            onChange: this.onPageChange,
                        }} />
                </div>
            </Spin>
        );
    }
}

EventsTab.defaultProps = {
    events: [],
    onPageChange: () => null,
    onTableChange: () => null,
    setLoadedState: () => null,
    eventsTabLoaded: false,
};

EventsTab.propTypes = {
    actions: PropTypes.object.isRequired,
    events: PropTypes.array,
    eventsPagination: PropTypes.object.isRequired,
    mapDeviceHeatMap: PropTypes.array.isRequired,
    mapHeatMapDataUpdated: PropTypes.bool.isRequired,
    deviceDetailedLocationInfo: PropTypes.object.isRequired,
    deviceDetailedLocationFetching: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    eventsTabFilter: PropTypes.object.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
    setLoadedState: PropTypes.func,
    assetId: PropTypes.string,
    dateFrom: PropTypes.string,
    mapDate: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        events: state.events.events,
        eventsPagination: state.events.eventsPagination,
        mapDeviceHeatMap: state.device.mapDeviceHeatMap,
        mapHeatMapDataUpdated: state.device.mapHeatMapDataUpdated,
        deviceDetailedLocationInfo: state.device.deviceDetailedLocationInfo,
        deviceDetailedLocationFetching: state.device.deviceDetailedLocationFetching,
        user: state.user,
        isFetching: state.events.isFetching,
        assetId: state.global.eventSearchFilters.assetId,
        mapDate: state.global.mapDate,
        dateFrom: state.global.eventSearchFilters.dateFrom,
        dateTo: state.global.eventSearchFilters.dateTo,
        eventType: state.global.eventSearchFilters.eventType,
        drivers: state.global.eventSearchFilters.drivers,
        divisions: state.global.eventSearchFilters.divisions,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...deviceActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventsTab);
