import React from 'react';
import { VideoCameraFilled } from '@ant-design/icons';
import { Button, Col, Row, Spin, Radio } from 'antd';
import moment from 'moment';
import Map from '../Map';
import { Link } from 'react-router-dom';
import { isEmpty } from 'underscore';
import ReactToPdf from 'react-to-pdf';
import VideoSelection from './partial/VideoSelection';
import VideoPlayers from './partial/VideoPlayers';
import DefaultDrivePicture from '../../assets/images/default-image.png';
import './recordDetailView.scss';
import { timeToSeconds } from '../../core/utils/functions';
import { getBaseUrl } from '../../core/utils/api';
import { requestMarkerDetails, setMarkerPopoverToLoading } from '../../core/utils/mapUtils';

const { GOOGLE_MAP_API_KEY } = require('../../core/constants').default;

export default function () {
    const {
        selectedRecord, user, searchedSingleVideoChannels, isFetchingVideoUrls, allowActions,
        showStreamingSectionOnLoad, showDownloadIframe, parent, geoPoints
    } = this.props;

    const {
        sliderValue, sliderRangeMinValue, sliderRangeMaxValue, streamingVideos, videoMode,
        showResetBtn, markerPopover,
    } = this.state;

    const polygonBoundary = [{
        "coordinates": geoPoints,
        "id": 'new',
    }];

    const hasDVR = selectedRecord && selectedRecord.dvrDetails && Object.keys(selectedRecord.dvrDetails).length > 0;
    const mapMinHeight = showDownloadIframe ? '400px' : '800px';
    const mapWidthSpan = streamingVideos ? 12 : !hasDVR && !showStreamingSectionOnLoad ? 24 : 15;
    const mappedDevicePath = this.mapDevicePath(selectedRecord);
    const assets = this.getAssetCurrentPin(mappedDevicePath);
    const mapToolBarButtons = [
        { key: 'side-bar', visible: false },
        { key: 'info-box', visible: true },
        { key: 'traffic', visible: false },
        { key: 'geo-fence', visible: false },
        { key: 'center-map', visible: false },
        { key: 'satellite', visible: true },
        { key: 'heat-map', visible: false },
        { key: 'live-map', visible: false },
        { key: 'video-search', visible: false },
        { key: 'play-pause', visible: true },
    ];

    const dvrOnline = selectedRecord?.dvrDetails?.is_online || 0;
    const cachedVideos = selectedRecord?.dvrDetails?.cached_videos || [];
    const channels = selectedRecord?.dvrDetails?.available_channels || [];
    const hasFailedCached = selectedRecord?.dvrDetails?.has_failed_cache || false;
    const { permissions = {} } = user || {};
    const videoSidePanelPermission = permissions?.live_video || permissions?.historic_video_downloading || parent === 'sharedView';

    const highlightSlider = [];
    if (cachedVideos && cachedVideos.length > 0) {
        cachedVideos.forEach((cVideo) => {
            const startTimeParts = cVideo[0].start_time && (cVideo[0].start_time).split(' ');
            const endTimeParts = cVideo[0].end_time && (cVideo[0].end_time).split(' ');
            if (startTimeParts && startTimeParts[1] && endTimeParts && endTimeParts[1]) {
                let cacheVideoStart = timeToSeconds(startTimeParts[1]);
                let cacheVideoEnd = timeToSeconds(endTimeParts[1]);
                if (cacheVideoStart < selectedRecord?.endInterval) {
                    if (cacheVideoStart < selectedRecord?.startInterval) {
                        cacheVideoStart = selectedRecord?.startInterval;
                    }
                    if (cacheVideoEnd > selectedRecord?.endInterval) {
                        cacheVideoEnd = selectedRecord?.endInterval;
                    }
                    highlightSlider.push([cacheVideoStart, cacheVideoEnd]);
                }
            }
        });
    }

    const videoContent = () => {
        const { live_video: canViewLiveVideo, historic_video_downloading: canViewHistoricVideo } = permissions;

        if (canViewLiveVideo && videoMode === 'streaming') {
            if (hasFailedCached) {
                return (
                    <VideoSelection
                        parent={this}
                        title="The cache process failed"
                        content="Please click the below button to request cache again"
                        buttonLabel="Request Cache"
                        channels={[]}
                        onClick={this.requestCacheAgain} />
                );
            }

            return (
                <Spin spinning={isFetchingVideoUrls}>
                    {/* Choose streaming channels (when device is online) */}
                    {(!streamingVideos && +dvrOnline === 1) || (searchedSingleVideoChannels.length === 0 && +dvrOnline === 1)
                        ? (
                            <VideoSelection
                                parent={this}
                                title="Stream Videos"
                                content="Move the middle point on the event slider bar to the location/time you want to view, select channels below, then click 'view'"
                                buttonLabel="View"
                                channels={channels}
                                onClick={this.getSelectedVideosUrl} />
                        )
                        : null}

                    {/* Streaming not available when device is not online */}
                    {!streamingVideos && +dvrOnline === 0
                        ? (
                            <VideoSelection
                                allowActions={canViewHistoricVideo && allowActions}
                                className="extra-height"
                                title="Stream not available"
                                parent={this}
                                content={(
                                    <>
                                        <div>We cannot connect to the asset to stream the video. This might be because the vehicle is offline, not connected to a power source, or
                                            has a weak signal.
                                        </div>
                                        <div>We can download this footage for you automatically when the asset comes back online, select the channels below and click &apos;Request for
                                            later&apos;
                                        </div>
                                    </>
                                )}
                                buttonLabel="Request for later"
                                channels={canViewHistoricVideo ? channels : []}
                                onClick={this.requestCacheAndTrimVideos} />
                        )
                        : null}
                    {/* Streaming videos now */}
                    {streamingVideos && searchedSingleVideoChannels.length > 0
                        ? (
                            <VideoPlayers refs={this.streamingVideosRefs} videos={searchedSingleVideoChannels} minHeight={348} />
                        )
                        : null}
                </Spin>
            );
        }

        // if ((canViewHistoricVideo || parent === 'sharedView') && videoMode === 'cached') {
        //     const nonCachedChannels = [];

        //     for (let i = 0; i < channels.length; i++) {
        //         const channel = channels[i];
        //         let alreadyCached = false;
        //         for (let j = 0; j < cachedVideos.length; j++) {
        //             const cachedVideo = cachedVideos[j] ? cachedVideos[j][0] : null;
        //             if (cachedVideo && channel.channel == cachedVideo.channel) {
        //                 alreadyCached = true;
        //                 break;
        //             }
        //         }
        //         if (!alreadyCached) {
        //             nonCachedChannels.push(channel);
        //         }
        //     }

        //     return (
        //         <Spin spinning={isFetchingVideoUrls}>
        //             {cachedVideos ? (
        //                 <VideoPlayers refs={this.cachedVideosRefs} videos={cachedVideos} isFlv={false} />
        //             ) : null}
        //             {parent !== 'sharedView' && nonCachedChannels.length > 0 ? (
        //                 <VideoSelection
        //                     allowActions={canViewHistoricVideo && allowActions}
        //                     className="extra-height"
        //                     parent={this}
        //                     content={(
        //                         <>
        //                             <div>To download this footage automatically when the asset comes back online, select the channels below and click &apos;Request for
        //                                 later&apos;
        //                             </div>
        //                         </>
        //                     )}
        //                     buttonLabel="Request for later"
        //                     channels={canViewHistoricVideo ? nonCachedChannels : []}
        //                     onClick={this.requestCacheAndTrimVideos} />
        //             ) : null}
        //         </Spin>
        //     );
        // }

        return null;
    };

    const videoSidebar = () => {
        const { live_video: canViewLiveVideo, historic_video_downloading: canViewHistoricVideo } = permissions;
        const defaultTab = !canViewLiveVideo || cachedVideos.length > 0 ? 'cached' : 'streaming';
        const adjustableHeight = videoMode === 'streaming' && !streamingVideos && +dvrOnline === 0;

        if (!videoSidePanelPermission) {
            return null;
        }

        if (!showDownloadIframe) {
            return null;
        }

        // the only instance this should be disabled is when user can choose videoMode and there are no cached videos
        const cachedButtonDisabled = canViewLiveVideo && canViewHistoricVideo && cachedVideos.length === 0;

        return (
            <Col xs={24} md={(mapWidthSpan === 12 ? 12 : 9)}>
                {(selectedRecord && selectedRecord.dvrDetails && Object.keys(selectedRecord.dvrDetails).length > 0) || showStreamingSectionOnLoad
                    ? (
                        <>
                            <Row style={{ marginBottom: '20px' }}>
                                <Col span={24}>
                                    {(cachedVideos.length > 0 && parent !== 'sharedView')
                                        ? (
                                            <div style={{
                                                textAlign: 'right',
                                                marginBottom: '10px',
                                            }}><VideoCameraFilled style={{ marginRight: '10px' }} /> There are cached
                                                videos available for this asset.
                                            </div>
                                        )
                                        : null}
                                    <div style={{
                                        textAlign: 'right',
                                        marginBottom: '5px',
                                    }}>
                                        <span
                                            className="video-type-box"
                                            style={{
                                                float: 'left',
                                                marginLeft: 0,
                                            }}>
                                            <Radio.Group
                                                defaultValue={defaultTab}
                                                buttonStyle="solid"
                                                onChange={(e) => {
                                                    const state = {};
                                                    state.videoMode = e.target.value;
                                                    state.streamingVideos = false;
                                                    state.selectedVideoChannels = [];
                                                    this.setState(state);
                                                    if (this.videoTimer != null) {
                                                        this.videoTimer = clearTimeout(this.videoTimer);
                                                    }
                                                }}>
                                                {canViewLiveVideo ? (<Radio.Button value="streaming">Streaming</Radio.Button>) : null}
                                                {canViewHistoricVideo ? (<Radio.Button value="cached" disabled={cachedButtonDisabled}>Cached</Radio.Button>) : null}
                                            </Radio.Group>
                                        </span>
                                        <span className="video-type-box">
                                            {canViewHistoricVideo && allowActions && isEmpty(cachedVideos) && !hasFailedCached
                                                ? (
                                                    <Button
                                                        type="primary"
                                                        onClick={this.requestCacheAndTrimVideos}
                                                        className="btn">
                                                        Cache & Trim Video
                                                    </Button>
                                                )
                                                : null}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            <div className={`video-sidebar ${adjustableHeight ? 'extra-height' : ''}`}>
                                {videoContent()}
                            </div>
                        </>
                    )
                    : null}
            </Col>
        );
    };
    return (
        <div className={showDownloadIframe ? 'video-detail-view' : 'video-detail-view video-detail-view-pdf'} ref={this.pdfDocumentRef}>
            <Row gutter={16}>
                <Col xs={24} md={showDownloadIframe && videoSidePanelPermission ? mapWidthSpan : 24}>
                    {/* Map and telematics */}
                    <Row className="map-container">
                        <Col span={24} style={{ minHeight: mapMinHeight }}>
                            <Map
                                ref={this.googleMapAccess}
                                allowMultipleRangeSlider
                                sliderRangeMaxValue={sliderRangeMaxValue}
                                sliderRangeMinValue={sliderRangeMinValue}
                                markerOnMouseEnter={(marker) => { setMarkerPopoverToLoading(this); requestMarkerDetails(marker, this) }}
                                markerPopover={markerPopover}
                                highlightSlider={highlightSlider}
                                mapDate={moment()
                                    .subtract('1', 'days')
                                    .format('DD/MM/YYYY')}
                                apiKey={GOOGLE_MAP_API_KEY}
                                mapToolBarButtons={mapToolBarButtons}
                                sliderStep={1}
                                sliderValue={parseInt(sliderValue, 10)}
                                sliderMinValue={selectedRecord?.startInterval}
                                sliderMaxValue={selectedRecord?.endInterval}
                                sliderValueUnit="seconds"
                                hideDatePicker
                                // center={this.centerMap}
                                onSliderRangeChange={(value, formattedValue, rangeMin, rangeMax, changedByPlay) => this.onSliderRangeChange(value, formattedValue, rangeMin, rangeMax, changedByPlay)}
                                // heatMap={heatMap}
                                assets={assets}
                                showToolbar={showDownloadIframe}
                                toolbarLoading={!selectedRecord}
                                path={mappedDevicePath}
                                onAutoPlay={this.handleResume}
                                onPause={this.handlePause}
                                allowStreetView={false}
                                showFindCenterButton={false}
                                forwardCallback={this.handleForward}
                                rewindCallback={this.handleRewind}
                                increaseOptionCallback={this.handleIncreaseOptionCallback}
                                resetCallback={this.handleReset}
                                polygonBoundary={polygonBoundary}
                                showResetBtn={showResetBtn} />
                        </Col>
                    </Row>
                </Col>
                {!hasDVR
                    ? (
                        <p style={{
                            backgroundColor: '#f1f4f6',
                            textAlign: 'center',
                            paddingTop: 10,
                            paddingRight: 10,
                        }}>no DVR is connected to the asset.
                        </p>
                    )
                    : videoSidebar()}
            </Row>

            <Row gutter={[16, 16]} className="asset-info-row">
                <Col xs={24} md={7}>
                    <Row>
                        <Col xs={24}>
                            {/* Asset and driver information */}
                            <div className="video-view-block-title">Asset & Driver Information</div>
                            <Row gutter={[32, 32]}>
                                <Col span={12}>
                                    <img
                                        alt="Asset"
                                        src={
                                            selectedRecord?.info?.asset_picture_id
                                                ? `${getBaseUrl()}/site/display-file?mediaId=${selectedRecord?.info?.asset_picture_id}`
                                                : DefaultDrivePicture
                                        }
                                        style={{ width: '100%', maxHeight: '400px' }} />
                                    <div style={{ margin: '5px 0' }}>
                                        <Link to={`/assets/view/${selectedRecord?.info?.asset_id}`}>
                                            {selectedRecord?.info?.asset_name} [{selectedRecord?.info?.registration}]
                                        </Link>
                                    </div>
                                    <div style={{ margin: '5px 0' }}><strong>Last Service:</strong>{selectedRecord && selectedRecord.info ? moment(selectedRecord.info.last_service).format('DD/MM/YYYY') : null}</div>
                                </Col>
                                <Col span={12}>
                                    <img
                                        alt="Driver"
                                        src={
                                            selectedRecord?.info?.profile_picture_id
                                                ? `${getBaseUrl()}/site/display-file?mediaId=${selectedRecord?.info?.profile_picture_id}`
                                                : DefaultDrivePicture
                                        }
                                        style={{ width: '100%', maxHeight: '400px' }} />
                                    <div style={{ margin: '5px 0' }}>
                                        <Link to={`/drivers/view/${selectedRecord?.info?.user_id}`}>{selectedRecord?.info?.driver}</Link>
                                    </div>
                                    <div style={{ margin: '5px 0' }}>DRIVER</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
