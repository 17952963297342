import React from 'react';
import {
    AlertFilled, ApiFilled, BellFilled, EyeFilled, KeyOutlined,
} from '@ant-design/icons';
import { isEmpty } from 'underscore';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

import history from './history';
import MapAssetPopup from '../../components/elements/MapAssetPopup';
import { openStreetView } from './functions';

const {
    MapAssetPopupHead,
    MapAssetPopupContent,
} = MapAssetPopup;

const mapHeatMap = (mapDeviceHeatMap) => {
    const heatMap = {
        positions: mapDeviceHeatMap ? mapDeviceHeatMap.map((mapDeviceHeatMapItem) => ({
            lat: parseFloat(mapDeviceHeatMapItem.latitude),
            lng: parseFloat(mapDeviceHeatMapItem.longitude),
            weight: parseInt(mapDeviceHeatMapItem.weight, 10) * 10,
        })) : [],
        options: {
            radius: 10,
            opacity: 0.6,
        },
    };

    return heatMap;
};

const mapHeatMapMarkers = (mapDeviceHeatMap, googleMapAccess) => {
    const mapBounds = googleMapAccess && googleMapAccess.current && googleMapAccess.current.state && googleMapAccess.current.state.map && googleMapAccess.current.state.map.getBounds();

    let northEastLat = 0;
    let northEastLng = 0;
    let getSouthWestLat = 0;
    let getSouthWestLng = 0;

    if (mapBounds && mapBounds.getNorthEast && mapBounds.getSouthWest) {
        northEastLat = mapBounds.getNorthEast().lat();
        northEastLng = mapBounds.getNorthEast().lng();

        getSouthWestLat = mapBounds.getSouthWest().lat();
        getSouthWestLng = mapBounds.getSouthWest().lng();
    }

    const localMarkers = mapDeviceHeatMap.filter((item) => {
        const lat = parseFloat(item.latitude);
        const lng = parseFloat(item.longitude);
        const minLat = getSouthWestLat;
        const maxLat = northEastLat;
        const minLng = getSouthWestLng;
        const maxLng = northEastLng;
        return lat > minLat && lat < maxLat && lng > minLng && lng < maxLng;
    });

    const total = localMarkers.length;
    const max = 50;
    const step = Math.max(Math.floor(total / max), 1);
    const decimated = localMarkers.filter((item, i) => i % step === 0);

    let markers = [];
    if (!isEmpty(decimated)) {
        markers = decimated.map((item, key) => {
            const data = item.events;
            const content = data.map((dataPoint) => (
                <div key={`heat-map-item-${key}`}>
                    <Link to={`${`/events/custom-view/${dataPoint.event_id}`}/${dataPoint.device_id}`}>
                        {dataPoint.date}: {dataPoint.asset_name}<div style={{ marginLeft: '40px' }}> {dataPoint.event_title}</div>
                    </Link>
                </div>
            ));

            let EventIcon = AlertFilled;
            switch (data[0].event_icon) {
            case 'key':
                EventIcon = KeyOutlined;
                break;
            case 'eye':
                EventIcon = EyeFilled;
                break;
            case 'api':
                EventIcon = ApiFilled;
                break;
            case 'thunderbolt':
                EventIcon = BellFilled;
                break;
            default:
                EventIcon = AlertFilled;
            }

            return {
                title: (
                    <MapAssetPopupHead
                        heading={item.title}
                        registration={item.registration} />
                ),
                lat: parseFloat(item.latitude),
                lng: parseFloat(item.longitude),
                record_id: item.record_id,
                is_event: true,
                onClick: () => history.push(`${`/events/custom-view/${data[0].event_id}`}/${data[0].device_id}`),
                content,
                forceInfoBox: item.events.length > 1,
                icon: <Tooltip><EventIcon className="event-icon" /></Tooltip>,
            };
        });
    }

    return markers;
};

const setMarkerPopoverToLoading = (ref) => {
    let { markerPopover } = ref.state;
    if (markerPopover) {
        const updatedMarkerPopover = React.cloneElement(markerPopover, { showLoading: true });
        ref.setState({ markerPopover: updatedMarkerPopover });
    } else {
        markerPopover = (
            <MapAssetPopupContent
                showLoading />
        );
        ref.setState({ markerPopover });
    }
};

const requestMarkerDetails = (marker, ref) => {
    if (marker && marker.record_id) {
        const { actions } = ref.props;
        const event = marker.is_event;
        const payload = { record_id: marker.record_id };

        if (event) {
            payload.event = true;
        }

        actions.getDeviceLocationDetailedInfoRequest(payload);
    } else if (marker && marker.date_from) {
        const { actions } = ref.props;
        const { is_event, date_from, asset_id } = marker;
        const payload = { asset_id, date_to: date_from };

        if (is_event) {
            payload.event = true;
        }

        actions.getDeviceLocationDetailedInfoRequest(payload);
    } else if (ref) {
        const markerPopover = (
            <MapAssetPopupContent
                showNoData />
        );
        ref.setState({ markerPopover });
    }
};

const setMarkerPopoverDetails = (ref, live = false) => {
    const { deviceDetailedLocationInfo, user, company } = ref.props;
    const { marker } = deviceDetailedLocationInfo || {};
    if (!isEmpty(marker)) {
        let { speed } = marker || {};
        let unit = 'kph';
        if (user && user.profile && user.profile.unit_of_measurement_preference === 'british-imperial') {
            unit = 'mph';
            speed = Math.floor(speed * 0.62);
        }

        const event_key = deviceDetailedLocationInfo.event ? marker.event_key : null;
        const dateTime = deviceDetailedLocationInfo.event ? marker.date : null;
        const markerPopover = (
            <MapAssetPopupContent
                showLoading={false}
                online={deviceDetailedLocationInfo.online}
                online_status={deviceDetailedLocationInfo.online_status}
                event_key={event_key}
                dateTime={dateTime}
                driver={deviceDetailedLocationInfo.driver}
                assetPicture={deviceDetailedLocationInfo.asset_picture}
                userPicture={deviceDetailedLocationInfo.user_picture}
                deviceModel={deviceDetailedLocationInfo.device_model}
                location_data={deviceDetailedLocationInfo.marker?.data || []}
                showPicture
                speed={speed}
                live={live}
                speedUnit={unit}
                streetViewIconClicked={openStreetView}
                googleMapAccessRef={ref.googleMapAccess}
                lat={marker.lat}
                lng={marker.lng}
                angle={marker.angle} 
                showTimeToDestinationBtn={company && company.features && company.features.find((feature) => feature.id === 'time-to-destination')} />
        );

        ref.setState({ markerPopover });
    } else {
        const markerPopover = (
            <MapAssetPopupContent
                showNoData />
        );

        ref.setState({ markerPopover });
    }
};

const getMapAssetPopupHead = (detailedInfo) => (
    <MapAssetPopupHead
        heading={detailedInfo.title}
        registration={detailedInfo.registration} />
);

const getMapAssetPopupContent = (ref, detailedInfo, speed, unit) => {
    const { company } = ref.props;

    return (
        <MapAssetPopupContent
            showLoading={false}
            online={0}
            online_status={detailedInfo.online_status}
            event_key={detailedInfo.marker?.event_key}
            driver={detailedInfo.driver}
            assetPicture={detailedInfo.asset_picture}
            userPicture={detailedInfo.user_picture}
            deviceModel={detailedInfo.device_model}
            location_data={detailedInfo.marker?.data || []}
            showPicture
            speed={speed}
            speedUnit={unit}
            streetViewIconClicked={openStreetView}
            googleMapAccessRef={ref.googleMapAccess}
            lat={detailedInfo.lat}
            lng={detailedInfo.lng}
            angle={detailedInfo.angle} 
            showTimeToDestinationBtn={company && company.features && company.features.find((feature) => feature.id === 'time-to-destination')} />
    );
};

const calculateSpeedAndUnit = (detailedInfo, user) => {
    let { speed } = detailedInfo;
    let unit = 'kph';

    if (user && user.profile && user.profile.unit_of_measurement_preference === 'british-imperial') {
        unit = 'mph';
        speed = Math.floor(detailedInfo.speed * 0.62);
    }

    return {
        speed,
        unit,
    };
};

export {
    mapHeatMap, mapHeatMapMarkers, setMarkerPopoverToLoading, requestMarkerDetails,
    setMarkerPopoverDetails, getMapAssetPopupHead, getMapAssetPopupContent, calculateSpeedAndUnit,
};
