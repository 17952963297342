import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VideoHistory from '../../../components/VideoHistory';
import * as assetActions from '../../../core/asset/assetActions';
import * as userActions from '../../../core/user/userActions';
import { getTodayInServerFormat, getYesterdayInServerFormat } from '../../../core/utils/dateUtils';

class AssetVideoHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const {
            videoHistoryTabLoaded,
            setLoadedState,
        } = this.props;
        if (!videoHistoryTabLoaded) {
            this.getHistoricVideos();
            setLoadedState(true);
        }
    }

    /**
     * Makes a request to the getAssetHistoricVideoRequest action.
     *
     * @param {Object} params - The query params object to use in the request.
     */
    getHistoricVideos = () => {
        const {
            assetId,
            actions,
        } = this.props;

        actions.getAssetHistoricVideoRequest({
            asset_id: assetId,
            date_from: getYesterdayInServerFormat(),
            date_to: getTodayInServerFormat(),
        });
    };

    onPaginationChange = (page) => {
        const { onPageChange } = this.props;
        onPageChange(page);
    };

    onTableChange = (pagination, filters, sorter) => {
        const { onTableChange, assetVideoHistoryPagination } = this.props;

        if (pagination && pagination.current == assetVideoHistoryPagination.currentPage) {
            onTableChange(pagination, filters, sorter);
        }
    };

    render() {
        const {
            isFetching,
            videos,
            assetVideoHistoryPagination,
            handleTableChange,
            paginationChange,
            handleVideoClick,
            videoHistoryFirstLoad,
        } = this.props;

        return (
            <>
                {videoHistoryFirstLoad ? (
                    <div className="h-centered-text-container">
                        <p>(Filtering by Today)</p>
                    </div>
                ) : null}
                <VideoHistory
                    loading={isFetching && isFetching.length > 0}
                    sortableColumns={['date', 'time', 'location', 'duration']}
                    videos={videos}
                    pagination={assetVideoHistoryPagination}
                    handleTableChange={handleTableChange || this.onTableChange}
                    handlePaginationChange={paginationChange || this.onPaginationChange}
                    handleVideoClick={handleVideoClick} />
            </>
        );
    }
}

AssetVideoHistory.defaultProps = {
    pagination: { page: 1, totalCount: 16, perPage: 16 },
    handleTableChange: null,
    paginationChange: null,
    handleVideoClick: () => null,
    videoHistoryTabLoaded: false,
    setLoadedState: () => null,
};

AssetVideoHistory.propTypes = {
    videos: PropTypes.array.isRequired,
    pagination: PropTypes.object,
    handleTableChange: PropTypes.func,
    paginationChange: PropTypes.func,
    handleVideoClick: PropTypes.func,
    assetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    videoHistoryTabLoaded: PropTypes.bool,
    setLoadedState: PropTypes.func,
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        serviceAlerts: state.asset.serviceAlerts,
        serviceAlertsPagination: state.asset.serviceAlertsPagination,
        isFetching: state.asset.isFetching,
        videos: state.asset.assetVideoHistory,
        assetVideoHistoryPagination: state.asset.assetVideoHistoryPagination,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
            ...userActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssetVideoHistory);
