import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'underscore';
import { connect } from 'react-redux';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { FlagFilled } from '@ant-design/icons';
import { Tooltip, Spin, Empty } from 'antd';

import * as eventsActions from '../../../core/events/eventsActions';
import { convertUtcToBst } from '../../../core/utils/dateUtils';
import EventCustomViewPage from '../../../components/EventCustomViewPage';

class EventScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_time: null,
            end_time: null,
        };
    }

    componentDidMount() {
        const { actions, report } = this.props;
        // checking summer british time
        const start_time = convertUtcToBst(report.start_time);
        const end_time = convertUtcToBst(report.end_time);
        this.setState({ start_time, end_time });

        actions.getEventInfoRequest({
            eventId: report.eventId,
            deviceId: report.deviceId,
            eventView: Math.random(),
            before_time: report.before_time,
            after_time: report.after_time,
            start_time,
            end_time,
        });
    }

    render() {
        const centerMap = {
            lat: 52.48759,
            lng: -1.91199,
        };

        const {
            selectedEvent, report, actions, targetRef, isFetching,
        } = this.props;

        const {
            start_time, end_time,
        } = this.state;

        const { eventId } = report;

        const mappedDevicePath = selectedEvent && selectedEvent.path ? selectedEvent.path.map((event, eventIndex) => {
            const tempEvent = {};
            tempEvent.lat = event.lat;
            tempEvent.lng = event.lng;
            tempEvent.position = 'path';
            if (event.priority == '1') {
                centerMap.lat = parseFloat(event.lat);
                centerMap.lng = parseFloat(event.lng);
                const iconStyle = {};
                iconStyle.fontSize = '16px';
                iconStyle.color = '#CF2B28';
                tempEvent.position = 'marker';
                tempEvent.currentMarker = {};
                tempEvent.currentMarker.icon = event.icon && event.icon != '' ? <Tooltip title={event.event_name}><LegacyIcon type={event.icon} theme="filled" style={iconStyle} /></Tooltip> : <FlagFilled />;
            }
            return tempEvent;
        })
            : [];
        return (
            <>
                <div className="section__header">
                    <div className="section__header-item is-active">
                        <span>Event: {eventId}</span>
                    </div>
                </div>
                <div className="section__body">
                    <Spin spinning={isFetching}>
                        {!isEmpty(selectedEvent)
                            ? (
                                <EventCustomViewPage
                                    showStitchVideo
                                    allowEdit={false}
                                    targetRef={targetRef}
                                    params={report}
                                    actions={actions}
                                    before_time={report.before_time}
                                    after_time={report.after_time}
                                    start_time={start_time}
                                    end_time={end_time}
                                    centerMap={centerMap}
                                    onTimeDataChange={this.onTimeDataChange}
                                    mappedDevicePath={mappedDevicePath}
                                    selectedEvent={selectedEvent} />
                            )
                            : <Empty />}
                    </Spin>
                </div>
            </>
        );
    }
}

EventScreen.defaultProps = {
    selectedEvent: {},
};

EventScreen.propTypes = {
    actions: PropTypes.object.isRequired,
    report: PropTypes.object.isRequired,
    selectedEvent: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedEvent: state.events.selectedEvent,
        report: state.events.report,
        isFetching: state.events.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...eventsActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventScreen);
