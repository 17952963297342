import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DOM from './recordsView';
import * as recordsActions from '../../core/records/recordsActions';
import * as globalActions from '../../core/global/globalActions';
import * as reportActions from '../../core/report/reportActions';

class RecordsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            referrer: '',
            sliderValue: null,
            geoPoints: [],
        };
        this.view = DOM;
    }

    componentDidMount() {
        this.requestEventInfo();
        const hash = new URLSearchParams(this.props.location.search).get('hash');
        if (hash) {
            const rawData = localStorage.getItem(hash);
            if (rawData) {
                const geoPoints = rawData.split("&").map((pointsString) => JSON.parse(pointsString));
                this.setState({ geoPoints });
            }

        }
    }

    requestEventInfo = (startTime, endTime, sliderValue) => {
        const { actions, match } = this.props;
        const { recordId } = match.params;
        const params = {};
        params.recordId = recordId;
        params.start_time = startTime;
        params.end_time = endTime;
        actions.getRecordInfoRequest(params);
        this.setState({ sliderValue });
    };

    render() {
        return this.view();
    }
}

RecordsView.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedRecord: state.records.selectedRecord,
        isFetching: state.records.isFetching,
        referrer: state.referrer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...recordsActions,
                ...globalActions,
                ...reportActions,
            },
            dispatch,
        ),
    };
}
export const RecordsViewTest = RecordsView;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RecordsView);
