import React from 'react';
import { Layout as AntLayout, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';

import './notFound.scss';
import Menu from '../../components/layout/Menu';
import srcFleetclearLogo from '../../assets/images/fleetclear-logo.svg';
import srcISSLogo from '../../assets/images/iss-logo-silver-black.png';

export default function () {
    return (
        <Layout
            data-test="pages-notfound"
            showHeader={false}
            className="not-found"
            title={<div>Page not found</div>}
            headerTitle={<div>Page not found</div>}
            showFooter={false}>
            <div className="header">
                <AntLayout.Header className="header__inner">
                    <Row type="flex" justify="space-between">
                        <Col>
                            <div className="header__logos">
                                <img src={srcISSLogo} className="header__logo-iss" alt="ISS" />
                                <img src={srcFleetclearLogo} className="header__logo-fleet" alt="Fleetclear" />
                            </div>
                        </Col>
                    </Row>
                </AntLayout.Header>
            </div>
            <div className="content-wrap">
                <FilterSidebar />
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__header">
                                <div className="section__header-item is-active">
                                    <span>404: Page not found</span>
                                </div>
                            </div>
                            <div className="section__body">
                                <p className="heading">404</p>
                                <p className="small-heading">Um, yeah. This is awkward.</p>
                                <p className="normal">We tried really hard, but couldn't find the page you were looking for. You may find what you were looking for on our <Link to="/map">homepage</Link>.</p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
