/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Select, Button, Input, Checkbox } from 'antd';
import Icon from '../../../components/elements/Icon';
import './filterForm.css';

const FormItem = Form.Item;


// assetsForm
// divisionsForm

function assetsForm() {
    const { form, divisions, vehicleTypes, vehicleMakes, assetOptions } = this.props;
    const { getFieldDecorator } = form;

    const { Option } = Select;

    return (
        (<Form onSubmit={this.handleSubmit} className="login-form" layout="vertical">
            {/*     <FormItem name="asset" label="Asset name or reg ff">
                {getFieldDecorator('asset')(<Input placeholder="Asset name or reg" />)}
            </FormItem>
*/}
            <Form.Item label="Asset name or reg">
                {getFieldDecorator('asset')(
                    <Select
                        mode="multiple"
                        showSearch
                        allowClear
                        filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                        placeholder="Asset name or reg"
                        suffixIcon={<Icon name="triangle-down" />}>
                        {assetOptions.map((a) => (
                            <Option key={a.id} value={a.id}>
                                {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                            </Option>
                        ))}
                    </Select>,
                )}
            </Form.Item>
            <FormItem label="Division">
                {getFieldDecorator('divisions')(
                    <Select
                        mode="multiple"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        placeholder="Select division"
                        suffixIcon={<Icon name="triangle-down" />}>
                        {
                            divisions.map((v, i) => (
                                /* eslint-disable-next-line react/no-array-index-key */
                                (<Option key={`${i}`} value={v.id}>{v.name}</Option>)
                            ))
                        }
                    </Select>
                )}
            </FormItem>
            <FormItem label="Vehicle type">
                {getFieldDecorator('vehicle_type')(
                    <Select allowClear placeholder="Select vehicle type" suffixIcon={<Icon name="triangle-down" />}>
                        {
                            vehicleTypes.map((v, i) => (
                                /* eslint-disable-next-line react/no-array-index-key */
                                (<Option key={`${i}`} value={v.id}>{v.title}</Option>)
                            ))
                        }
                    </Select>
                )}
            </FormItem>
            <FormItem label="Year vehicle registered">
                {getFieldDecorator('registration_year')(
                    <Select
                        placeholder="Select year"
                        suffixIcon={<Icon name="triangle-down" />}>
                        <Option value="">Select year</Option>
                        {[...Array(80)].map((x, i) => {
                            const year = 1950 + i;
                            return <Option key={year} value={year}>{year}</Option>;
                        })}
                    </Select>,
                )}
            </FormItem>
            <FormItem label="Asset make">
                {getFieldDecorator('make')(
                    <Select allowClear placeholder="Select make" suffixIcon={<Icon name="triangle-down" />}>
                        {
                            vehicleMakes.map((v, i) => (
                                /* eslint-disable-next-line react/no-array-index-key */
                                (<Option key={`${i}`} value={v.id}>{v.title}</Option>)
                            ))
                        }
                    </Select>
                )}
            </FormItem>
            <FormItem label="Fuel type">
                {getFieldDecorator('fuel_type')(
                    <Select
                        placeholder="Select fuel type"
                        suffixIcon={<Icon name="triangle-down" />}>
                        <Option value="">Select fuel type</Option>
                        <Option value="1">Diesel</Option>
                        <Option value="2">Petrol</Option>
                        <Option value="3">Gas</Option>
                        <Option value="4">Electricity</Option>
                        <Option value="5">Hydrogen</Option>
                        <Option value="6">Hybrid</Option>
                    </Select>,
                )}
            </FormItem>
            <div className="mt-2 text-center">
                <Button htmlType="submit" type="primary" className="btn extrawide" size="large">
                    Filter
                </Button>
            </div>
        </Form>)
    );
}
function divisionsForm() {
    const { form, divisions } = this.props;
    const { getFieldDecorator } = form;

    const { Option } = Select;

    return (
        (<Form onSubmit={this.handleSubmit} className="login-form" layout="vertical">
            <FormItem label="Division">
                {getFieldDecorator('divisions')(
                    <Select
                        mode="multiple"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        placeholder="Select division"
                        suffixIcon={<Icon name="triangle-down" />}>
                        {
                            divisions.map((v, i) => (
                                /* eslint-disable-next-line react/no-array-index-key */
                                (<Option key={`${i}`} value={v.id}>{v.name}</Option>)
                            ))
                        }
                    </Select>
                )}
            </FormItem>
            <div className="mt-2 text-center">
                <Button htmlType="submit" type="primary" className="btn extrawide" size="large">
                    Filter
                </Button>
            </div>
        </Form>)
    );
}

export { divisionsForm, assetsForm };
