/* eslint-disable react/sort-comp */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './drivers';

import * as DriverActions from '../../core/driver/driverActions';
import * as DivisionActions from '../../core/division/divisionActions';

class Drivers extends Component {
    constructor(props) {
        super(props);

        // checking should we display assets tab
        const urlParams = new URLSearchParams(props.location.search);
        const filterByName = urlParams.get('name');
        let searchParams = {};

        if (filterByName) {
            searchParams = { name: filterByName };
        }

        this.state = {
            searchParams,
            orderParams: {},
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, driverList } = this.props;
        const { searchParams } = this.state;

        actions.getDriverListRequest();
        actions.getDriversRequest({ searchParams });
        actions.getGeneralDataRequest();
        actions.getDivisionOptionsRequest();
    }

    paginationChange(page) {
        const { actions, driver } = this.props;
        const { searchParams, orderParams } = this.state;

        if (page !== driver.pagination.currentPage) {
            actions.getDriversRequest({ page, searchParams, orderParams });
        }
    }

    filterDrivers(searchParams) {
        const { actions } = this.props;
        const { orderParams } = this.state;

        actions.getDriversRequest({ page: 1, searchParams, orderParams });
        this.setState({ searchParams });
    }

    handleTableChange = (pagination, filters, sorter) => {
        const { searchParams } = this.state;
        const { actions, driver } = this.props;
        // checking when only order changes
        if (pagination && pagination.current == driver.pagination.currentPage) {
            const orderParams = {};
            // reset the params so that this orderParams will be last, for sorting order
            if (orderParams[sorter.field] !== undefined) {
                delete orderParams[sorter.field];
            }
            orderParams[sorter.field] = sorter.order ? sorter.order : false;
            this.setState({ orderParams });
            actions.getDriversRequest({ page: driver.pagination.currentPage, searchParams, orderParams });
        }
    };

    render() {
        return this.view();
    }
}

Drivers.propTypes = {
    actions: PropTypes.object.isRequired,
    driver: PropTypes.object.isRequired,
    division: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        driver: state.driver,
        division: state.division,
        driverList: state.driver.driverList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...DriverActions,
                ...DivisionActions,
            },
            dispatch,
        ),
    };
}
export const DriversTest = Drivers;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Drivers);
